import { wildButtonProps } from '@components/WildButton';
import { RouteUrls } from '@constants/routeUrls';
import { useTour } from '@reactour/tour';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ isSuperUser }) {
  const navigate = useAppNavigate();
  const { t } = useTranslation('Marketing');
  const setPageMeta = useStore(setPageMetaSelector);
  const { setIsOpen, setCurrentStep } = useTour();

  useEffect(() => {
    setPageMeta({
      title: t('collections'),
      description: t('collections_desc'),
      actions: [
        !isSuperUser && {
          label: t('collection_detail'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.marketing.productCollections.newCollection);
          },
        },
        {
          ...wildButtonProps,
          label: t('start_tour'),
          onClick() {
            setCurrentStep(0);
            setIsOpen(true);
          },
        },
      ].filter(Boolean),
    });
  }, [t, isSuperUser]);

  useUnmount(() => {
    setPageMeta({});
  });
}
