import './styles.scss';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import React from 'react';
import { toast } from 'sonner';

const AkinonNotification = ({ message, description, type, onDismiss }) => {
  return (
    <div
      className={`ant-notification-notice akinon-notification ant-notification-notice-${type} ant-notification-notice-closable akinon-notification`}
    >
      <div className="ant-notification-notice-content">
        <div className="ant-notification-notice-with-icon" role="alert">
          <span
            role="img"
            aria-label="close-circle"
            className="anticon anticon-close-circle ant-notification-notice-icon ant-notification-notice-icon-error"
          >
            {type === 'error' && <CloseCircleOutlined size={24} />}
            {type === 'success' && <CheckCircleOutlined size={24} />}
            {type === 'info' && <InfoCircleOutlined size={24} />}
            {type === 'warning' && <InfoCircleOutlined size={24} />}
          </span>
          <div className="ant-notification-notice-message" style={{ fontSize: 16 }}>
            {message}
          </div>
          <div className="ant-notification-notice-description">
            <div className="ant-space ant-space-vertical">
              <div className="ant-space-item">
                <div className="ant-space ant-space-vertical">
                  <div className="ant-space-item">
                    <span className="ant-typography" style={{ color: 'white', fontSize: 14 }}>
                      {description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
      <div className="ant-notification-notice-close">
        <button className="akinon-notification__close-button" onClick={onDismiss}>
          <CloseOutlined size={14} />
        </button>
      </div>
    </div>
  );
};

/**
 * @param {import('antd/lib/notification').ArgsProps} args
 */
export const openNotification = ({
  message,
  description,
  type = 'success',
  duration = 10000,
  onDismiss,
  ...rest
}) => {
  const onDismissToast = onDismiss
    ? (t) => {
        toast.dismiss(t);
        onDismiss();
      }
    : (t) => toast.dismiss(t);

  toast.custom(
    (t) => (
      <AkinonNotification
        type={type}
        message={message}
        description={description}
        onDismiss={() => onDismissToast(t)}
      />
    ),
    { position: 'top-right', duration, ...rest }
  );
};
