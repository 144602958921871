import { dateFormatter } from '@utils/index';
import { Tag, Tooltip, Typography } from 'antd';

const { Text, Title } = Typography;

const CollectionStatus = {
  ACTIVE: true,
  PASSIVE: false,
};

export const DataIndex = {
  SELLER_NAME: ['seller', 'name'],
  NAME: 'name',
  ID: 'commerce_id',
  SLUG: 'slug',
  IS_ACTIVE: 'is_active',
  BEGINNING_DATE: 'beginning_date',
  EXPIRATION_DATE: 'expiration_date',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

export const StaticFilterKey = {
  SELLER: 'datasource',
  NAME: 'name',
  STATUS: 'is_active',
  FIRST_CREATED_DATE: 'created_at__gte',
  LAST_CREATED_DATE: 'created_at__lte',
  FIRST_UPDATED_DATE: 'updated_at__gte',
  LAST_UPDATED_DATE: 'updated_at__lte',
  FIRST_EXPIRATION_DATE: 'expiration_date__gte',
  LAST_EXPIRATION_DATE: 'expiration_date__lte',
};

const getStatusRenderMap = ({ t }) => {
  return {
    [CollectionStatus.ACTIVE]: {
      color: 'green',
      label: t('active'),
    },
    [CollectionStatus.PASSIVE]: {
      color: 'red',
      label: t('passive'),
    },
  };
};

export const getColumns = ({ t, isSuperUser }) => [
  {
    dataIndex: DataIndex.ID,
    title: t('collection_id'),
    render: (id) => <Tooltip title={id}>{id}</Tooltip>,
  },
  isSuperUser && {
    dataIndex: DataIndex.SELLER_NAME,
    visibleOnFilter: [StaticFilterKey.SELLER],
    title: t('seller.name'),
    render: (name) => <Tooltip title={name}>{name}</Tooltip>,
  },
  {
    dataIndex: DataIndex.NAME,
    visibleOnFilter: [StaticFilterKey.NAME],
    title: t('collections_name'),
    render: (name) => <Tooltip title={name}>{name}</Tooltip>,
  },
  {
    dataIndex: DataIndex.SLUG,
    title: t('slug'),
    render: (slug) => <Tooltip title={slug}>{slug}</Tooltip>,
  },
  {
    dataIndex: DataIndex.IS_ACTIVE,
    visibleOnFilter: [StaticFilterKey.STATUS],
    title: t('status'),
    render: (cellData) => {
      const { color, label } = getStatusRenderMap({ t })[cellData] || {};
      return <Tag color={color}>{label}</Tag>;
    },
  },
  {
    dataIndex: DataIndex.BEGINNING_DATE,
    title: t('start_date'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.EXPIRATION_DATE,
    visibleOnFilter: [StaticFilterKey.FIRST_EXPIRATION_DATE, StaticFilterKey.LAST_EXPIRATION_DATE],
    title: t('end_date'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.CREATED_AT,
    visibleOnFilter: [StaticFilterKey.FIRST_CREATED_DATE, StaticFilterKey.LAST_CREATED_DATE],
    title: t('creation.date'),
    render: (cellData) => dateFormatter(cellData),
  },
  {
    dataIndex: DataIndex.UPDATED_AT,
    visibleOnFilter: [StaticFilterKey.FIRST_UPDATED_DATE, StaticFilterKey.LAST_UPDATED_DATE],
    title: t('modified_date'),
    render: (cellData) => dateFormatter(cellData),
  },
];

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.standard-akinon-table-header',
      content: (
        <>
          <Title level={4}>{t('collection_list_tutorial.table_title')}</Title>
          <Text>{t('collection_list_tutorial.table')}</Text>
        </>
      ),
    },
    {
      selector: '.filter-options__filter-modal-open-button',
      content: (
        <>
          <Title level={4}>{t('collection_list_tutorial.dynamic_filters_title')}</Title>
          <Text>{t('collection_list_tutorial.dynamic_filters')}</Text>
        </>
      ),
    },
    {
      selector: '.app-layout__page-meta-action',
      content: (
        <>
          <Title level={4}>{t('collection_list_tutorial.add_new_collection')}</Title>
          <Text>{t('collection_list_tutorial.add_new_collection_desc')}</Text>
        </>
      ),
    },
  ];
};
