import { getAttributeOptionsUrl } from '@constants/apiUrls';
import { AttributeDataType, booleanAttributeOptions, WidgetType } from '@constants/commontypes';

export const getAttributeOptions = ({ attributeDataType, attributeOptionsMap, id }) => {
  switch (attributeDataType) {
    case AttributeDataType.BOOLEAN:
      return booleanAttributeOptions;
    case AttributeDataType.DROPDOWN:
      return attributeOptionsMap?.[id];
    default:
      return [];
  }
};

export const getDynamicFilterWidgetProps = ({ widget, key, id }) => {
  switch (widget) {
    case WidgetType.SELECT:
      return {
        showSearch: true,
        optionFilterProp: 'label',
      };
    case WidgetType.ASYNC_SELECT:
      return {
        labelInValue: true,
        showSearch: true,
        valueKey: 'value',
        labelKey: 'label',
        queryProps: {
          remoteKey: key,
          remoteUrl: getAttributeOptionsUrl,
          params: {
            limit: 20,
            sort: 'label',
            attribute: id,
          },
          searchKey: 'label__icontains',
        },
      };
    default:
      return {};
  }
};
