import './style.scss';

import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import BulkActionModal from '@components/BulkActionModal';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { getCancellationPlansUrl, getOrderItemsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { cancellationPlanStatuses, limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useIsMobile } from '@hooks/useIsMobile';
import { useTour } from '@reactour/tour';
import useBulkAction from '@root/hooks/useBulkAction';
import { useApproveCancellationPlansMutation } from '@services/api/hooks';
import { queryClient } from '@services/api/queryClient';
import {
  orderItemQueryKey,
  useApproveBulkRefundMutation,
  useDataSourceDetail,
  useRejectOrderItemsMutation,
} from '@services/hooks';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider, Tag } from 'antd';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ProductStatus } from '../common';
import { getOrderStatusesRenderMap } from '../OrderList/common';
import {
  cancellationPlanColumns,
  cardBoxItems,
  getProductDetailsColumns,
  getProductDetailSteps,
  getTableActions,
  getTourSteps,
  Step,
} from './common';
import BulkRejectOrderItemsModal from './components/BulkRejectOrderItemsModal';
import OrderDetailList from './components/OrderDetailList';
import OrderInfoPanels from './components/OrderInfoPanels';
import ProductInfoDrawer from './components/ProductInfoDrawer';
import BulkRejectRefundRequestModal from './components/RejectRefundRequestModal';
import SingleRejectModal from './components/SingleRejectModal';
import useApproveOrderItems from './hooks/useApproveOrderItems';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useBulkRejectRefundRequests from './hooks/useBulkRejectRefundRequests';
import { useCancellationDataSource } from './hooks/useCancellationDataSource';
import { useDataSource } from './hooks/useDataSource';
import { useOrderItemDetail } from './hooks/useOrderItemDetail';
import { usePackagesItems } from './hooks/usePackagesItems';
import { usePageMeta } from './hooks/usePageMeta';
import { useProductDetail } from './hooks/useProductDetail';
import useRejectOrderItems from './hooks/useRejectOrderItems';
import useRejectSingleOrderItem from './hooks/useRejectSingleOrderItem';

const OrderDetail = () => {
  const { t, i18n } = useTranslation('Orders');
  const isMobile = useIsMobile();
  const { orderId } = useParams();
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const [cancellationPagination, setCancellationPagination] = usePagination();
  const [currentStep, setCurrentStep] = useState(0);
  const [productStatus, setProductStatus] = useState(undefined);
  const [isProductInfoDrawerVisible, setIsProductInfoDrawerVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { setSteps } = useTour();

  useBreadcrumbs();
  usePageMeta({ setStepperStep: setCurrentStep });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const productDetailSteps = getProductDetailSteps({ t });

  const { approveCancellationPlans } = useApproveCancellationPlansMutation({
    mutationOptions: {
      onSuccess: () => {
        openNotification({
          message: t('transaction_success'),
          description: t('refund_approved_success'),
        });
      },
      onError: () => {
        openNotification({
          message: t('transaction_failed'),
          description: t('refund_approved_failed'),
          type: 'error',
        });
      },
    },
  });

  const { orderDetail, isLoadingOrderDetail } = useOrderItemDetail({ orderId });
  const { packages, packageItemsIds, isLoadingPackages } = usePackagesItems({
    orderId,
  });

  const { data: sellerDetail } = useDataSourceDetail({
    dataSourceId: orderDetail?.datasource,
    enabled: Boolean(orderDetail?.datasource),
  });

  const { dataSource, total, isDataSourceLoading } = useDataSource({
    pagination,
    filters,
    orderId,
    status: productStatus,
  });

  const { productDetail, isProductDetailLoading } = useProductDetail({
    product: selectedProduct,
    isFetch: isProductInfoDrawerVisible,
  });

  const getOrderItemQueryKey = useCallback(() => {
    const params = {
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
      status__in: productStatus,
      order: orderId,
    };
    return [...orderItemQueryKey, params];
  }, [pagination, filters, productStatus, orderId]);

  const { approveBulkRefund } = useApproveBulkRefundMutation({
    mutationOptions: {
      onSuccess: () => {
        const isAllProducts = currentStep === Step.ALL_PRODUCTS;
        const params = {
          order_item__order: orderId,
          ...limitQuery,
        };

        queryClient.invalidateQueries({
          queryKey: isAllProducts ? [QueryKey.CANCELLATION, { ...params }] : getOrderItemQueryKey(),
        });
      },
    },
  });

  const { rejectOrderItems, isRejecting } = useRejectOrderItemsMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries(getOrderItemQueryKey());
      },
    },
  });

  const { onApproveOrderItems } = useApproveOrderItems({
    approveBulkRefund,
  });
  const { onRejectRefundRequests } = useBulkRejectRefundRequests({
    onSettled: async () => {
      const params = {
        order_item__order: orderId,
        ...limitQuery,
      };
      await queryClient.invalidateQueries([QueryKey.CANCELLATION, { ...params }]);
      await queryClient.invalidateQueries(getOrderItemQueryKey());
    },
  });

  const { onRejectOrderItems } = useRejectOrderItems({
    t,
    orderId,
    rejectOrderItems,
  });

  const {
    onOpenSingleRejectModal,
    onSingleReject,
    isSingleRejectModalVisible,
    onCancelSingleRejectModal,
  } = useRejectSingleOrderItem({
    t,
    rejectOrderItems,
    orderId,
  });

  const { onShowModal: onShowApproveRefundRequestsModal, ...bulkApproveRefundRequestsProps } =
    useBulkAction({
      bulkActionMutate: onApproveOrderItems,
      isSingleRequest: true,
    });
  const {
    onShowModal: onShowRejectRefundRequestsModal,
    onCloseModal: onCloseRejectRefundModal,
    ...bulkRejectRefundRequestsProps
  } = useBulkAction({
    bulkActionMutate: onRejectRefundRequests,
  });
  const { onShowModal: onShowRejectOrderItemsModal, ...bulkRejectOrderItemsProps } = useBulkAction({
    bulkActionMutate: onRejectOrderItems,
    isSingleRequest: true,
  });

  const { cancellationDataSource, cancellationDataSourceTotal, isCancellationDataSourceLoading } =
    useCancellationDataSource({
      pagination: cancellationPagination,
      filters,
      orderId,
    });

  useEffect(() => {
    setSteps(
      getTourSteps({
        t,
        cancellationDataSource,
      })
    );
  }, [t, cancellationDataSource, currentStep, isDataSourceLoading]);

  const handleProductInfoClick = (record) => {
    setIsProductInfoDrawerVisible(true);
    setSelectedProduct(record);
  };

  const columns = getProductDetailsColumns({
    t,
    onOpenSingleRejectModal,
    productStatus,
    language_code: orderDetail?.language_code,
    packageItemsIds,
    handleProductInfoClick,
    isMobile,
  });

  useEffect(() => {
    currentStep === Step.ALL_PRODUCTS
      ? setProductStatus(undefined)
      : setProductStatus(productDetailSteps[currentStep].productStatus);
  }, [currentStep]);

  const cancelationColumns = cancellationPlanColumns({
    t,
    approveCancellationPlans,
  });

  const getSellerName = () => {
    const sellerName = sellerDetail?.name;

    return sellerName || '';
  };

  const stepHandleOnChange = (step) => {
    setCurrentStep(step);
  };

  const orderInformationBoxes = cardBoxItems({ t, i18n, orderDetail });

  const tableActions = getTableActions({
    t,
    currentStep,
    onShowApproveRefundRequestsModal,
    onShowRejectRefundRequestsModal,
    onShowRejectOrderItemsModal,
    sellerDetail,
    dataSource,
  });
  const isStepDisabled =
    currentStep === Step.ALL_PRODUCTS ||
    currentStep === Step.DELIVERED_TO_SHIPPING ||
    currentStep === Step.DELIVERED;

  const rowSelection = {
    selectedRowKeys,
    onChange: (nextSelectedRowKeys) => {
      setSelectedRowKeys(nextSelectedRowKeys);
    },
    getCheckboxProps: (record) => {
      const hasCancelRequest = record?.orderCancellationRequests?.some(
        (request) =>
          request.cancellation_type === 'cancel' &&
          request.status === cancellationPlanStatuses.WAITING
      );

      const hasRefund = record?.orderCancellationRequests?.some(
        (request) =>
          request.cancellation_type === 'refund' &&
          request.status === cancellationPlanStatuses.WAITING
      );

      const canCancel = hasCancelRequest && sellerDetail?.cancel_management_type;

      let isDisabled = isStepDisabled;

      if (hasRefund || canCancel) {
        isDisabled = false;
      }

      return {
        disabled: isDisabled,
      };
    },
  };

  const orderStatusesRenderMap = useMemo(() => {
    return getOrderStatusesRenderMap({ t });
  }, [t]);

  return (
    <section className="order-detail">
      <AkinonSpin spinning={isLoadingOrderDetail}>
        <Box className="box-primary order-no-box reconciliation-main-info-row">
          <div className="reconciliation-main-info">
            <div>{t('order_no', { orderNumber: orderDetail?.number })}</div>
            <div className="reconciliation-status-tag">
              <Tag color={orderStatusesRenderMap[orderDetail?.status]?.tagColor}>
                {orderStatusesRenderMap[orderDetail?.status]?.label}
              </Tag>
            </div>
          </div>
          <Show when={isSuperUser}>
            <div className="reconciliation-main-info">
              <div>
                {t('seller')}: {getSellerName()}
              </div>
            </div>
          </Show>
        </Box>
      </AkinonSpin>
      <div className="card-box order-main-information">
        {orderInformationBoxes?.map((item, index) => (
          <div className="card-box--item" key={index}>
            <AkinonSpin spinning={isLoadingOrderDetail} wrapperClassName="w-100">
              <div className="card-box--header">{item.header}</div>
              <div className="card-box--content success">{item.content}</div>
            </AkinonSpin>
          </div>
        ))}
      </div>

      <OrderDetailList
        t={t}
        orderDetail={orderDetail}
        packages={packages}
        isLoadingOrderDetail={isLoadingOrderDetail}
        isLoadingPackages={isLoadingPackages}
      />

      <AkinonSteps current={currentStep} onChange={stepHandleOnChange}>
        {productDetailSteps.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>

      <Divider />

      <ProductInfoDrawer
        open={isProductInfoDrawerVisible}
        onClose={() => setIsProductInfoDrawerVisible(false)}
        productDetail={productDetail}
        isProductDetailLoading={isProductDetailLoading}
      />

      <AkinonTable
        title={t('products')}
        rowKey="id"
        dataSource={dataSource}
        optionsUrl={getOrderItemsUrl}
        columns={columns}
        loading={isDataSourceLoading}
        total={total}
        pagination={pagination}
        tableActions={tableActions}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        rowSelection={rowSelection}
        rowClassName={(record) =>
          clsx({
            'order-detail__row--cancelled-red':
              record.status === ProductStatus.CANCELLED_AND_RETURNED,
            'order-detail__row--waiting-red': record.status === ProductStatus.CANCELLATION_WAITING,
          })
        }
      />

      <OrderInfoPanels orderDetail={orderDetail} />

      {cancellationDataSource?.length > 0 && (
        <AkinonTable
          className="order-detail-lists"
          wrapperClassName="order-detail-refund-requests"
          title={t('cancel_refund_requests')}
          pagination={cancellationPagination}
          setPagination={setCancellationPagination}
          columns={cancelationColumns}
          loading={isCancellationDataSourceLoading}
          total={cancellationDataSourceTotal}
          rowKey="id"
          dataSource={cancellationDataSource}
          optionsUrl={getCancellationPlansUrl}
          description={`${cancellationDataSourceTotal} ${t('results.found')}`}
        />
      )}

      <SingleRejectModal
        onSingleReject={onSingleReject}
        isSingleRejectModalVisible={isSingleRejectModalVisible}
        onCancelSingleRejectModal={onCancelSingleRejectModal}
        isRejecting={isRejecting}
      />
      <BulkActionModal
        {...bulkApproveRefundRequestsProps}
        modalProps={{
          title: t('bulk_approve_refund_requests'),
          description: t('bulk_approve_refund_requests_desc'),
        }}
        progressModalProps={{ title: t('bulk_approve_refund_requests') }}
      />
      <BulkActionModal
        {...bulkRejectOrderItemsProps}
        CustomModal={BulkRejectOrderItemsModal}
        modalProps={{
          title: t('bulk_reject_order_items'),
        }}
        progressModalProps={{ title: t('bulk_reject_order_items') }}
      />
      <BulkActionModal
        {...bulkRejectRefundRequestsProps}
        CustomModal={BulkRejectRefundRequestModal}
        modalProps={{
          title: t('bulk_reject_refund_requests'),
          dataSource,
          isDataSourceLoading,
          onCloseRejectRefundModal,
          selectedRowKeys,
        }}
      />
    </section>
  );
};

export default OrderDetail;
