const useAddAllProductsToCollection = ({ addAllProductsToCollection, collectionId }) => {
  const onAddAllProducts = async () => {
    addAllProductsToCollection({
      requestBody: { collection_id: collectionId },
    });
  };

  return { onAddAllProducts };
};

export default useAddAllProductsToCollection;
