import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

import { getRequestBodyFromFormValues } from '../common';

const defaultRule = {
  slug: 'any-rule',
};

export function useOnSubmit({
  t,
  optionId,
  updateShippingCompanyOption,
  createShippingCompanyOptions,
  defaultPriority,
  shippingCompanyOptionHttpOptions,
  isSuperUser,
  configId,
  createDataSourceShippingCompanyOptionConfig,
  updateDataSourceShippingCompanyOptionConfig,
}) {
  const navigate = useAppNavigate();

  return (formValues) => {
    if (!formValues.priority) formValues.priority = defaultPriority;
    if (!has(formValues, 'rule') || isEmpty(formValues?.rule)) {
      formValues['rule'] = [defaultRule];
    }

    const findPasswordConfig = formValues?.config?.find((item) => item.key === 'password');
    if (findPasswordConfig?.value?.split('').every((char) => char === '*')) {
      formValues.config = formValues.config.filter((item) => item.key !== 'password');
    }

    const requestBody = getRequestBodyFromFormValues({
      formValues,
      shippingCompanyOptionHttpOptions,
    });

    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('delivery_setting_message.save.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('delivery_setting_message.save.error'),
    };

    let patch = updateDataSourceShippingCompanyOptionConfig;
    if (isSuperUser && !configId) {
      patch = updateShippingCompanyOption;
    }

    const post = isSuperUser
      ? createShippingCompanyOptions
      : createDataSourceShippingCompanyOptionConfig;

    const patchRequest = (params) =>
      patch(
        { ...params, requestBody },
        { onSuccess: () => navigate(RouteUrls.settings.deliverySettingsShipmentSettings) }
      );

    const postRequest = (params) =>
      post(
        { ...params, requestBody },
        { onSuccess: () => navigate(RouteUrls.settings.deliverySettingsShipmentSettings) }
      );

    if (optionId || configId) {
      patchRequest({ optionId, configId, requestConfig });
    } else {
      postRequest({ requestConfig });
    }
  };
}
