import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput, { AkinonInputNumber, AkinonTextArea } from '@components/AkinonInput';
import AsyncSelect from '@components/AkinonSelect/AsyncSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSwitch from '@components/AkinonSwitch';
import Box from '@components/utility/box';
import {
  getCitiesUrl,
  getCountriesUrl,
  getDistrictsUrl,
  getTownshipsUrl,
} from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import { useGetLocationDetailQuery } from '@services/api/hooks/useGetLocationDetailQuery';
import { usePatchLocationMutation } from '@services/api/hooks/usePatchLocationMutation';
import { usePostLocationMutation } from '@services/api/hooks/usePostLocationMutation';
import { IconInfoCircle } from '@tabler/icons-react';
import { APIProvider, Map, Marker, useMarkerRef } from '@vis.gl/react-google-maps';
import { Col, Divider, Row } from 'antd';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormKey, getFormSchema } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const LocationsForm = () => {
  const { t, i18n } = useTranslation(TranslationKey.LOCATIONS);
  const { locationId } = useParams();
  const navigate = useAppNavigate();
  const { locationDetail, isFetchingLocationDetail } = useGetLocationDetailQuery({
    locationId,
    queryOptions: {
      enabled: Boolean(locationId),
    },
  });

  const { postLocation, isPostingLocation } = usePostLocationMutation();
  const { patchLocation, isPatchingLocation } = usePatchLocationMutation();

  const schema = getFormSchema();
  const { control, handleSubmit, reset, watch, setValue } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  useBreadcrumbs();
  usePageMeta();

  const latitude = watch(FormKey.LATITUDE);
  const longitude = watch(FormKey.LONGITUDE);
  const country = watch(FormKey.COUNTRY);
  const city = watch(FormKey.CITY);
  const township = watch(FormKey.TOWNSHIP);

  const onSubmit = (formValues) => {
    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('location_message.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('location_message.error'),
    };

    const formAction = locationDetail ? patchLocation : postLocation;
    const formActionConfig = locationDetail
      ? { urlParams: { locationId: locationDetail.id }, ...requestConfig }
      : { ...requestConfig };
    formAction(
      {
        requestBody: formValues,
        requestConfig: formActionConfig,
      },
      {
        onSuccess() {
          navigate(RouteUrls.stockLocations.landing);
        },
      }
    );
  };

  useEffect(() => {
    reset(locationDetail && schema.parse(locationDetail));
  }, [locationDetail]);

  const [markerRef] = useMarkerRef();

  const INITIAL_CAMERA = {
    center: { lat: latitude ?? 41, lng: longitude ?? 29 },
    zoom: 12,
  };

  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);
  const handleCameraChange = useCallback((ev) => {
    setCameraProps(ev.detail);
    setValue(FormKey.LATITUDE, ev.detail.center.lat);
    setValue(FormKey.LONGITUDE, ev.detail.center.lng);
  });

  return (
    <section className="locations-form">
      <AkinonSpin spinning={isFetchingLocationDetail}>
        <Box
          className="box-primary form-box"
          title={t('location_edit_title')}
          subtitle={t('location_edit_desc')}
        >
          <AkinonForm className="akn-form akn-form-v2" layout="vertical">
            <Divider className="form-divider mt-0" orientation="left">
              {t('tag')}
            </Divider>
            <AkinonInput
              formItemProps={{
                name: FormKey.NAME,
                label: t('location_name'),
                control,
                required: true,
              }}
              placeholder={t('location_name')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.LOCATION_CODE,
                label: t('location_code'),
                control,
                required: true,
              }}
              placeholder={t('location_code')}
            />
            <Divider className="form-divider" orientation="left">
              {t('behaviors')}
            </Divider>
            <AkinonInputNumber
              formItemProps={{
                name: FormKey.PRIORITY,
                label: t('location_priority'),
                control,
                tooltip: {
                  title: t('priority_tooltip_title'),
                  icon: <IconInfoCircle />,
                  className: 'stroke-spanish-gray',
                },
              }}
              placeholder={t('location_priority')}
            />
            <Divider className="form-divider" orientation="left">
              {t('address')}
            </Divider>

            <AsyncSelect
              formItemProps={{
                name: FormKey.COUNTRY,
                label: t('country'),
                control,
                required: true,
              }}
              onChange={() => {
                setValue(FormKey.CITY, null);
                setValue(FormKey.TOWNSHIP, null);
                setValue(FormKey.DISTRICT, null);
              }}
              optionFilterProp="label"
              showSearch
              allowClear
              placeholder={t('country')}
              queryProps={{
                remoteUrl: getCountriesUrl,
                remoteKey: [QueryKey.COUNTRIES, { is_active: true }],
                searchKey: 'name',
                params: {
                  ...limitQuery,
                  is_active: true,
                },
              }}
              labelKey="name"
              valueKey="id"
            />

            <Row gutter={24} className="mb-3">
              <Col span={12}>
                <AsyncSelect
                  formItemProps={{
                    name: FormKey.CITY,
                    label: t('city'),
                    control,
                    required: true,
                  }}
                  onChange={() => {
                    setValue(FormKey.TOWNSHIP, null);
                    setValue(FormKey.DISTRICT, null);
                  }}
                  placeholder={t('city')}
                  optionFilterProp="label"
                  showSearch
                  allowClear
                  queryProps={{
                    remoteUrl: getCitiesUrl,
                    remoteKey: [QueryKey.CITIES, { is_active: true }],
                    searchKey: 'name',
                    params: {
                      country,
                      ...limitQuery,
                      is_active: true,
                    },
                    queryOptions: {
                      enabled: !isEmpty(country),
                    },
                  }}
                  labelKey="name"
                  valueKey="id"
                />
              </Col>
              <Col span={12}>
                <AsyncSelect
                  formItemProps={{
                    name: FormKey.TOWNSHIP,
                    label: t('township'),
                    control,
                    required: true,
                  }}
                  onChange={() => {
                    setValue(FormKey.DISTRICT, null);
                  }}
                  placeholder={t('township')}
                  optionFilterProp="label"
                  showSearch
                  allowClear
                  queryProps={{
                    remoteUrl: getTownshipsUrl,
                    remoteKey: [QueryKey.TOWNSHIPS, { is_active: true }],
                    searchKey: 'name',
                    params: {
                      city,
                      ...limitQuery,
                      is_active: true,
                    },
                    queryOptions: {
                      enabled: !isEmpty(city),
                    },
                  }}
                  labelKey="name"
                  valueKey="id"
                />
              </Col>
            </Row>
            <Row gutter={24} className="mb-3">
              <Col span={12}>
                <AsyncSelect
                  formItemProps={{
                    name: FormKey.DISTRICT,
                    label: t('district'),
                    control,
                    required: true,
                  }}
                  placeholder={t('district')}
                  queryProps={{
                    remoteUrl: getDistrictsUrl,
                    remoteKey: [QueryKey.DISTRICTS, { is_active: true }],
                    searchKey: 'name',
                    params: {
                      city,
                      township,
                      ...limitQuery,
                      is_active: true,
                    },
                    queryOptions: {
                      enabled: Boolean(city) && Boolean(township),
                    },
                  }}
                  optionFilterProp="label"
                  showSearch
                  allowClear
                />
              </Col>
              <Col span={12}>
                <AkinonInput
                  formItemProps={{
                    name: FormKey.POSTAL_CODE,
                    label: t('postal_code'),
                    control,
                  }}
                  placeholder={t('postal_code')}
                />
              </Col>
            </Row>

            <AkinonTextArea
              formItemProps={{
                name: FormKey.LINE,
                label: t('address_line'),
                control,
                required: true,
              }}
              placeholder={t('address_line')}
              style={{ transition: 'none' }}
            />

            <AkinonInputNumber
              formItemProps={{
                name: FormKey.PHONE_NUMBER,
                label: t('phone_number'),
                control,
              }}
              placeholder={t('phone_number')}
            />

            <AkinonInput
              formItemProps={{
                name: FormKey.EMAIL,
                label: t('email'),
                control,
              }}
              placeholder={t('email')}
            />

            <Row gutter={24} className="mb-3">
              <Col span={12}>
                <AkinonInputNumber
                  formItemProps={{
                    name: FormKey.LATITUDE,
                    label: t('latitude'),
                    control,
                    required: true,
                  }}
                  placeholder={t('latitude')}
                  min={-90}
                  max={90}
                />
              </Col>
              <Col span={12}>
                <AkinonInputNumber
                  formItemProps={{
                    name: FormKey.LONGITUDE,
                    label: t('longitude'),
                    control,
                    required: true,
                  }}
                  placeholder={t('longitude')}
                  min={-180}
                  max={180}
                />
              </Col>
            </Row>

            <AkinonSwitch
              formItemProps={{
                name: FormKey.IS_RETURN_LOCATION,
                label: t('return_accepted'),
                control,
                tooltip: {
                  title: t('return_accepted_desc'),
                  icon: <IconInfoCircle />,
                  className: 'stroke-spanish-gray',
                },
              }}
              checkedChildren={t('yes')}
              unCheckedChildren={t('no')}
            />

            <AkinonSwitch
              formItemProps={{ name: FormKey.IS_ACTIVE, label: t('location_is_active'), control }}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
            />
            {/*
             * APIProvider Should be moved to global scope in case of usage on multiple pages
             * it still should be lazy loaded
             */}
            <APIProvider apiKey={import.meta.env.GOOGLE_MAPS_KEY}>
              <div className="mb-3">
                <Map
                  style={{ width: '100%', height: '400px', borderRadius: 5, overflow: 'hidden' }}
                  {...cameraProps}
                  onCameraChanged={handleCameraChange}
                  defaultZoom={9}
                  gestureHandling={'greedy'}
                  disableDefaultUI={true}
                >
                  <Marker
                    ref={markerRef}
                    position={{ lat: cameraProps?.center?.lat, lng: cameraProps?.center?.lng }}
                  />
                </Map>
              </div>
            </APIProvider>
            <div className="locations-form__footer">
              <AkinonFormItem>
                <AkinonButton
                  onClick={handleSubmit(onSubmit)}
                  loading={isPostingLocation || isPatchingLocation}
                >
                  {t('send').toLocaleUpperCase(i18n.language)}
                </AkinonButton>
              </AkinonFormItem>
            </div>
          </AkinonForm>
        </Box>
      </AkinonSpin>
    </section>
  );
};

export default LocationsForm;
