import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import { ConfigTypeInputMap } from '@pages/ProductsAndCategories/ProductForm/common';
import { Checkbox, Divider, Form, Modal, Space, Typography } from 'antd';
import map from 'lodash/map';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * @param {{ form: import('react-hook-form').UseFormReturn, }} props
 */
const BulkAttributeUpdateModal = ({
  form,
  setInvisible,
  isVisible,
  dataSource,
  variants,
  variantDynamicFields,
  variantListAttributeFields,
  selectedRowKeys,
}) => {
  const { t, i18n } = useTranslation('ProductsAndCategories');
  const [selectedVariants, setSelectedVariants] = useState([]);
  const { reset, control, trigger } = useForm({
    mode: 'onChange',
  });
  const dataSourcesToUpdate = dataSource.filter((item) => selectedRowKeys?.includes(item.id));

  const defaultValues = useMemo(() => {
    return {
      ...pick(
        form.getValues(),
        variantListAttributeFields?.map((field) => field.key)
      ),
    };
  }, [variantListAttributeFields]);

  const bulkAttributeValues = useWatch({
    control: control,
    defaultValues,
  });

  const { bulk, ...restFormValues } = bulkAttributeValues;

  const handleCheckboxChange = (event) => {
    setSelectedVariants((prevSelectedVariant) => {
      if (event.target.checked) {
        return [...prevSelectedVariant, event.target.value];
      }
      return prevSelectedVariant.filter((variantId) => variantId !== event.target.value);
    });
  };

  const onModalClose = useCallback(() => {
    setSelectedVariants([]);
    setInvisible();
    reset({
      bulk: {},
    });
  }, [setInvisible, setSelectedVariants, reset]);

  const handleApply = useCallback(
    (variantsToApply) => {
      if (variantsToApply.length === 0) return;
      trigger().then((isValid) => {
        if (!isValid) return;
        reset({
          bulk: {},
          ...restFormValues,
          ...Object.entries(bulk)
            .filter(([_, value]) => !!value)
            .reduce((acc, [key, value]) => {
              acc[key] = dataSource.map((item) =>
                variantsToApply.includes(item.id) ? value : restFormValues[key]?.[item.id]
              );
              return acc;
            }, {}),
        });
      });
    },
    [reset, bulk, dataSource, restFormValues]
  );

  const handleBulkAttributeUpdate = useCallback(() => {
    trigger().then((isValid) => {
      if (!isValid) return;

      form.reset({
        ...form.getValues(),
        ...restFormValues,
      });
      onModalClose();
    });
  }, [restFormValues, form]);

  const handleCleanAllContent = useCallback(() => {
    reset({
      bulk: {},
    });
  }, [reset]);

  const toggleAllVariantsCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedVariants(map(dataSourcesToUpdate, 'id'));
    } else {
      setSelectedVariants([]);
    }
  };

  useEffect(() => {
    if (isVisible)
      reset({
        ...pick(
          form.getValues(),
          variantListAttributeFields?.map((field) => field.key)
        ),
      });
  }, [isVisible, variantListAttributeFields]);

  const bulkFormFields = useMemo(() => {
    return variantListAttributeFields?.map((field) => {
      const { data_type, label, ...restProps } = omit(field, ['required', 'initialValue']);
      const Component = ConfigTypeInputMap[data_type];

      return (
        <AkinonFormItem
          key={field.key}
          label={<Text className="text-white">{label}</Text>}
          name={`bulk.${field.key}`}
          control={control}
          isWithColumn
          colSpan={8}
        >
          <Component {...restProps} />
        </AkinonFormItem>
      );
    });
  }, [variantListAttributeFields]);

  const formValues = useMemo(() => {
    return dataSourcesToUpdate?.map((item, index) => {
      return (
        <div className="form-modal-item" key={index}>
          <div className="form-modal-item__wrapper">
            <Checkbox
              value={item.id}
              checked={selectedVariants.includes(item.id)}
              onChange={handleCheckboxChange}
            />
            <Text className={'form-modal-item__variant'}>
              {Object.keys(variants)
                .map((variantKey) => {
                  const variantValue = item[variantKey];
                  const variantLabel = variantDynamicFields
                    .find((field) => field.key === variantKey)
                    .options.find((option) => option.value === variantValue)?.label;
                  return variantLabel ?? variantValue;
                })
                .join('/')}
            </Text>
            <div className="form-modal__root-form-builder">
              {variantListAttributeFields?.map((field) => {
                const { data_type, label, ...restProps } = field;
                const Component = ConfigTypeInputMap[data_type];

                return (
                  <AkinonFormItem
                    key={field.key}
                    label={<Text className="text-white">{label}</Text>}
                    name={`${field.key}.${item.id}`}
                    control={control}
                    isWithColumn
                    colSpan={8}
                    colProps={{
                      className: variantListAttributeFields?.length === 1 && 'ml-auto',
                    }}
                  >
                    <Component {...restProps} />
                  </AkinonFormItem>
                );
              })}
            </div>
          </div>
          <Divider className="form-modal-item__divider" />
        </div>
      );
    });
  }, [variantListAttributeFields, dataSourcesToUpdate, selectedVariants, handleCheckboxChange]);

  return (
    <Modal
      className="form-modal max-w-4xl !min-w-[780px]"
      onCancel={onModalClose}
      open={isVisible}
      destroyOnClose
      footer={null}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Form className="akn-form" layout="vertical">
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{t('bulk.update.product.attributes')}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">
            {t('bulk.update.product.attributes.description')}
          </div>
          <div className="form-modal__root-form-builder">{bulkFormFields}</div>
          <Space direction="horizontal" className="form-modal__form-actions">
            <AkinonButton
              onClick={() => {
                handleApply(map(dataSourcesToUpdate, 'id'));
              }}
              htmlType="button"
              type="primary"
            >
              {t('apply_to_all_variants').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
            <AkinonButton
              onClick={() => {
                handleApply(selectedVariants);
              }}
              disabled={selectedVariants.length === 0}
              htmlType="button"
              type="primary"
            >
              {t('apply_to_selected_variants').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </Space>
          <Divider orientation="left" />
          <Checkbox
            checked={selectedVariants.length === dataSourcesToUpdate.length}
            className="flex items-center text-white"
            onChange={toggleAllVariantsCheckbox}
          >
            {t('select_all_variants')}
          </Checkbox>

          {formValues}
          <Space>
            <AkinonButton type="primary" htmlType="button" onClick={handleBulkAttributeUpdate}>
              {t('save').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </Space>
        </Form>
      </Space>
    </Modal>
  );
};

export default BulkAttributeUpdateModal;
