import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import { Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Title } = Typography;

const CreateProductModal = ({ modalProps, buttonProps }) => {
  const { t, i18n } = useTranslation('ProductsAndCategories');
  return (
    <Modal {...modalProps} footer={null}>
      <div className="create-product-modal__header">
        <div className="create-product-modal__header-badge" />
        <Title className="create-product-modal__header-title">
          {t('add_to_marketplace_catalog')}
        </Title>
      </div>
      <Typography.Text className="create-product-modal__header-description">
        {t('add_to_marketplace_catalog_desc')}
      </Typography.Text>
      <AkinonButton
        className="create-product-modal__submit-button"
        htmlType="submit"
        type="primary"
        {...buttonProps}
      >
        {t('save').toLocaleUpperCase(i18n.language)}
      </AkinonButton>
    </Modal>
  );
};

export default CreateProductModal;
