import './style.scss';

import Box from '@components/utility/box';
import { QueryKey } from '@constants/query';
import { useUser } from '@root/contexts/hooks/useUser';
import { useBasketOfferDetailQuery } from '@services/api/hooks/useBasketOfferDetailQuery';
import { usePostBasketOfferParticipantMutation } from '@services/api/hooks/useBasketOfferParticipantMutation';
import { useBasketOfferParticipantsQuery } from '@services/api/hooks/useBasketOfferParticipantsQuery';
import { queryClient } from '@services/api/queryClient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CampaignConditions from './components/CampaignConditions';
import CampaignInformation from './components/CampaignInformation';
import ProductCollections from './components/ProductCollections';
import { useBreadcrumbs } from './hooks/useBreadCrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const CampaignDetail = () => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const { id: basketOfferId } = useParams();
  const { postBasketOfferParticipants, isPostingBasketOfferParticipants } =
    usePostBasketOfferParticipantMutation({
      mutationOptions: {
        onSuccess: () => {
          queryClient.invalidateQueries([QueryKey.BASKET_OFFER_PARTICIPANTS, basketOfferId]);
        },
      },
    });
  const user = useUser();

  const { basketOfferDetail } = useBasketOfferDetailQuery({ id: basketOfferId });
  const { basketOfferParticipants } = useBasketOfferParticipantsQuery({ id: basketOfferId });
  const hasUserJoinedCampaign = basketOfferParticipants?.some(
    (participant) => participant.datasource === user.datasource
  );

  useBreadcrumbs({ campaignId: basketOfferId });
  usePageMeta({ promotionName: basketOfferDetail?.promotion?.name, user });

  const collectionId =
    basketOfferDetail?.condition?.collection ?? basketOfferDetail?.benefit?.collection;

  const onJoinCampaign = () => {
    postBasketOfferParticipants({
      requestBody: { basket_offer: basketOfferId, datasource: user.datasource },
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('join_campaign_message.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('join_campaign_message.error'),
      },
    });
  };

  return (
    <section className="campaign-detail-container">
      <div className="campaign-detail-box__content">
        <Box title={t('campaign_info')} className="campaign-detail-box box-primary form-box">
          <CampaignInformation basketOfferDetail={basketOfferDetail} />
        </Box>
        <Box title={t('campaign_conditions')} className="campaign-detail-box box-primary form-box">
          <CampaignConditions
            basketOfferDetail={basketOfferDetail}
            isJoiningCampaing={isPostingBasketOfferParticipants}
            onJoinCampaign={onJoinCampaign}
            hasUserJoinedCampaign={hasUserJoinedCampaign}
          />
        </Box>
      </div>
      {hasUserJoinedCampaign && basketOfferId && <ProductCollections collectionId={collectionId} />}
    </section>
  );
};

export default CampaignDetail;
