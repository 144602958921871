import { postReconciliationsRebateRulesUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostReconciliationRebateRuleMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postReconciliationsRebateRulesUrl, requestBody, {
        ...requestConfig,
      }),
    ...mutationOptions,
  });

  return {
    postReconciliationRebateRule: mutate,
    isPostingReconciliationRebateRule: isLoading,
  };
}
