import NavigationList from '@components/Navigation/List';
import { Color } from '@constants/theme';
import { useEnv } from '@hooks/useEnv';
import { useUser } from '@root/contexts/hooks/useUser';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNavigationItems } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const InventoryManagementLanding = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const user = useUser();
  const { isProd } = useEnv();

  useBreadcrumbs();
  usePageMeta();

  const navigationItems = getNavigationItems({ t, user, isProd });

  return <NavigationList items={navigationItems} cardColor={Color.SHIP_COVE} />;
};

export default InventoryManagementLanding;
