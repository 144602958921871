import './index.scss';

import AkinonButton from '@components/AkinonButton';
import If from '@components/If';
import { antdProgressStatus } from '@constants/commontypes';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import useDocumentDownloadQuery from '@services/api/hooks/useDocumentDownloadQuery';
import { Col, Modal, Progress, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const BulkActionProgressModal = ({
  open,
  messages,
  progressStatus,
  progressPercentage,
  shouldNavigateOnDone = false,
  navigateTo = '',
  onCancel,
  title = '',
  succeededCountTransKey = '',
  failedCountTransKey = '',
}) => {
  const { succeeded, failed, total, processed } = progressStatus?.state ?? {};
  const { finishButtonText } = messages ?? {};
  const { t } = useTranslation(TranslationKey.PRODUCTS_AND_CATEGORIES);
  const [shouldStartDownload, setShouldStartDownload] = useState(false);

  const isDone = processed === total;

  const navigate = useAppNavigate();

  useDocumentDownloadQuery({
    documentUrl: progressStatus?.efile,
    queryOptions: {
      enabled: shouldStartDownload && Boolean(progressStatus?.efile),
    },
    onSettled: (data) => {
      setShouldStartDownload(false);
      if (data?.url) {
        window.location.href = data?.url;
      }
    },
  });

  const startDownload = () => {
    setShouldStartDownload(true);
  };

  function destroyModal() {
    onCancel?.();
    setShouldStartDownload(false);
  }

  const isWaitingForEFile = isDone && !progressStatus?.efile;

  return (
    <Modal
      open={open}
      title={title}
      className="ant-modal-progress bulk-actions-progress-modal"
      closable={true}
      footer={null}
      onCancel={destroyModal}
      centered
      width={650}
    >
      <Row className="body" gutter={[0, 30]} justify="center" align="center">
        <Col span={24}>
          <span>
            <strong>{t('process.status')}&nbsp;</strong>
          </span>

          <Progress
            strokeLinecap="butt"
            percent={progressPercentage}
            status={antdProgressStatus.active}
          />
        </Col>
        <Col span={24} sm={12}>
          <If
            condition={succeeded > 0}
            then={
              <Space direction="vertical">
                <Trans
                  defaults={t(succeededCountTransKey, {
                    count: succeeded,
                  })}
                  i18nKey={succeededCountTransKey}
                  values={{ count: succeeded }}
                  components={[<strong key="strong" className="green text-xs" />]}
                />
                {shouldNavigateOnDone && (
                  <AkinonButton
                    type="primary"
                    loading={isDone}
                    onClick={() => navigate(navigateTo)}
                  >
                    {finishButtonText}
                  </AkinonButton>
                )}
              </Space>
            }
            otherwise={null}
          />
        </Col>
        <Col span={24} sm={12} className="download-error-records-wrapper">
          <If
            condition={failed > 0}
            then={
              <Space direction="vertical">
                <Trans
                  defaults={t(failedCountTransKey, { count: failed })}
                  i18nKey={failedCountTransKey}
                  values={{ count: failed }}
                  components={[<strong key="strong" className="red text-xs" />]}
                />
                <AkinonButton
                  loading={!isDone || shouldStartDownload || isWaitingForEFile}
                  type="danger"
                  disabled={!isDone || shouldStartDownload || !progressStatus?.efile}
                  onClick={startDownload}
                  className="w-100"
                >
                  {!isDone ? t('file.preparing') : t('download_error_records')}
                </AkinonButton>
              </Space>
            }
            otherwise={null}
          />
        </Col>
      </Row>
    </Modal>
  );
};
