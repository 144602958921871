import { getDataSourcesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { getIsNullBooleanAttributeOptions, WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useMemo } from 'react';

import { StaticFilterKey } from '../common';

export const useStaticFilters = ({ t }) => {
  const userRole = useUserRole();

  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });

  return useMemo(
    () => ({
      fields: [
        isSuperUser && {
          name: StaticFilterKey.DATASOURCE,
          widget: WidgetType.ASYNC_SELECT,
          showSearch: true,
          labelInValue: true,
          queryProps: {
            remoteKey: QueryKey.DATA_SOURCES,
            remoteUrl: getDataSourcesUrl,
            params: {
              limit: 20,
              sort: 'name',
              is_active: true,
            },
            searchKey: 'name__icontains',
          },
          placeholder: t('seller'),
        },
        {
          name: StaticFilterKey.NAME,
          widget: WidgetType.INPUT,
          placeholder: t('product_name'),
        },
        {
          name: StaticFilterKey.SKU,
          widget: WidgetType.INPUT,
          placeholder: t('sku'),
        },
        {
          name: StaticFilterKey.BASE_CODE,
          widget: WidgetType.INPUT,
          placeholder: t('basecode'),
        },
        {
          name: StaticFilterKey.PRODUCT_ISNULL,
          widget: WidgetType.CHECKBOX_GROUP,
          placeholder: t('offer.product.related'),
          options: [{ label: t('offer.product.related'), value: false }],
        },
        {
          name: StaticFilterKey.CATEGORY,
          widget: WidgetType.TREESELECT,
          placeholder: t('category'),
          showSearch: true,
          treeDefaultExpandAll: true,
          virtual: false,
          style: { width: '100%' },
          loading: isCategoriesLoading,
          dropdownStyle: { maxHeight: 400, overflow: 'auto' },
          treeData: categoryTreeData,
          treeNodeFilterProp: 'title',
          allowClear: true,
        },
        {
          name: StaticFilterKey.IMAGE_ISNULL,
          widget: WidgetType.SELECT,
          showSearch: true,
          optionFilterProp: 'label',
          placeholder: t('product_image'),
          options: getIsNullBooleanAttributeOptions({ t }),
        },
      ].filter(Boolean),
    }),
    [t, categoryTreeData, isCategoriesLoading, isSuperUser]
  );
};
