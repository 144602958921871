import './styles.scss';

import { getImageResourceUrl, ImageExtension } from '@common/image';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput, { AkinonPasswordInput } from '@components/AkinonInput';
import { RouteUrls } from '@constants/routeUrls';
import { useOAuthProvidersQuery } from '@services/api/hooks/useOAuthProvidersQuery';
import { Divider, Form, Typography } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FormMetaKey, signInFormResolver } from './common';
import SsoProviders from './components/SsoProviders';
import { useSignIn } from './hooks/useSignIn';

const { Title, Text } = Typography;

const SignIn = () => {
  const { t, i18n } = useTranslation('Authentication');
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver: signInFormResolver,
  });
  const { postTokenObtain, isSignInMutating, resetPostTokenObtain } = useSignIn();

  const { providers, isFetchingProviders } = useOAuthProvidersQuery();

  const onSubmit = (formValues) => {
    resetPostTokenObtain();
    postTokenObtain({
      requestBody: formValues,
      requestConfig: { suppressedNotifications: ['error', 'success'] },
    });
  };

  return (
    <section className="sign-in">
      <Title level={1} className="sign-in__app-name">
        {t('seller.center').toLocaleUpperCase(i18n.language)}
      </Title>
      <div className="sign-in__form-box box-primary form-box boxWrapper">
        <div className="sign-in__form-box-header">
          <Title level={2} className="sign-in__title">
            {t('login')}
          </Title>
          <Divider className="sign-in__form-box-header-divider" />
          <div className="sign-in__form-box-header-flag" />
        </div>
        <AkinonForm layout="vertical" className="sign-in__form" requiredMark={false}>
          <AkinonFormItem
            name={FormMetaKey.EMAIL}
            control={control}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            label={t('email')}
            required
          >
            <AkinonInput placeholder={t('email')} />
          </AkinonFormItem>
          <AkinonPasswordInput
            formItemProps={{
              name: FormMetaKey.PASSWORD,
              control,
              labelCol: { span: 8 },
              wrapperCol: { span: 24 },
              label: t('password'),
              required: true,
            }}
            placeholder={t('password')}
          />
          <Form.Item wrapperCol={{ span: 24 }}>
            <AkinonButton
              loading={isSignInMutating}
              htmlType="submit"
              onClick={handleSubmit(onSubmit)}
              type="primary"
              className="sign-in__form-submit-button"
            >
              {t('login').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </Form.Item>
        </AkinonForm>

        <Link className="sign-in__forgot-password-link" to={RouteUrls.public.forgotPassword}>
          {t('forgot.password')}
        </Link>
        <SsoProviders providers={providers} isFetchingProviders={isFetchingProviders} />
      </div>

      <footer className="sign-in__footer">
        <img
          className="sign-in__footer-logo"
          src={getImageResourceUrl({
            name: 'akinon-logo',
            extension: ImageExtension.SVG,
          })}
          alt="Akinon Logo"
        />
        <Text className="sign-in__footer-text">
          {t('layout.footer.copyRight', {
            year: new Date().getFullYear(),
          })}
        </Text>
      </footer>
    </section>
  );
};

export default SignIn;
