import { zodResolver } from '@hookform/resolvers/zod';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import { z } from 'zod';

export const FormKey = {
  FILE: 'file',
};

export const getFormResolver = ({ t }) => {
  return zodResolver(
    z.object({
      file: z
        .object({
          fileList: z
            .array(z.object({ size: z.number(), originFileObj: z.instanceof(File) }))
            .nullish(),
        })
        .superRefine(({ fileList }, ctx) => {
          const size10MB = 1024 * 1024 * 10;

          if (isEmpty(fileList)) {
            ctx.addIssue({
              message: t('upload_at_least_one_file'),
              code: z.ZodIssueCode.custom,
            });
            return;
          }

          const sumeOfFileSizes = sum(fileList.map((file) => file.size));

          if (sumeOfFileSizes > size10MB) {
            ctx.addIssue({
              message: t('file_size_error_message', { fileSize: '10MB' }),
              code: z.ZodIssueCode.custom,
            });
          }
        })
        .nullish(),
    })
  );
};
