import { useMemo } from 'react';

import { getStaticFilters } from '../common';

const useFilterFields = ({ t, showSkuFilter, showBaseCodeFilter }) => {
  const staticFilters = useMemo(
    () => getStaticFilters({ t, showSkuFilter, showBaseCodeFilter }),
    [t, getStaticFilters, showSkuFilter, showBaseCodeFilter]
  );

  return useMemo(
    () => ({
      dynamic: { fields: [], isLoading: false },
      static: staticFilters,
    }),
    [staticFilters]
  );
};

export default useFilterFields;
