import AkinonSpin from '@components/AkinonSpin';
import React from 'react';

const CustomerInfo = ({ t, orderDetail, isLoadingOrderDetail }) => {
  return (
    <AkinonSpin spinning={isLoadingOrderDetail}>
      <div className="detail-item">
        <div className="detail-item">
          <div className="detail-item--title">{t('namesurname')}</div>
          <span className="detail-item--desc">
            {orderDetail?.billing_address_obj?.first_name
              ? `${orderDetail?.billing_address_obj?.first_name} ${orderDetail?.billing_address_obj?.last_name}`
              : '-'}
          </span>
        </div>
        <div className="detail-item">
          <div className="detail-item--title">{t('email')}</div>
          <span className="detail-item--desc">
            {orderDetail?.billing_address_obj?.email ?? '-'}
          </span>
        </div>
        <div className="detail-item">
          <div className="detail-item--title">{t('phone')}</div>
          <span className="detail-item--desc">
            {orderDetail?.billing_address_obj?.phone_number ?? '-'}
          </span>
        </div>
      </div>
    </AkinonSpin>
  );
};

export default CustomerInfo;
