import './styles.scss';

import { getImageResourceUrl, ImageExtension } from '@common/image';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import { AkinonPasswordInput } from '@components/AkinonInput';
import { openNotification } from '@components/AkinonNotification';
import Show from '@components/Show';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { usePutResetPasswordMutation } from '@services/hooks/common/usePutResetPasswordMutation';
import { useResetPasswordQuery } from '@services/hooks/common/useResetPasswordQuery';
import { Divider, Form, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormMetaKey, getFormSchema } from './common';

const { Title, Text } = Typography;

const ResetPassword = () => {
  const navigate = useAppNavigate();
  const { resetToken } = useParams();
  const { t, i18n } = useTranslation('Authentication');
  const { isPasswordResetTokenValid, passwordResetTokenError } = useResetPasswordQuery({
    resetToken,
  });
  const { resetPassword, isResettingPassword } = usePutResetPasswordMutation();
  const schema = getFormSchema({ t });
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (!isPasswordResetTokenValid && passwordResetTokenError?.response?.status === 404) {
      navigate(RouteUrls.public.signIn);
      openNotification({
        message: t('invalid_request'),
        description: t('password_reset_token_invalid_desc'),
        type: 'error',
      });
    }
  }, [isPasswordResetTokenValid, passwordResetTokenError]);

  const onSubmit = (formValues) => {
    resetPassword(
      {
        requestBody: formValues,
        requestConfig: {
          suppressedNotifications: ['success', 'error'],
        },
        resetToken,
      },
      {
        onSuccess() {
          navigate(RouteUrls.public.signIn);
          openNotification({
            message: t('api.request.successMessage'),
            description: t('password_changed'),
          });
        },
        onError(error) {
          if (error?.response?.status === 404) {
            navigate(RouteUrls.public.signIn);
            openNotification({
              message: t('invalid_request'),
              description: t('password_reset_token_invalid_desc'),
              type: 'error',
            });
          }
        },
      }
    );
  };

  return (
    <section className="reset-password">
      <Title level={1} className="reset-password__app-name">
        {t('seller.center').toLocaleUpperCase(i18n.language)}
      </Title>
      <div className="reset-password__form-box  box-primary form-box boxWrapper">
        <div className="reset-password__form-box-header">
          <Title level={2} className="reset-password__title">
            {t('reset_password')}
          </Title>
          <Divider className="reset-password__form-box-header-divider" />
          <div className="reset-password__form-box-header-flag" />
        </div>
        <AkinonForm
          control={control}
          schema={schema}
          onFinish={handleSubmit(onSubmit)}
          className="reset-password__form"
          layout="vertical"
        >
          <AkinonPasswordInput
            placeholder={t('new_password')}
            name={FormMetaKey.PASSWORD}
            label={t('new_password')}
          />
          <AkinonPasswordInput
            placeholder={t('new_password_repeat')}
            name={FormMetaKey.CONFIRM}
            label={t('new_password_repeat')}
          />
          <Form.Item wrapperCol={{ span: 24 }}>
            <Space direction="vertical" size="small">
              <AkinonButton
                loading={isResettingPassword}
                htmlType="submit"
                type="primary"
                className="reset-password__form-submit-button"
                disabled={!isPasswordResetTokenValid}
              >
                {t('change.password').toLocaleUpperCase(i18n.language)}
              </AkinonButton>
              <Show when={!isPasswordResetTokenValid}>
                <Text className="reset-password__form-submit-error-message">
                  {t('resetPassword.invalidToken')}
                </Text>
              </Show>
            </Space>
          </Form.Item>
        </AkinonForm>
      </div>
      <footer className="reset-password__footer">
        <img
          className="reset-password__footer-logo"
          src={getImageResourceUrl({
            name: 'akinon-logo',
            extension: ImageExtension.SVG,
          })}
          alt="Akinon Logo"
        />
        <Text className="reset-password__footer-text">
          {t('layout.footer.copyRight', {
            year: new Date().getFullYear(),
          })}
        </Text>
      </footer>
    </section>
  );
};

export default ResetPassword;
