import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AuthorizedComponent from '@components/AuthorizedComponent';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SubmitForm = ({ onSubmit, isSubmittingAllPreOffers, isPostingPreOffer }) => {
  const { t, i18n } = useTranslation('ProductsAndCategories');

  return (
    <Box className="box-primary form-box">
      <Form className="akn-form akn-form-v2 submit-form">
        <Form.Item wrapperCol={{ span: 24 }}>
          <AuthorizedComponent userRole={UserRole.SELLER}>
            <AkinonButton
              className="submit-form__approve-button"
              loading={isPostingPreOffer || isSubmittingAllPreOffers}
              htmlType="submit"
              type="primary"
              onClick={onSubmit}
            >
              {t('product.form.approve').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </AuthorizedComponent>
        </Form.Item>
      </Form>
    </Box>
  );
};

export default SubmitForm;
