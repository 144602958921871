import AkinonButton from '@components/AkinonButton';
import { getDataSourcesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { Tag, Tooltip } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

export const RevisionStatus = {
  DRAFT: 0,
  SUBMIT: {
    SUBMITTED: 1,
  },
  APPROVE: {
    APPROVED: 2,
    PUBLISHING: 4,
    PUBLISHED: 5,
  },
  REJECTED: 3,
};

export const revisionStatusMapping = {
  revision_draft: [RevisionStatus.DRAFT],
  revision_submitted: [RevisionStatus.SUBMIT.SUBMITTED],
  revision_approved: [
    RevisionStatus.APPROVE.APPROVED,
    RevisionStatus.APPROVE.PUBLISHING,
    RevisionStatus.APPROVE.PUBLISHED,
  ],
  revision_rejected: [RevisionStatus.REJECTED],
};

const revisionColors = {
  default: [RevisionStatus.DRAFT],
  blue: [RevisionStatus.SUBMIT.SUBMITTED],
  green: [
    RevisionStatus.APPROVE.APPROVED,
    RevisionStatus.APPROVE.PUBLISHING,
    RevisionStatus.APPROVE.PUBLISHED,
  ],
  red: [RevisionStatus.REJECTED],
};

const getRevisionStatuses = ({ t }) => [
  { value: Object.values(RevisionStatus.SUBMIT).join(','), label: t('revision_submitted') },
  { value: Object.values(RevisionStatus.APPROVE).join(','), label: t('revision_approved') },
  { value: RevisionStatus.REJECTED, label: t('revision_rejected') },
];

const getStatusText = ({ t, status }) => {
  return t(
    Object.keys(revisionStatusMapping).find((key) => revisionStatusMapping[key].includes(status))
  );
};

const getTagColor = ({ status }) => {
  return Object.keys(revisionColors).find((key) => revisionColors[key].includes(status));
};

export const DataIndex = {
  NAME: 'name',
  ID: 'id',
  CURRENT_ATTRIBUTES: 'current_attributes',
  NEW_ATTRIBUTES: 'attributes',
  STATUS: 'status',
  DATASOURCE: 'datasource',
  DATASOURCE_NAME: 'datasource_name',
};

const StaticFilterKey = {
  PRODUCT: 'product__name__icontains',
  STATUS: 'status__in',
  SKU: 'product__sku__iexact',
  BASE_CODE: 'product__base_code__iexact',
  ATTRIBUTES: 'attributes__r_foo__iexact',
  DATASOURCE: 'datasource',
};

export const getStaticFilters = ({ t }) => {
  return {
    fields: [
      {
        name: StaticFilterKey.STATUS,
        placeholder: t('status'),
        widget: WidgetType.SELECT,
        options: getRevisionStatuses({ t }),
      },
      {
        name: StaticFilterKey.PRODUCT,
        placeholder: t('product_name'),
        widget: WidgetType.INPUT,
      },
      {
        name: StaticFilterKey.SKU,
        placeholder: t('sku'),
        widget: WidgetType.INPUT,
      },
      {
        name: StaticFilterKey.BASE_CODE,
        placeholder: t('base.code'),
        widget: WidgetType.INPUT,
      },
      {
        name: StaticFilterKey.DATASOURCE,
        widget: WidgetType.ASYNC_SELECT,
        showSearch: true,
        labelInValue: true,
        queryProps: {
          remoteKey: QueryKey.DATA_SOURCES,
          remoteUrl: getDataSourcesUrl,
          params: {
            limit: 20,
            sort: 'name',
            is_active: true,
          },
          searchKey: 'name__icontains',
        },
        placeholder: t('seller'),
      },
    ],
  };
};

export function getColumns({
  t,
  i18n,
  userRole,
  isMobile,
  setIsApproveModalVisible,
  setIsRejectModalVisible,
  setSelectedRevision,
  setIsRevisionDetailModalVisible,
  setRevisionDetailModalInfo,
  navigate,
}) {
  const isSuperUser = userRole === UserRole.SUPER_USER;
  return [
    {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.PRODUCT],
      title: t('product_name'),
      render: (_newName, record) => {
        if (!isSuperUser) {
          return (
            <AkinonButton
              htmlType="button"
              type="link"
              onClick={() => {
                navigate(RouteUrls.productsAndCategories.products.detail, {
                  productId: record.product,
                  state: {
                    isEditDisabled: true,
                  },
                });
              }}
            >
              {record?.product_detail?.name}
            </AkinonButton>
          );
        }

        return record?.product_detail?.name;
      },
    },
    {
      dataIndex: DataIndex.ID,
      title: t('sku'),
      render: (_, record) => {
        return record?.product_detail?.sku;
      },
    },
    {
      dataIndex: DataIndex.DATASOURCE,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      title: t('seller'),
      render: (_, record) => {
        return record?.[DataIndex.DATASOURCE_NAME];
      },
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      title: t('status'),
      render: (status) => {
        const statusText = getStatusText({ t, status });
        const statusColor = getTagColor({ status });
        return (
          <Tooltip title={statusText}>
            <Tag color={statusColor}>{statusText}</Tag>
          </Tooltip>
        );
      },
    },
    {
      dataIndex: DataIndex.CURRENT_ATTRIBUTES,
      title: t('details'),
      render: (currentAttributes = {}, record) => {
        return (
          <AkinonButton
            onClick={() => {
              setRevisionDetailModalInfo((draft) => {
                draft.hasLongItems = true;
                draft.currentAttributes = cloneDeep(currentAttributes);
                draft.requestedAttributes = cloneDeep(record?.attributes);
                draft.record = cloneDeep(record);
              });
              setIsRevisionDetailModalVisible(true);
            }}
          >
            {t('view_details')}
          </AkinonButton>
        );
      },
    },
    isSuperUser && {
      dataIndex: 'actions',
      title: t('actions'),
      fixed: isMobile ? false : 'right',
      render: (_, record) => {
        if (record?.status === RevisionStatus.SUBMIT.SUBMITTED)
          return (
            <div className="w-full flex gap-1 flex-auto">
              <AkinonButton
                type="primary"
                className="w-1/2"
                onClick={() => {
                  setIsApproveModalVisible(true);
                  setSelectedRevision(record?.id);
                }}
                size="small"
              >
                {t('approve').toLocaleUpperCase(i18n.language)}
              </AkinonButton>
              <AkinonButton
                type="danger"
                className="w-1/2"
                onClick={() => {
                  setIsRejectModalVisible(true);
                  setSelectedRevision(record?.id);
                }}
                size="small"
                aria-label="Reject"
              >
                {t('reject').toLocaleUpperCase(i18n.language)}
              </AkinonButton>
            </div>
          );
      },
    },
  ];
}
