import { getRequestFiltersParams } from '@common/index';
import { openNotification } from '@components/AkinonNotification';
import { QueryKey } from '@constants/query';
import { getExportProductPrices } from '@services/api';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export const useExportProductPricesQuery = ({ requestConfig, filters }) => {
  const { t } = useTranslation('ProductsAndCategories');

  const queryResponse = useQuery({
    queryKey: [QueryKey.EXPORT_PRODUCT_PRICES, requestConfig, requestConfig?.params, filters],
    queryFn: async () => {
      const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
      const filterParams = getRequestFiltersParams(filters);
      delete filterParams?.filter_file;
      requestConfig = {
        ...requestConfig,
        params: {
          ...requestConfig?.params,
          ...filterParams,
        },
      };

      const response = await getExportProductPrices({ requestConfig, fileFilter });
      return response;
    },
    enabled: false,
    onSuccess() {
      openNotification({
        message: t('file.preparing'),
        description: t('file.download.modal.description'),
        t,
      });
    },
  });

  return queryResponse;
};
