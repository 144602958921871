import { createSelectOptions } from '@common/index';
import {
  getApprovalStatusOptions,
  getIsNullBooleanAttributeOptions,
  WidgetType,
} from '@constants/commontypes';

export const StaticFilterKey = {
  DATASOURCE: 'datasource',
  NAME: 'name__icontains',
  SKU: 'sku',
  BASE_CODE: 'base_code',
  CATEGORY: 'category',
  PRODUCT_ISNULL: 'product__isnull',
  APPROVAL_STATUS: 'approval_status',
  STATUS: 'status',
  IMAGE_ISNULL: 'productimage__isnull',
};

export const getStaticFilters = ({
  t,
  dataSources,
  categoryTreeData,
  isDataSourcesLoading,
  isCategoriesLoading,
  isSuperUser,
}) => ({
  fields: [
    isSuperUser && {
      name: StaticFilterKey.DATASOURCE,
      widget: WidgetType.SELECT,
      loading: isDataSourcesLoading,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('seller'),
      options: createSelectOptions(dataSources, {
        labelKey: 'name',
        valueKey: 'id',
      }),
      filterSort: (optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase()),
    },
    {
      name: StaticFilterKey.NAME,
      widget: WidgetType.INPUT,
      placeholder: t('product_name'),
    },
    {
      name: StaticFilterKey.SKU,
      widget: WidgetType.INPUT,
      placeholder: t('sku'),
    },
    {
      name: StaticFilterKey.BASE_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('basecode'),
    },
    {
      name: StaticFilterKey.PRODUCT_ISNULL,
      widget: WidgetType.CHECKBOX_GROUP,
      placeholder: t('offer.product.related'),
      options: [{ label: t('offer.product.related'), value: false }],
    },
    {
      name: StaticFilterKey.CATEGORY,
      widget: WidgetType.TREESELECT,
      placeholder: t('category'),
      showSearch: true,
      treeDefaultExpandAll: true,
      virtual: false,
      style: { width: '100%' },
      loading: isCategoriesLoading,
      dropdownStyle: { maxHeight: 400, overflow: 'auto' },
      treeData: categoryTreeData,
      treeNodeFilterProp: 'title',
      allowClear: true,
    },
    {
      name: StaticFilterKey.APPROVAL_STATUS,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('offer.status'),
      options: getApprovalStatusOptions({ t, isSuperUser }),
    },
    {
      name: StaticFilterKey.IMAGE_ISNULL,
      widget: WidgetType.SELECT,
      showSearch: true,
      optionFilterProp: 'label',
      placeholder: t('product_image'),
      options: getIsNullBooleanAttributeOptions({ t }),
    },
  ].filter(Boolean),
});

export const PreOfferStatus = {
  REJECTED: 0,
  APPROVED: 1,
  PENDING_APPROVAL: 2,
  APPROVAL_REQUIRED: 3,
};

export const OfferStatus = {
  rejected: '0',
  approved: '1',
  'pending-approval': '2,3,4',
};

export const SuperUserOfferStatus = {
  rejected: '0',
  approved: '1',
  'pending-approval': '2,3',
};

export const ApprovalStatus = {
  SUBMITTED: 2,
  WAITING_FOR_APPROVAL: 3,
  SENDING: 4,
  SUBMITTING_FOR_APPROVAL: 5,
  APPROVAL_IN_PROGRESS: 6,
};

export const PendingOffersSuperUserOfferStatuses = [
  ApprovalStatus.SUBMITTED,
  ApprovalStatus.SENDING,
  ApprovalStatus.SUBMITTING_FOR_APPROVAL,
  ApprovalStatus.APPROVAL_IN_PROGRESS,
];
export const PendingOffersSellerOfferStatuses = [
  ApprovalStatus.SUBMITTED,
  ApprovalStatus.WAITING_FOR_APPROVAL,
  ApprovalStatus.SENDING,
  ApprovalStatus.SUBMITTING_FOR_APPROVAL,
  ApprovalStatus.APPROVAL_IN_PROGRESS,
];

export const getApprovalStatus = ({ isSuperUser, filters }) => {
  if (filters?.approval_status__in || filters?.approval_status) {
    return filters.approval_status__in || filters.approval_status;
  } else if (isSuperUser) {
    return PendingOffersSuperUserOfferStatuses.join(',');
  } else {
    return PendingOffersSellerOfferStatuses.join(',');
  }
};

export const PendingOffersColumKey = {
  MSP: 'MSP',
  PRICE: 'PRICE',
  CURRENCY: 'CURRENCY',
  STOCK: 'STOCK',
};

export const PendingOffersDataIndex = {
  MSP: ['prices', '0', 'retail_price'],
  PRICE: ['prices', '0', 'price'],
  CURRENCY: ['prices', '0', 'currency_type'],
  STOCK: ['stocks', '0', 'stock'],
};

export const RejectedOffersColumKey = {
  MSP: 'MSP',
  PRICE: 'PRICE',
  CURRENCY: 'CURRENCY',
  STOCK: 'STOCK',
};

export const RejectedOffersDataIndex = {
  MSP: ['prices', '0', 'retail_price'],
  PRICE: ['prices', '0', 'price'],
  CURRENCY: ['prices', '0', 'currency_type'],
  STOCK: ['stocks', '0', 'stock'],
};
