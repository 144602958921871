import { AppShellProvider } from '@akinon/app-shell';
import { openNotification } from '@components/AkinonNotification';
import Modal from '@components/Modal/Modal';
import useAppNavigate from '@hooks/useAppNavigate';
import { useApplicationsQuery } from '@services/api/hooks/useApplicationsQuery';
import useStore from '@zustand-store/index';
import { Outlet } from 'react-router-dom';

export default function AppShellLayout({ children }) {
  const navigate = useAppNavigate();
  const { apps } = useApplicationsQuery();
  const tokens = useStore((state) => state.tokens);

  return (
    <AppShellProvider
      apps={apps}
      navigation={{
        navigate,
      }}
      actions={{
        showToast: (message, type) => {
          openNotification({
            message: message,
            type,
          });
        },
        showConfirmationDialog: (title, description) => {
          Modal.confirm({
            title,
            content: description,
          });
        },
        showModalDialog: (title, description) => {
          Modal.confirm({
            title,
            content: description,
            okButtonProps: {
              hidden: true,
            },
            cancelButtonProps: {
              hidden: true,
            },
          });
        },
        showErrorMessage: (title, description) => {
          openNotification({
            message: title,
            description,
            type: 'error',
          });
        },
        actions: {
          foo: (message) => {
            openNotification({
              message: message,
              description: 'foo message',
            });
          },
        },
      }}
      data={{ tokens }}
    >
      {children ?? <Outlet />}
    </AppShellProvider>
  );
}
