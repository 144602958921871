import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { useSalesReportsQuery } from '@services/api/hooks/useSalesReportsQuery';
import { Divider } from 'antd';
import { last, pick, uniqBy, values } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import useFilterFields from '../hooks/useFilterFields';
import { getProductsTableColumns } from './common';

export default function ProductBasedTable({ params, reportType }) {
  const { t } = useTranslation('Reporting');
  const [pagination, setPagination] = usePagination();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [dataSourceTree, setDataSourceTree] = useImmer({});
  const base_code = last(expandedRowKeys);
  const filters = usePageFilters();

  const { report: productsReport, isReportFetching: isProductsReportFetching } =
    useSalesReportsQuery({
      params: {
        ...params,
        type: base_code ? 'sku' : 'base-code',
        base_code,
      },
      filters,
    });
  const productsReportDataSource = productsReport?.results ?? [];

  const filterFields = useFilterFields({
    t,
    showSkuFilter: /* Boolean(base_code) */ false,
    showBaseCodeFilter: !Boolean(base_code),
  });

  useEffect(() => {
    if (!base_code) {
      setDataSourceTree((draft) => {
        draft['root'] = productsReportDataSource;
      });
    }

    setDataSourceTree((draft) => {
      productsReportDataSource?.forEach((dsRow) => {
        const oldRow = draft[dsRow.base_code] ?? [];
        draft[dsRow.base_code] = uniqBy([...oldRow, dsRow], 'sku');
      });
    });
  }, [productsReport]);

  const total = dataSourceTree?.root?.length || 0;
  const columns = getProductsTableColumns({ t, expandedRowKeys, setExpandedRowKeys, reportType });

  useEffect(() => {
    return () => {
      setExpandedRowKeys([]);
    };
  }, []);

  const getExpandable = () => ({
    expandedRowKeys,
    showExpandColumn: false,
    expandedRowClassName: () => 'expanded-row',
    expandedRowRender: (record) => {
      const subDataSourceKeys = Object.keys(dataSourceTree).filter((key) => {
        return key === record?.base_code;
      });
      const subDataSource = values(pick(dataSourceTree, subDataSourceKeys))
        .flat()
        .filter((row) => row?.sku);
      const subcolumns = getProductsTableColumns({
        t,
        expandedRowKeys,
        setExpandedRowKeys,
        key: 'sku',
        isExpendable: false,
        title: 'sku',
        reportType,
      });

      if (!subDataSource?.length && !isProductsReportFetching) return t('data.not.found');
      return (
        <AkinonTable
          style={{ marginLeft: 20, marginTop: 0 }}
          rowKey="sku"
          className="expanded-table"
          dataSource={subDataSource}
          columns={subcolumns}
          pagination={false}
        />
      );
    },
  });

  return (
    <div>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('product_based')}
        columns={columns}
        dataSource={dataSourceTree?.root}
        pagination={pagination}
        setPagination={setPagination}
        loading={isProductsReportFetching}
        rowKey="base_code"
        total={total}
        description={`${total} ${t('results.found')}`}
        expandable={getExpandable()}
      />
    </div>
  );
}
