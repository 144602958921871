import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import { zodResolver } from '@hookform/resolvers/zod';
import { TranslationKey } from '@root/i18n';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { Modal, Typography } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const { Text } = Typography;

const FormKey = {
  CATEGORY: 'category_id',
};

const getSchema = () => {
  return z.object({
    [FormKey.CATEGORY]: z.string(),
  });
};

const BulkSetCategoryModal = ({ onConfirm, isVisible, onCancel, isLoading, selectedRowKeys }) => {
  const { t } = useTranslation(TranslationKey.PRODUCTS_AND_CATEGORIES);

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(getSchema()),
  });
  const { control, reset, handleSubmit } = form;

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'attribute_set',
      useItemId: true,
    },
  });

  const onSubmit = (formValues) => {
    onConfirm(formValues);
    onCancel();
    reset({ category: '' });
  };

  const hasSelectedKeys = selectedRowKeys?.length > 0;

  return (
    <Modal
      width={800}
      open={isVisible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      footer={null}
      destroyOnClose
      onCancel={() => {
        onCancel();
      }}
      title={t('bulk_change_category')}
    >
      <AkinonForm className="flex flex-col gap-2">
        <Text className="text-white mb-3">
          {t(`are_you_sure_to_bulk_change_category${hasSelectedKeys ? '_selected' : ''}`)}
        </Text>
        <Text className="text-white mb-3">{t('bulk_change_category_desc')}</Text>
        <AkinonTreeSelect
          formItemProps={{
            name: FormKey.CATEGORY,
            label: t('category'),
            control,
          }}
          loading={isCategoriesLoading}
          treeData={categoryTreeData}
          treeNodeFilterProp={'title'}
          allowClear
          showSearch
          treeDefaultExpandAll
          virtual={false}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
        <div className="flex gap-2 justify-end">
          <AkinonButton type="danger" onClick={onCancel}>
            {t('cancel')}
          </AkinonButton>
          <AkinonButton loading={isLoading} onClick={handleSubmit(onSubmit)}>
            {t('save')}
          </AkinonButton>
        </div>
      </AkinonForm>
    </Modal>
  );
};

export default BulkSetCategoryModal;
