import './styles.scss';

import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getIntegrationErrorsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useResolveIntegrationErrorMutation } from '@services/api/hooks/useResolveIntegrationErrorMutation';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

export default function IntegrationErrorsList() {
  const { t, i18n } = useTranslation('Settings');
  const isMobile = useIsMobile();
  const [pagination, setPagination] = usePagination();
  const filterFields = useFilterFields({ t });

  const filters = useFilterParams({ filterFields });

  const { dataSource, total, loading } = useDataSource({ pagination, filters });
  const { resolveIntegrationError } = useResolveIntegrationErrorMutation();

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t, i18n, resolveIntegrationError, isMobile });

  return (
    <section className="integration-errors">
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        dataSource={dataSource}
        optionsUrl={getIntegrationErrorsUrl}
        loading={loading}
        total={total}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        rowKey={defaultRowKey}
        description={t('n_results_found', { count: total })}
        title={t('integration_errors')}
      />
    </section>
  );
}
