import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import { IconMailFast, IconMessageUser } from '@tabler/icons-react';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.messages.productMessages,
        title: t('product.messages'),
        description: t('product.messages.desc'),
        icon: <i className="icon-siparis" />,
      },
      {
        to: RouteUrls.messages.orderMessages,
        title: t('order.messages'),
        description: t('order.messages.desc'),
        icon: <IconMessageUser size={32} />,
      },
      {
        to: RouteUrls.messages.quickReplies,
        title: t('quick.replies'),
        description: t('quick.replies.desc'),
        icon: <IconMailFast size={48} />,
      },
    ],
    userRole,
    isProd,
  });
};
