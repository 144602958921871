import AkinonSpin from '@components/AkinonSpin';
import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';
import { generatePath } from 'react-router-dom';

const OtherPackagesInfo = ({ t, packages, packageDetail, isPackageInfoLoading }) => {
  const otherPackages =
    (packages && packages?.filter((item) => item?.id !== packageDetail?.id)) || [];
  return (
    <AkinonSpin spinning={isPackageInfoLoading}>
      <div className="detail-item">
        <div className="detail-item--title">{t('package_number')}</div>
        {otherPackages?.map((item, index) => {
          return (
            <div key={index} className="detail-item">
              <Link
                to={generatePath(RouteUrls.order.packages.packageDetail, { packageId: item?.id })}
              >
                {item?.number}
              </Link>
            </div>
          );
        })}
      </div>
    </AkinonSpin>
  );
};

export default OtherPackagesInfo;
