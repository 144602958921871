import { getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { useStore } from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ user, promotionName }) {
  const { t } = useTranslation('Marketing');
  const setPageMeta = useStore(setPageMetaSelector);
  const userRole = getUserRole({ user });
  const isSeller = userRole === UserRole.SELLER;

  useEffect(() => {
    setPageMeta({
      title: promotionName,
      description: isSeller ? t('campaign_detail_seller_desc') : t('campaign_detail_desc'),
    });
  }, [t, promotionName]);

  useUnmount(() => {
    setPageMeta({});
  });
}
