import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.order.packages.packageList,
        title: t('package_list'),
        description: t('package_list_desc'),
        icon: <i className="icon-packages-list" />,
      },
      {
        to: RouteUrls.order.packages.packagesWaitingForCargo,
        title: t('packages_waiting_for_shipment'),
        description: t('packages_waiting_for_shipment_desc'),
        icon: <i className="icon-packages-waiting" />,
      },
      {
        to: RouteUrls.order.packages.packagesWaitingForDelivery,
        title: t('packages_waiting_for_delivery'),
        description: t('packages_waiting_for_delivery_desc'),
        icon: <i className="icon-packages-pending" />,
      },
    ],
    userRole,
    isProd,
  });
};
