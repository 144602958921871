import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import AkinonSpin from '@components/AkinonSpin';
import { AkinonQuillEditor } from '@components/QuillEditor';
import Box from '@components/utility/box';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { queryClient } from '@services/api/queryClient';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormKey, schema } from './common';
import { useCreateOrUpdateQuickReplyMutation } from './hooks/api/useCreateOrUpdateQuickReplyMutation';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';
import { useQuickReplyData } from './hooks/useQuickReplyData';

const QuickReply = () => {
  const { t } = useTranslation('Messages');
  const navigate = useAppNavigate();
  const { id } = useParams();

  const { quickReply, isFetchingQuickReply } = useQuickReplyData({ quickReplyId: id });
  const { createOrUpdateQuickReply, isCreatingOrUpdatingQuickReply } =
    useCreateOrUpdateQuickReplyMutation({
      isUpdate: !!quickReply,
      mutationOptions: {
        onSuccess: (data) => {
          queryClient.invalidateQueries([QueryKey.QUICK_REPLY, id]);
          reset(data);
          navigate(RouteUrls.messages.quickReplies);
        },
      },
    });

  useBreadcrumbs();
  usePageMeta({ quickReply });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {},
    resolver: zodResolver(schema),
  });

  const onSubmit = (formValues) =>
    createOrUpdateQuickReply({
      quickReplyId: id,
      requestBody: formValues,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('quick_reply_form_message.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('quick_reply_form_message.error'),
      },
    });

  useEffect(() => {
    if (quickReply) {
      reset(quickReply);
    }
  }, [quickReply]);

  return (
    <section>
      <AkinonSpin spinning={Boolean(id && isFetchingQuickReply)}>
        <Box
          className="box-primary form-box answer-box"
          title={t(quickReply ? 'edit.quick.reply' : 'new.quick.reply')}
        >
          <AkinonForm className="akn-form akn-form-v2" layout="vertical">
            <AkinonInput
              formItemProps={{
                label: t('title'),
                name: FormKey.TITLE,
                control,
                required: true,
              }}
              placeholder={t('write.title')}
            />
            <AkinonQuillEditor
              formItemProps={{
                label: t('content'),
                name: FormKey.CONTENT,
                control,
                required: true,
              }}
              maxLength={512}
            />
            <div>
              <AkinonButton
                onClick={handleSubmit(onSubmit)}
                htmlType="submit"
                type="primary"
                className="float-left"
                loading={isCreatingOrUpdatingQuickReply}
              >
                {t(quickReply ? 'save' : 'add')}
              </AkinonButton>
            </div>
          </AkinonForm>
        </Box>
      </AkinonSpin>
    </section>
  );
};

export default QuickReply;
