import AkinonButton from '@components/AkinonButton';
import AkinonCheckbox from '@components/AkinonCheckbox';
import AkinonInput from '@components/AkinonInput';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { variantsFormDynamicMetaFieldsSelector } from '@zustand-store/selectors/preOffer';
import { Divider, Form, Modal, Space, Typography } from 'antd';
import { last, merge, omit, pick, range } from 'lodash';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ProductsToBeAddedTableKey } from '../../common';

const { Text } = Typography;

const BulkUpdateStocksModal = ({
  form: productSummaryForm,
  isBulkUpdateStockModalVisible,
  setIsBulkUpdateStockModalVisible,
  dataSource,
}) => {
  const form = useForm({
    defaultValues: {},
  });
  const { handleSubmit, reset, control } = form;
  const { t, i18n } = useTranslation(TranslationKey.PRODUCTS_AND_CATEGORIES);
  const [selectedRows, setSelectedRows] = useState(range(dataSource.length).map(() => false));
  const variantsKeys = useStore(variantsFormDynamicMetaFieldsSelector).map((metaField) =>
    last(metaField.key.split('.'))
  );
  const variantsPairs = dataSource.map((dataSourceItem) =>
    Object.values(pick(dataSourceItem, variantsKeys))
  );

  const onClose = () => {
    reset();
    setIsBulkUpdateStockModalVisible(false);
  };

  const onSubmit = (values) => {
    const newValues = merge(productSummaryForm.getValues(), omit(values, 'root'));
    productSummaryForm.reset(newValues);
    onClose();
  };
  const handleApplyToAll = () => {
    const rootValues = form.getValues().root;
    dataSource.forEach((product) => {
      form.setValue(product.id, rootValues);
    });

    setSelectedRows(range(dataSource.length).map(() => true));
  };
  const handleApplyToSelectedOnes = () => {
    const rootValues = form.getValues().root;

    selectedRows.forEach((isSelected, index) => {
      if (!isSelected) return;

      const product = dataSource[index];
      form.setValue(product.id, rootValues);
    });
  };
  const handleCleanAllContent = () => {
    dataSource.forEach((product) => {
      form.setValue(product.id, { [ProductsToBeAddedTableKey.STOCK]: null });
    });
  };
  return (
    <Modal
      className="form-modal"
      onCancel={onClose}
      open={isBulkUpdateStockModalVisible}
      width={'75%'}
      destroyOnClose
      footer={null}
    >
      <Box className="box-primary form-box">
        <Form className="akn-form" layout="vertical" wrapperCol={verticalFormItemLayout.wrapperCol}>
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{t('bulk.update.stock')}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">{t('bulk.update.stock.description')}</div>
          <Divider orientation="left" className="form-modal__divider">
            {t('update.stock.amount').toLocaleUpperCase(i18n.language)}
          </Divider>
          <div style={{ maxWidth: 210 }}>
            <AkinonInput
              formItemProps={{
                control,
                name: ['root', ProductsToBeAddedTableKey.STOCK].join('.'),
                label: t('total.product.stocks'),
                className: 'w-[256px] ml-[323px]',
              }}
              placeholder={t('total.product.stocks')}
            />
          </div>
          <Space direction="horizontal" className="form-modal__form-actions">
            <AkinonButton onClick={handleApplyToAll} htmlType="button" type="primary">
              {t('apply.to.all').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
            <AkinonButton onClick={handleApplyToSelectedOnes} htmlType="button" type="primary">
              {t('apply.to.selected.ones').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </Space>
          {dataSource.map((product, index) => {
            const handleCheckboxChange = (e) => {
              const newselectedRows = [...selectedRows];
              newselectedRows[index] = e.target.checked;
              setSelectedRows(newselectedRows);
            };

            return (
              <div className="form-modal-item" key={product?.id}>
                <div className="form-modal-item__wrapper">
                  <AkinonCheckbox
                    checked={selectedRows[index]}
                    className="form-modal-item__checkbox"
                    onChange={handleCheckboxChange}
                  />
                  <Text className="form-modal-item__variant singular-form-modal-item__variant">
                    {variantsPairs[index].join('/')}
                  </Text>
                  <AkinonInput
                    formItemProps={{
                      control,
                      name: [product.id, ProductsToBeAddedTableKey.STOCK].join('.'),
                      className: 'w-[256px]',
                    }}
                    placeholder={t('total.product.stocks')}
                  />
                </div>
                <Divider className="form-modal-item__divider" />
              </div>
            );
          })}
          <Space>
            <AkinonButton type="primary" htmlType="submit" onClick={handleSubmit(onSubmit)}>
              {t('save').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </Space>
        </Form>
      </Box>
    </Modal>
  );
};

export default BulkUpdateStocksModal;
