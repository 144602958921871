import AkinonButton from '@components/AkinonButton';
import CopyButton from '@components/CopyButton';
import { dateTimeFormat } from '@constants/index';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

const DataIndex = {
  KEY: 'key',
  VALID_UNTIL: 'valid_until',
  NOTE: 'note',
  STATUS: 'status',
  REVOKE: 'revoke',
};

export const getColumns = ({ t, i18n, handleOpenRevokeModal }) => [
  {
    dataIndex: DataIndex.KEY,
    title: t('key'),
    render: (key) => {
      const maskedKey = key.substring(0, 3) + '***********';
      return <CopyButton tooltipVisible={true} text={key} label={maskedKey} isColumn />;
    },
  },
  {
    dataIndex: DataIndex.VALID_UNTIL,
    title: t('validUntil'),
    render: (date) => {
      return moment(date).format(dateTimeFormat);
    },
  },
  {
    dataIndex: DataIndex.NOTE,
    title: t('note'),
  },
  {
    dataIndex: DataIndex.STATUS,
    title: t('status'),
    render: (status) => {
      return (
        <div
          className={clsx('access-token-status', {
            'access-token-approved': status,
            'access-token-rejected': !status,
          })}
        >
          {status ? t('active') : t('passive')}
        </div>
      );
    },
  },
  {
    dataIndex: 'revoke',
    title: t('revoke'),
    render: (_, record) => {
      return (
        <AkinonButton
          type="primary"
          onClick={() => {
            handleOpenRevokeModal({ accessTokenId: record?.id });
          }}
          size="small"
          disabled={!record?.status}
        >
          {t('revoke').toLocaleUpperCase()}
        </AkinonButton>
      );
    },
  },
];
