import { getDataSourcesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useStaticFilterFields = ({ userRole }) => {
  const { t } = useTranslation('Finance');
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return useMemo(
    () => ({
      fields: [
        isSuperUser && {
          name: StaticFilterKey.DATASOURCE,
          widget: WidgetType.ASYNC_SELECT,
          showSearch: true,
          labelInValue: true,
          queryProps: {
            remoteKey: QueryKey.DATA_SOURCES,
            remoteUrl: getDataSourcesUrl,
            params: {
              limit: 20,
              sort: 'name',
              is_active: true,
            },
            searchKey: 'name__icontains',
          },
          placeholder: t('seller'),
        },
        {
          name: StaticFilterKey.ORDER_NUMBER,
          placeholder: t('order_number_1'),
        },
      ].filter(Boolean),
    }),
    [t, isSuperUser]
  );
};
