// Products.
export const getProductsUrl = 'products/';
export const postFetchProductsUrl = 'products/fetch/';
export const getProductLowestPriceUrl = 'products/:productId/lowest-price/';
export const getProductUrl = 'products/:productId/';
export const getProductImagesUrl = 'product-images/';
export const getProductStockListUrl = 'product-stocks/';
export const patchProduckStockUrl = 'product-stocks/:stockId/';
export const getProductRevisionsUrl = 'product-revisions/';
export const getProductImageRevisionsUrl = 'product-image-revisions/';
export const postSubmitProductRevisionUrl = 'product-revisions/:productRevisionId/submit/';
export const getProductRevisionByProductIdUrl = 'product-revisions/:productId/';
export const postProductRevisionUrl = 'product-revisions/';
export const postProductImageRevisionUrl = 'product-image-revisions/';
export const patchProductRevisionUrl = 'product-revisions/:productRevisionId/';
export const postRejectProductRevisionUrl = 'product-revisions/:productRevisionId/reject/';
export const postApproveProductRevisionUrl = 'product-revisions/:productRevisionId/approve/';
export const postRecallProductRevisionUrl = 'product-revisions/:productRevisionId/recall/';
export const setBulkCategoryUrl = 'products/bulk/set-category/';
export const getProductStateStatusUrl = 'products/bulk-state/:cacheKey/';

// Dashboard.
export const getDashboardUrl = 'dashboard/';

// Bulk actions
export const downloadBulkProductOfferCSVTemplateURL = 'pre-offers/import-template/';
export const downloadBulkProductOfferXLSXTemplateURL = 'pre-offers/import-template/export/';
export const downloadReconciliationRuleTemplateURL = 'reconciliation-rules/import-template/export/';
export const postPreOfferFileUploadURL = 'pre-offers/import/';
export const postProductOfferFileUploadURL = 'product-offers/import/';
export const getUploadStatusUrl = 'imports/:key/';
export const getUploadStatusProgressUrl = 'imports/:key/progress/';
export const getBulkStatusChunksURL = 'imports/:key/chunks/';
export const downloadBulkTemplateAttributeUrl = 'attribute-values/value-template/export/';
export const downloadBulkAttributeXLSXTemplateURL = 'attribute-values/value-template/export/';

export const downloadBulkProductPricesTemplateUrl = 'product-prices/import-template/';
export const postBulkProductPricesUrl = 'product-prices/import/';
export const getBulkStatusProductPricesUrl = 'product-prices/import/:key/';

export const downloadBulkPackagesTemplateUrl = 'packages/import-template/';

export const downloadBulkProductStocksTemplateUrl = 'product-stocks/import-template/';
export const postBulkProductStocksUrl = 'product-stocks/import/';
export const getBulkStatusProductStocksUrl = 'product-stocks/import/:key/';

export const postBulkPackageListUrl = 'packages/import/';

// Prices.
export const getPriceListsUrl = 'price-lists/';
export const getProductPricesUrl = 'product-prices/';
export const patchProductPriceUrl = 'product-prices/:priceId/';

// Pre-offers.
export const preOffersUrl = 'pre-offers/';
export const getPreOfferUrl = 'pre-offers/:preOfferId/';
export const createProductUrl = 'pre-offers/:preOfferId/create-product/';
export const submitAllPreOffersUrl = 'pre-offers/submit-all/';
export const submitPreOfferUrl = 'pre-offers/:preOfferId/submit/';
export const withdrawPreOfferUrl = 'pre-offers/:preOfferId/recall/';
export const rejectPreOfferUrl = 'pre-offers/:preOfferId/reject/';
export const approvePreOfferUrl = 'pre-offers/:preOfferId/approve/';
export const postPreOffersUrl = 'pre-offers/';
export const patchPreOffersUrl = 'pre-offers/:preOfferId/';
export const preOfferImagesUrl = 'pre-offer-images/';
export const getPreOfferSubmitUrl = (preOfferId) => `pre-offers/${preOfferId}/submit/`;
export const getPreOfferApproveUrl = (preOfferId) => `pre-offers/${preOfferId}/approve/`;
export const deletePreOfferImageUrl = 'pre-offer-images/:imageId/';
export const patchPreOfferImageUrl = 'pre-offer-images/:imageId/';
export const exportPreOffersUrl = 'pre-offers/export/';

// Offers.
export const getProductOffersUrl = 'product-offers/';
export const getProductOfferUrl = 'product-offers/:offerId/';
export const deleteProductOffersUrl = 'product-offers/:offerId/';
export const patchProductOffersUrl = 'product-offers/:offerId/';
export const postPreOfferBulkApproveUrl = 'pre-offers/bulk-approve/';
export const postPreOfferBulkRejectUrl = 'pre-offers/bulk-reject/';
export const postResolveAllPreOffersUrl = 'pre-offers/bulk/resolve/';
export const exportOffersUrl = 'product-offers/export/';
export const postBulkStatusUpdateUrl = 'product-offers/bulk-status-update/';
export const getRestrictedProductsUrl = 'data-source-product-restrictions/';
export const postRestrictedProductsUrl = 'data-source-product-restrictions/';
export const deleteRestrictedProductsUrl = 'data-source-product-restrictions/:restrictionId/';

// Product misc.
export const getAttributeConfigsUrl = 'attribute-configs/';
export const getAttributesUrl = 'attributes/';
export const getAttributeUrl = 'attributes/:attributeId/';
export const getAttributeOptionsUrl = 'attribute-values/';
export const getAttributeInputOptionsUrl = (attributeId) =>
  `attribute-values/?attribute=${attributeId}&limit=99999`;
export const attributeSetUrl = `attribute-sets/:attributeSetId/`;
export const getCategoryNodesUrl = 'category-nodes/';
export const getCategoryNodePermissionsUrl = 'data-source-category-node-permissions/';
export const deleteCategoryNodePermissionsUrl =
  'data-source-category-node-permissions/:categoryNodeId/';
export const getStockListsUrl = 'stock-lists/';
export const exportProductStocksUrl = 'product-stocks/export/';
export const exportProductPricesUrl = 'product-prices/export/';
export const getMasterProductPricesUrl = 'master-product-prices/';

// Datasources.
export const getDataSourcesUrl = 'data-sources/';
export const postDataSourcesUrl = 'data-sources/';
export const getDataSourceDetailUrl = 'data-sources/:dataSourceId/';
export const patchSupplierDetailUrl = 'data-sources/:dataSourceId/update-supplier-detail/';
export const getShippingCompanyOptionsUrl = 'shipping-company-options/';
export const getShippingCompanyOptionUrl = 'shipping-company-options/:optionId/';
export const getDataSourceShippingCompanyOptionConfigsUrl =
  'data-source-shipping-company-option-configs/';
export const getDataSourceShippingCompanyOptionConfigUrl =
  'data-source-shipping-company-option-configs/:configId/';
export const getDataSourcePermissionsUrl = 'data-sources/:dataSourceId/users/';
export const postDataSourceCreateUserUrl = 'data-sources/:dataSourceId/users/';
export const postDataSourceUpdateUserUrl = 'data-sources/:dataSourceId/users/:userId/';

// Rejections.
export const getRejectionsUrl = 'rejections/';
export const postResolveRejectionUrl = 'rejections/:rejectionReasonId/resolve/';

// Customer relation misc.
export const getRejectionReasonsUrl = 'rejection-reasons/';
export const getRejectionReasonUrl = `rejection-reasons/:rejectionReasonId/`;
export const postRejectionReasonUrl = 'rejection-reasons/';
export const patchRejectionReasonUrl = 'rejection-reasons/:rejectionReasonId/';
export const getConversationsUrl = 'remote/conversations/';
export const getConversationUrl = 'remote/conversations/:conversationId/';
export const createConversationPrivateStatusUrl =
  'remote/conversations/:conversationId/update-private-status/';
export const getQuickRepliesUrl = 'quick-replies/';
export const getQuickReplyUrl = 'quick-replies/:quickReplyId/';
export const deleteQuickReplyUrl = 'quick-replies/:quickReplyId/';
export const createMessageUrl = 'remote/messages/';

//Shipping.
export const postShippingCompanyOptionsUrl = 'shipping-company-options/';
export const getShipmentCompanyOptionsUrl = 'shipping-company-options/';
export const getShipmentCompanyOptionsByIdUrl = 'shipping-company-options/:shippingMethodId/';
export const getShippingOptionsUrl = 'shipping-options/';
export const getShippingOptionsByIdUrl = 'shipping-options/:shippingOptionId/';
export const getShippingCompaniesByIdUrl = 'shipping-companies/:shippingCompanyId/';
export const getShippingCompaniesUrl = 'shipping-companies/';

//Packages.
export const patchPackageUrl = 'packages/:packageId/';
export const getPackagesIdUrl = 'packages/:packageId/';
export const getPackagesUrl = 'packages/';
export const getPackageItemsUrl = 'package-items/';
export const listPackagesUrl = 'packages/';
export const getPackageCargoLabelUrl = 'packages/:packageId/generate-label/';
export const postPackageGenerateShipmentUrl = 'packages/:packageId/generate-shipment/';
export const exportPackagesUrl = 'packages/export/';

export const getBillingAddressUrl = 'customer-addresses/:billingAddressId/';
export const getBasketOfferUrl = 'basket-offers/';
export const getBasketOfferDetailUrl = 'basket-offers/:id/';
export const postBasketOfferParticipantsUrl = 'basket-offer-participants/';
export const getBasketOfferParticipantsUrl = 'basket-offer-participants/';
export const packagesRecreateURL = 'packages/re-create/';
export const getCancellationPlansUrl = 'cancellation-plans/';
export const getCancellationPlanOrderItemsUrl = 'cancellation-plan-order-items/';
export const approveCancellationPlansUrl = 'cancellation-plans/:cancellationPlanId/approve/';
export const rejectCancellationPlansUrl = 'cancellation-plans/:cancellationPlanId/reject/';
export const postCancellationRequestImagesUrl = 'cancellation-request-images/';

// Orders.
export const getOrdersUrl = 'orders/';
export const getOrderDetailUrl = 'orders/:orderId';
export const postRejectOrderItemsUrl = 'orders/:orderId/reject/';
export const getOrderItemsUrl = 'order-items/';
export const rejectOrderItemUrl = 'order-items/:orderItemId/reject/';
export const getCancellationRequestsUrl = 'cancellation-requests/';
export const approveCancellationRequestUrl = 'cancellation-requests/:requestId/approve/';
export const rejectCancellationRequestUrl = 'cancellation-requests/:requestId/reject/';
export const approveBulkRefundUrl = 'cancellation-requests/bulk-approve/';
export const exportOrdersUrl = 'orders/export/';

//Reconciliations.
export const getReconciliationsURL = 'reconciliations/';
export const getReconciliationsRebateRulesUrl = 'reconciliation-rebate-rules/';
export const postReconciliationsRebateRulesUrl = 'reconciliation-rebate-rules/';
export const deleteReconciliationsRebateRuleUrl = 'reconciliation-rebate-rules/:ruleId/';
export const getReconciliationDetailURL = 'reconciliations/:reconciliationId/';
export const getReconciliationItemDetailURL = 'reconciliations/:reconciliationId/items/';
export const postReconciliationStatusURL = 'reconciliations/:reconciliationId/change_status/';
export const postReconciliationRules = 'reconciliation-rules/';
export const deleteReconciliationRules = 'reconciliation-rules/:ruleId/';
export const getReconciliationRules = 'reconciliation-rules/';
export const postBulkReconciliationRulesUrl = 'reconciliation-rules/import/';

// Users.
export const getUsersUrl = 'users/';
export const getUserDetailURL = 'users/:userId/';
export const postUsersUrl = 'users/';
export const getActiveUserUrl = 'active-user/';

// Downloads.
export const getExportUrl = 'exports/:cacheKey/';

// Common
export const getLanguagesUrl = 'languages/';
export const getMeUrl = 'users/me/';
export const patchMeUrl = 'users/me/';

// Auth
export const oAuthProvidersUrl = 'oauth/';
export const oAuthProviderAuthorizeUrl = 'oauth/:providerSlug/authorize/';
export const oAuthProviderAuthenticateUrl = 'oauth/:providerSlug/authenticate/';
export const oAuthProviderBySlugUrl = 'oauth/:slug/';
export const postTokenObtainUrl = 'token/obtain/';
export const postLoginAsUrl = 'users/:userId/login-as/';
export const postBlacklistTokenUrl = 'token/blacklist/';
export const postRefreshTokenUrl = 'token/refresh/';

// Cancelattion.
export const getCancellationReasonsUrl = 'cancellation-reasons/';
export const patchCancellationReasonUrl = 'cancellation-reasons/:cancellationReasonId/';
export const getCancelReasonUrl = 'cancellation-reasons/:cancellationReasonId/';

// Customer.
export const customerAddressesUrl = 'customer-addresses/:addressId/';
export const getCustomerAddressesUrl = 'customer-addresses/';

export const attachFileWithDataSourceIdUrl = 'data-sources/:dataSourceId/attach/';
export const getAttributeSetsUrl = 'attribute-sets/';
export const updateCategoryAttributeSetUrl = 'category-nodes/:categoryId/attribute-set/';

// Widgets
export const getWidgetSchemaUrl = 'remote/widget-types/:schemaId';
export const widgetsUrl = 'remote/widgets/';
export const patchWidgetUrl = 'remote/widgets/:widgetId/';

// Common
export const getCollectionsUrl = 'collections/';
export const postProductCollectionWidgetUrl = 'remote/product-collection-widgets/';
export const getProductCollectionWidgetsUrl = 'remote/product-collection-widgets/';
export const dataSourceLandingPagesUrl = 'data-source-landing-pages/';
export const dataSourceLandingPageUrl = 'data-source-landing-pages/:dataSourceLandingPageId/';
export const approveDataSourceLandingPageUrl =
  'data-source-landing-pages/:dataSourceLandingPageId/approve/';
export const rejectDataSourceLandingPageUrl =
  'data-source-landing-pages/:dataSourceLandingPageId/reject/';
export const postDataSourceLandingPageUrl = 'data-source-landing-pages/';

// Settings
export const getIntegrationAccessTokensUrl = 'integration-access-tokens/';
export const deleteIntegrationAccessTokensUrl = 'integration-access-tokens/:accessTokenId/';

export const resetPasswordUrl = 'reset-password/';
export const changePasswordUrl = 'users/me/change-password/';
export const resetPasswordWithTokenUrl = 'reset-password/:resetToken/';
export const generatePackageLabelUrl = 'packages/:packageId/generate-label/';
export const getCountriesUrl = 'countries/';
export const getCitiesUrl = 'cities/';
export const getDistrictsUrl = 'districts/';
export const getTownshipsUrl = 'townships/';
export const getCountryUrl = 'countries/:countryId/';
export const getCityUrl = 'cities/:cityId/';
export const getTownshipUrl = 'townships/:townshipId/';
export const getDistrictUrl = 'districts/:districtId/';
export const postTicketUrl = 'tickets/';
export const postTicketMessageUrl = 'tickets/:ticketId/messages/';
export const getTicketsUrl = 'tickets/';
export const getUserUrl = 'users/:userId/';
export const getTicketUrl = 'tickets/:ticketId/';
export const getTicketMessagesUrl = 'tickets/:ticketId/messages/';
export const patchTicketUrl = 'tickets/:ticketId/';
export const postWidgetUrl = 'remote/widgets/';
export const patchProductCollectionWidgetUrl = 'remote/product-collection-widgets/:widgetId/';
export const postPreOfferImageUrl = 'pre-offer-images/';
export const patchShippingCompanyOptionUrl = 'shipping-company-options/:optionId/';
export const postDataSourceShippingCompanyOptionConfigUrl =
  'data-source-shipping-company-option-configs/';
export const patchDataSourceShippingCompanyOptionConfigUrl =
  'data-source-shipping-company-option-configs/:configId/';
export const patchProductStockUrl = 'product-stocks/:stockId/';
export const getIntegrationErrorsUrl = 'integration-errors/';
export const getContentTypeUrl = 'content-types/:contentTypeId/';
export const resolveIntegrationErrorUrl = 'integration-errors/:integrationErrorId/resolve/';
export const getContentTypesUrl = 'content-types/';
export const exportOrderItemsUrl = 'order-items/export/';
export const exportPackageItemsUrl = 'package-items/export/';
export const getUnifiedDataSourcesUrl = 'data-sources/';
export const submitPreOfferURL = 'pre-offers/:id/submit/';
export const approvePreOfferURL = 'pre-offers/:id/approve/';
export const rejectPreOfferURL = 'pre-offers/:id/reject/';
export const salesReportingUrl = 'reporting/sales/';
export const orderReportingUrl = 'reporting/order/';
export const cacheKeydownloadURL = ':cacheKey/download/';
export const dynamicSettingsDetailURL = 'dynamic_settings/:dynamicSettingId/';
export const dynamicSettingsRegistryURL = 'dynamic_settings/registry/ ';
export const dynamicSettingsURL = 'dynamic_settings/';
export const postCollectionsURL = 'collections/';
export const priceListURL = 'price_list/';
export const getStockListURL = 'stock-lists/';
export const putCollectionsURL = 'collections/:collectionId/';
export const getDataSourceShipmentCompanyOptionsUrl =
  'data-source-shipping-company-option-configs/';
export const postProductCollectionURL = 'collection-items/';
export const postAddAllProductsURL = 'product-offers/bulk/create_collection_item/';
export const deleteAddAllProductsURL = 'product-offers/bulk/remove_collection_item/';
export const getAddAllProductsStatusURL = 'product-offers/bulk-state/:cacheKey/';
export const getResolveAllPreOffersStatusURL = 'pre-offers/bulk-state/:cacheKey/';
export const getCollectionsByIdURL = 'collections/:collectionId/';
export const getProductCollectionDetailURL = 'collection-items/';
export const getDeleteProductCollectionURL = 'collection-items/:id/';
export const getActiveSkuUrl = 'dashboard/active_sku/';
export const getApplicationsUrl = 'remote/applications/';
export const getTotalStockUrl = 'dashboard/total_stock/';
export const getTotalAmountUrl = 'dashboard/total_amount/';
export const getTotalItemsUrl = 'dashboard/total_items/';
export const getFulfillmentTimeUrl = 'dashboard/fulfillment_time/';
export const getGradeUrl = 'dashboard/grade/';
export const getCancelRatioUrl = 'dashboard/cancel_ratio/';
export const getOrderCountByDateUrl = 'dashboard/order_count_by_date/';
export const getMostSellingProductsUrl = 'dashboard/most_selling_products/';
export const getMostSellingCategoriesUrl = 'dashboard/most_selling_categories/';
export const getCancellationRefundCountUrl = 'dashboard/cancellation_refund_count/';
export const getActionRequiredPackagesUrl = 'dashboard/action_required_packages/';
export const getExportsUrl = 'exports/';
export const getImportsUrl = 'imports/';
export const getImportDetailUrl = 'imports/:key/';

// Notifications
export const getNotificationsUrl = 'notifications-management/';
export const postNotificationUrl = 'notifications-management/';
export const getNotificationDetailUrl = 'notifications-management/:id/';
export const postNotificationAudienceUrl = 'notifications-management/:id/audiences/add/';
export const postNotificationAttachmentUrl = 'notifications-management/:id/attachments/add/';
export const getAnnouncementsUrl = 'notifications/';
export const postNotificationStateUrl = 'notifications/:notificationId/state/';
export const postNotificationAllMarkAsReadUrl = 'notifications/state/bulk/';

// Agreements
export const getAgreementsUrl = 'agreements/';
export const getAgreementDetailUrl = 'agreements/:agreementId/';
export const getAgreementConsentsUrl = 'agreement-consents/';
export const getAgreementConsentDetailUrl = 'agreement-consents/:consentId/';
export const postAgreementConsentsUrl = 'agreements/:agreementId/consents/';
export const postAgreementConsentUrl = 'agreements/:agreementId/consent/';
export const getAgreementDownloadUrl = 'agreements/:id/download/';
// Locations
export const getLocationsUrl = 'data-source-locations/';
export const getLocationDetailUrl = 'data-source-locations/:locationId/';
export const patchLocationsUrl = 'data-source-locations/:locationId/';

// Seller Metrics
export const getSellerPerformanceMetricsUrl = 'data-source-performance-metrics/';
export const postSellerPerformanceMetricsUrl = 'data-source-performance-metrics/';
export const deleteSellerPerformanceMetricsUrl = 'data-source-performance-metrics/:metricId/';
export const getSalesReportsUrl = 'reports/sales/';
