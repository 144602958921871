import { OrderStatus, WidgetType } from '@constants/commontypes';
import { getOrderStatusOptions } from '@pages/Orders/OrderList/common';

import SaleReports from './components';

const StepKey = {
  SALES: 'sales',
  CANCELLATION: 'cancelation',
  RETURN: 'return',
};

export const StaticFilterKey = {
  STATUS: 'status__in',
  DATE_GTE: 'date_placed__gte',
  DATE_LTE: 'date_placed__lte',
  CANCELLATION_TYPE: 'cancellation_type',
  SALES_AMOUNT_GTE: 'sales_amount__gte',
  SALES_AMOUNT_LTE: 'sales_amount__lte',
  SALES_QUANTITY_GTE: 'sales_quantity__gte',
  SALES_QUANTITY_LTE: 'sales_quantity__lte',
  BASE_CODE: 'base_code',
  SKU: 'sku',
};

export const getStatusOptions = ({ t }) => [
  {
    label: t('active'),
    value: 1,
  },
  {
    label: t('passive'),
    value: 0,
  },
];

export const CancellationType = {
  CANCEL: 'cancel',
  REFUND: 'refund',
};

export const ReportType = {
  ...CancellationType,
  SALES: 'sales',
};

export const getStepList = ({ t }) => [
  {
    title: t('sale_reports.stepper_sales_title'),
    icon: <i className="icon-rapor-satis" />,
    key: StepKey.SALES,
    Component: (props) => <SaleReports {...props} />,
    componentProps: {
      reportType: ReportType.SALES,
      params: {
        status: OrderStatus.DELIVERED,
      },
    },
  },
  {
    title: t('sale_reports.stepper_cancellation_title'),
    icon: <i className="icon-rapor-iptal" />,
    key: StepKey.CANCELLATION,
    Component: (props) => <SaleReports {...props} />,
    componentProps: {
      reportType: ReportType.CANCEL,
      params: {
        status: OrderStatus.CANCELLED,
        cancellation_type: CancellationType.CANCEL,
      },
    },
  },
  {
    title: t('sale_reports.stepper_return_title'),
    icon: <i className="icon-rapor-iade" />,
    key: StepKey.RETURN,
    Component: (props) => <SaleReports {...props} />,
    componentProps: {
      reportType: ReportType.REFUND,
      params: {
        status: OrderStatus.CANCELLED,
        cancellation_type: CancellationType.REFUND,
      },
    },
  },
];

export const getStaticFilters = ({ t, showSkuFilter, showBaseCodeFilter }) => {
  return {
    fields: [
      {
        name: StaticFilterKey.STATUS,
        placeholder: t('status'),
        widget: WidgetType.SELECT,
        options: getOrderStatusOptions({ t }),
      },
      {
        name: StaticFilterKey.SALES_AMOUNT_GTE,
        placeholder: t('min_sales_amount'),
      },
      {
        name: StaticFilterKey.SALES_AMOUNT_LTE,
        placeholder: t('max_sales_amount'),
      },
      {
        name: StaticFilterKey.SALES_QUANTITY_GTE,
        placeholder: t('min_sales_quantity'),
      },
      {
        name: StaticFilterKey.SALES_QUANTITY_LTE,
        placeholder: t('max_sales_quantity'),
      },
      showBaseCodeFilter && {
        name: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base_code'),
      },
      showSkuFilter && {
        name: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
    ].filter(Boolean),
  };
};
