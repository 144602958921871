import { QueryKey } from '@constants/query';
import { getPackages } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions, requestConfig: import('axios').AxiosRequestConfig }} args
 */
export function usePackagesQuery({ queryOptions, requestConfig }) {
  const { data: packagesResult, isLoading } = useQuery({
    queryKey: [QueryKey.PACKAGES, requestConfig],
    queryFn: () => getPackages({ requestConfig }),
    ...queryOptions,
  });

  return {
    packages: packagesResult?.results,
    packagesResult,
    isLoadingPackages: isLoading,
  };
}
