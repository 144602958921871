import { createSelectOptions } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import AkinonForm from '@components/AkinonForm';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSwitch from '@components/AkinonSwitch';
import { WidgetType } from '@constants/commontypes';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { RouteUrls } from '@constants/routeUrls';
import { Color } from '@constants/theme';
import useAppNavigate from '@hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import { useDataSourcesQuery } from '@services/api/hooks';
import { useAgreementConsentQuery } from '@services/api/hooks/useAgreementConsentQuery';
import { useAgreementDetailQuery } from '@services/api/hooks/useAgreementDetailQuery';
import { useDeleteAgreementConsentMutation } from '@services/api/hooks/useDeleteAgreementConsentMutation';
import { usePatchAgreementMutation } from '@services/api/hooks/usePatchAgreementMutation';
import { usePostAgreementConsentsMutation } from '@services/api/hooks/usePostAgreementConsentsMutation';
import { usePostAgreementMutation } from '@services/api/hooks/usePostAgreementMutation';
import { IconInfoCircle, IconPaperclip } from '@tabler/icons-react';
import { Typography } from 'antd';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  AgreementStatus,
  FormKey,
  getFormData,
  getFormInitialValues,
  getFormResolver,
} from './common';
import CustomDragger from './components/CustomDragger';

const { Text } = Typography;

function AgreementForm() {
  const { t } = useTranslation(TranslationKey.FINANCE);
  const { agreementId } = useParams();
  const navigate = useAppNavigate();

  const { dataSources, isFetchingDataSources } = useDataSourcesQuery();
  const { agreementDetail, isAgreementDetailFetching } = useAgreementDetailQuery({
    agreementId,
    queryOptions: { enabled: Boolean(agreementId) },
  });
  const { agreementConsents, isAgreementConsentsFetching } = useAgreementConsentQuery({
    agreementId: agreementDetail?.id ?? agreementId,
    queryOptions: { enabled: Boolean(agreementDetail?.id ?? agreementId) },
  });

  const dataSourceOptions = createSelectOptions(dataSources, {
    valueKey: 'id',
    labelKey: 'name',
  });

  const { createAgreement, isAgreementCreating } = usePostAgreementMutation();
  const { updateAgreement, isAgreementUpdating } = usePatchAgreementMutation();
  const { addAgreementConsent, isAgreementConsentAdding } = usePostAgreementConsentsMutation();
  const { deleteAgreementConsent, isAgreementConsentDeleting } =
    useDeleteAgreementConsentMutation();

  const resolver = getFormResolver({ t, dataSourceOptions, agreementDetail });

  const initialValues = useMemo(
    () => getFormInitialValues({ agreementConsents, agreementDetail }),
    [agreementDetail, agreementConsents]
  );

  const form = useForm({
    mode: 'onChange',
    resolver,
    values: initialValues,
    disabled: agreementDetail?.status === AgreementStatus.PASSIVE,
  });
  const { control, watch, handleSubmit, setValue } = form;

  const visibility = watch(FormKey.VISIBILITY);

  const onSubmit = handleSubmit(async (data) => {
    const formData = getFormData(data);
    const formAction = agreementDetail ? updateAgreement : createAgreement;
    const formActionConfig = agreementDetail
      ? {
          urlParams: { agreementId: agreementDetail.id },
        }
      : {};
    formAction(
      {
        requestBody: formData,
        requestConfig: {
          ...formActionConfig,
          errorMessage: t('transaction_failed'),
          errorDescription: t('agreement_detail_message.error'),
        },
      },
      {
        onSuccess: async (result) => {
          const deletedCosents = agreementConsents
            ?.filter((consent) => !data[FormKey.CONSENTS]?.includes(consent.datasource))
            .map((consent) => consent.datasource);

          const addedConsents = data[FormKey.CONSENTS]?.filter(
            (consent) => !agreementConsents?.map((consent) => consent.datasource).includes(consent)
          );

          if (deletedCosents?.length) {
            await deleteAgreementConsent({
              requestBody: {
                urlParams: {
                  agreementId: result.id,
                },
                data: {
                  datasources: deletedCosents,
                },
              },
              requestConfig: {
                successMessage: t('transaction_success'),
                successDescription: t('agreement_detail_message.success'),
                errorMessage: t('transaction_failed'),
                errorDescription: t('agreement_detail_message.error'),
              },
            });
          }
          if (addedConsents?.length) {
            await addAgreementConsent({
              requestBody: {
                datasources: addedConsents,
              },
              requestConfig: {
                urlParams: {
                  agreementId: result.id,
                },
                successMessage: t('transaction_success'),
                successDescription: t('agreement_detail_message.success'),
                errorMessage: t('transaction_failed'),
                errorDescription: t('agreement_detail_message.error'),
              },
            });
          }

          navigate(RouteUrls.finance.agreements);
        },
      }
    );
  });

  return (
    <AkinonSpin
      spinning={
        isAgreementDetailFetching ||
        isAgreementConsentsFetching ||
        isFetchingDataSources ||
        isAgreementCreating ||
        isAgreementUpdating ||
        isAgreementConsentAdding ||
        isAgreementConsentDeleting
      }
    >
      <AkinonBox
        bannerColor={Color.WILD_WATERMELON}
        className="box-primary form-box !w-full max-w-[1200px]"
      >
        <AkinonForm {...verticalFormItemLayout}>
          <AkinonSwitch
            formItemProps={{
              control,
              name: FormKey.VISIBILITY,
              label: t('agreement_visibility'),
            }}
            checkedChildren={t('public')}
            unCheckedChildren={t('restricted')}
          />

          {!visibility && (
            <AkinonSelect
              formItemProps={{
                control,
                name: FormKey.CONSENTS,
                label: t('agreement_consents'),
                required: !visibility,
                // disabled: !isAgreementDraft,
              }}
              showSearch
              loading={isFetchingDataSources}
              options={dataSourceOptions}
              optionFilterProp="label"
              mode="multiple"
              allowClear
              placeholder={t('agreement_consents')}
            />
          )}

          <AkinonInput
            formItemProps={{
              control,
              name: FormKey.NAME,
              label: t('name'),
              required: true,
              disabled: agreementDetail?.status === AgreementStatus.ACTIVE,
            }}
            placeholder={t('name')}
          />

          <AkinonFlex className="gap-4 mb-6">
            <CustomDragger
              formItemProps={{
                control,
                name: FormKey.FILE,
                label: t('agreement_attachment'),
                required: true,
                valuePropName: 'fileList',
                tooltip: {
                  title: <Text className="text-astra">{t('uploadLimit5Mb')}</Text>,
                  icon: <IconInfoCircle style={{ color: 'white' }} size={20} />,
                },
                // disabled: !isAgreementDraft,
                disabled: agreementDetail?.status === AgreementStatus.ACTIVE,
              }}
              maxCount={1}
            >
              <AkinonFlex alignItems="center" className="w-full">
                <IconPaperclip size={16} className="ml-2 text-dodger-blue" />
                <Text className="text-white flex-1 justify-self-center">{t('dragger.desc')} </Text>
              </AkinonFlex>
            </CustomDragger>
          </AkinonFlex>

          <AkinonTextArea
            widget={WidgetType.TEXTAREA}
            formItemProps={{
              control,
              name: FormKey.DESCRIPTION,
              label: t('description'),
              disabled: agreementDetail?.status === AgreementStatus.ACTIVE,
            }}
            placeholder={t('description')}
          />

          <AkinonFlex className="gap-4">
            {(!agreementDetail || agreementDetail?.status !== AgreementStatus.PASSIVE) && (
              <AkinonButton
                type="primary"
                onClick={(event) => {
                  setValue(FormKey.STATUS, AgreementStatus.ACTIVE);
                  onSubmit(event);
                }}
              >
                {t(
                  !agreementDetail || agreementDetail?.status === AgreementStatus.DRAFT
                    ? 'publish'
                    : 'save'
                )}
              </AkinonButton>
            )}
            {(!agreementDetail || agreementDetail?.status === AgreementStatus.DRAFT) && (
              <AkinonButton
                type="primary"
                onClick={(event) => {
                  setValue(FormKey.STATUS, AgreementStatus.DRAFT);
                  onSubmit(event);
                }}
              >
                {t('save_as_draft')}
              </AkinonButton>
            )}
            {agreementDetail && agreementDetail?.status !== AgreementStatus.PASSIVE && (
              <AkinonButton
                onClick={(event) => {
                  setValue(FormKey.STATUS, AgreementStatus.PASSIVE);
                  onSubmit(event);
                }}
                type="danger"
              >
                {t('save_as_passive')}
              </AkinonButton>
            )}
          </AkinonFlex>
        </AkinonForm>
      </AkinonBox>
    </AkinonSpin>
  );
}

export default AgreementForm;
