import { defaultDateFormat } from '@constants/index';
import { statusActivePassiveFormatter } from '@utils/index';
import moment from 'moment';

export const DataIndex = {
  EMAIL: 'email',
  LAST_LOGIN: 'last_login',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  IS_ACTIVE: 'is_active',
};

export const FormKey = {
  ...DataIndex,
};

export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: DataIndex.EMAIL,
      title: t('email'),
    },
    {
      dataIndex: DataIndex.FIRST_NAME,
      title: t('first_name'),
    },
    {
      dataIndex: DataIndex.LAST_NAME,
      title: t('last_name'),
    },
    {
      dataIndex: DataIndex.IS_ACTIVE,
      title: t('status'),
      render: (cellData) => statusActivePassiveFormatter({ t, cellData }),
    },
    {
      dataIndex: DataIndex.LAST_LOGIN,
      title: t('last_login'),
      render: (lastLogin) => {
        return lastLogin && moment(lastLogin).format(defaultDateFormat);
      },
    },
  ];
};
