import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Form, Space } from 'antd';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import VariantsCollapse from './components/VariantsCollapse';

/**
 * @param {{ variantsForm: import('react-hook-form').UseFormReturn,
 *  productFeaturesForm: import('react-hook-form').UseFormReturn,
 *  basicProductInfoForm: import('react-hook-form').UseFormReturn,
 *  variantDynamicFields: any[],
 *  setIsOfferProductsReadyToBeDisplayed: import 'react'.Dispatch<import 'react'.SetStateAction<boolean>>,
 *  isOfferProductsReadyToBeDisplayed: boolean }} props
 */
const VariantsForm = ({
  variantsForm,
  productFeaturesForm,
  basicProductInfoForm,
  setIsOfferProductsReadyToBeDisplayed,
  isOfferProductsReadyToBeDisplayed,
  variantDynamicFields,
}) => {
  const { t, i18n } = useTranslation('ProductsAndCategories');
  const [expandedPanels, setExpandedPanels] = useState([]);
  const variantDynamicFieldsKeys = useMemo(
    () => variantDynamicFields?.map((variantsFormDynamicField) => variantsFormDynamicField.key),
    [variantDynamicFields]
  );
  const onDeleteAllVariants = useCallback(() => {
    setIsOfferProductsReadyToBeDisplayed(false);
    setExpandedPanels(variantDynamicFieldsKeys);
  }, [variantDynamicFieldsKeys, variantsForm, setIsOfferProductsReadyToBeDisplayed]);

  const forms = [
    { instance: basicProductInfoForm, root: 'basicProductInfoForm' },
    { instance: productFeaturesForm, root: 'productFeaturesForm' },
    { instance: variantsForm, root: 'variantsForm' },
  ];

  const onSubmit = useCallback(() => {
    Promise.all(forms.map(({ instance }) => instance.trigger())).then((formsPromiseResult) => {
      const inValidFormIndex = formsPromiseResult.findIndex((result) => !result);
      const areFormsValid = inValidFormIndex === -1;
      if (!areFormsValid) {
        const invalidForm = forms[inValidFormIndex];
        const invalidFormState = invalidForm.instance.getFieldState(invalidForm.root);
        const inValidFormKeys = Object.keys(invalidFormState.error ?? {});
        if (invalidForm.root === 'variantsForm') {
          setExpandedPanels(inValidFormKeys);
        }
        const firstKey = inValidFormKeys[0];
        invalidForm.instance.setFocus(`${invalidForm.root}.${firstKey}`);
      } else {
        setIsOfferProductsReadyToBeDisplayed(true);
        setExpandedPanels([]);
      }
    });
  }, [
    productFeaturesForm,
    variantsForm,
    basicProductInfoForm,
    setIsOfferProductsReadyToBeDisplayed,
  ]);

  return (
    <Box className="box-primary form-box variants-form" title={t('variants')}>
      <AkinonForm
        className="akn-form akn-form-v2"
        name="variantsForm"
        layout="vertical"
        {...verticalFormItemLayout}
      >
        <Space style={{ width: '100%' }} direction="vertical" size="large">
          <VariantsCollapse
            setExpandedPanels={setExpandedPanels}
            expandedPanels={expandedPanels}
            form={variantsForm}
            variantDynamicFields={variantDynamicFields}
            variantDynamicFieldsKeys={variantDynamicFieldsKeys}
            isOfferProductsReadyToBeDisplayed={isOfferProductsReadyToBeDisplayed}
          />
          <Form.Item className="variants-form__footer">
            <Space className="variants-form__footer-actions">
              <Show when={!isOfferProductsReadyToBeDisplayed}>
                <AkinonButton type="primary" htmlType="button" onClick={onSubmit}>
                  {t('continue').toLocaleUpperCase(i18n.language)}
                </AkinonButton>
              </Show>
              <Show when={isOfferProductsReadyToBeDisplayed}>
                <AkinonButton type="primary" htmlType="button" onClick={onDeleteAllVariants}>
                  {t('edit_variants').toLocaleUpperCase(i18n.language)}
                </AkinonButton>
              </Show>
              <Show when={!isOfferProductsReadyToBeDisplayed}>
                <AkinonButton
                  onClick={() => variantsForm.reset()}
                  icon={<i className="icon-bin" />}
                  type="danger"
                  htmlType="button"
                >
                  {t('delete.all.variants').toLocaleUpperCase(i18n.language)}
                </AkinonButton>
              </Show>
            </Space>
          </Form.Item>
        </Space>
      </AkinonForm>
    </Box>
  );
};

export default memo(VariantsForm);
