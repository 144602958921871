import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { TranslationKey } from '@root/i18n';
import { useDeleteSellerPerformanceMetricMutation } from '@services/api/hooks/useDeleteSellerPerformanceMetricMutation';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { usePostSellerPerformanceMetricsMutation } from '@services/api/hooks/usePostSellerPerformanceMetricsMutation';
import { useSellerPerformanceMetricsQuery } from '@services/api/hooks/useSellerPerformanceMetricsQuery';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  defaultSellerPerformanceMetrics,
  defaultSellerPerformanceMetricsData,
  getFormSchema,
  processSellerPerformanceMetrics,
  transformMetrics,
} from './common';
import { MetricBox } from './components/MetricBox';
import useBreadcrumbs from './hooks/useBreadcrumbs';
import usePageMeta from './hooks/usePageMeta';

const SellerPerformanceMetrics = () => {
  const { t, i18n } = useTranslation(TranslationKey.FINANCE);

  usePageMeta();
  useBreadcrumbs();

  const [selectedMetric, setSelectedMetric] = React.useState(null);

  const { dynamicSettings } = useDynamicSettingsQuery({
    params: {
      key: 'BASE_MAX_GRADE',
      is_active: true,
    },
  });

  const maxGrade = first(dynamicSettings)?.value;

  const {
    sellerPerformanceMetrics,
    isFetchingSellerPerformanceMetrics,
    refetchSellerPerformanceMetrics,
  } = useSellerPerformanceMetricsQuery({});

  const { postSellerPerformanceMetrics, isPostingSellerPerformanceMetrics } =
    usePostSellerPerformanceMetricsMutation({});

  const { deleteSellerPerformanceMetricAsync, isDeletingSellerPerformanceMetric } =
    useDeleteSellerPerformanceMetricMutation();

  const initialPerformanceMetrics = React.useMemo(() => {
    if (!isEmpty(sellerPerformanceMetrics)) {
      return processSellerPerformanceMetrics(sellerPerformanceMetrics);
    }

    return defaultSellerPerformanceMetricsData;
  }, [sellerPerformanceMetrics]);

  const formSchema = getFormSchema({ t, maxGrade });

  const { watch, control, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    resolver: zodResolver(formSchema),
    values: {
      seller_performance_metrics: initialPerformanceMetrics,
    },
  });

  const onSubmit = (data) => {
    const transformedData = transformMetrics(data);

    postSellerPerformanceMetrics(
      {
        requestBody: transformedData,
      },
      {
        onSuccess: async () => {
          const filteredEmptyMetrics = sellerPerformanceMetrics.filter(
            (metric) =>
              metric.weight === 0 && get(data.seller_performance_metrics, `${metric.key}.id`)
          );

          const deletePromises = filteredEmptyMetrics.map((metric) =>
            deleteSellerPerformanceMetricAsync({
              metricId: metric.id,
            })
          );
          await Promise.allSettled(deletePromises);
          refetchSellerPerformanceMetrics();
        },
      }
    );
  };

  const notExistMetrics = defaultSellerPerformanceMetrics.filter(
    (metric) => !Object.keys(watch('seller_performance_metrics')).includes(metric.key)
  );

  const handleAddMetric = () => {
    if (notExistMetrics.length > 0) {
      const newMetric = notExistMetrics.find((metric) => metric.key === selectedMetric);

      setValue(`seller_performance_metrics.${newMetric.key}`, {
        id: null,
        key: newMetric.key,
        name: newMetric.name,
        weight: 0,
        grade_list: [
          {
            min: 0,
            max: 100,
            score: 0,
          },
        ],
      });

      setSelectedMetric(null);
    }
  };

  return (
    <section>
      <AkinonBox
        title={t('seller_performance_metrics')}
        description={t('seller_performance_metrics_desc')}
        bannerColor={Color.WILD_WATERMELON}
        shadow
        className="box-primary form-box"
      >
        <AkinonSpin
          spinning={
            isPostingSellerPerformanceMetrics ||
            isFetchingSellerPerformanceMetrics ||
            isDeletingSellerPerformanceMetric
          }
        >
          <AkinonForm className="akn-form akn-form-v2" layout="vertical">
            <AkinonSelect
              formItemProps={{
                label: t('select_metric'),
              }}
              options={notExistMetrics.map((metric) => ({
                label: t(metric.key),
                value: metric.key,
              }))}
              value={selectedMetric}
              onChange={(value) => setSelectedMetric(value)}
              disabled={isEmpty(notExistMetrics)}
              placeholder={t('select_metric')}
            />
            <AkinonButton
              htmlType="submit"
              type="primary"
              className="mb-10"
              onClick={handleAddMetric}
              disabled={isEmpty(notExistMetrics)}
            >
              {t('add_metric').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
            {Object.entries(watch('seller_performance_metrics')).map(([key, metric]) => (
              <MetricBox
                key={key}
                t={t}
                tooltip={defaultSellerPerformanceMetrics.find((m) => m.key === key)?.tooltip}
                title={metric.key}
                control={control}
                metricKey={key}
                watch={watch}
                setValue={setValue}
              />
            ))}

            <AkinonButton
              htmlType="submit"
              type="primary"
              className="mt-5"
              onClick={handleSubmit(onSubmit)}
            >
              {t('submit').toLocaleUpperCase(i18n.language)}
            </AkinonButton>
          </AkinonForm>
        </AkinonSpin>
      </AkinonBox>
    </section>
  );
};

export default SellerPerformanceMetrics;
