import AkinonSpin from '@components/AkinonSpin';
import If from '@components/If';
import Link from '@components/Link';
import { dateFormatter } from '@utils/index';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

const PackageInfo = ({
  t,
  packageDetail,
  shippingMethod,
  orderDetail,
  locationDetail,
  isPackageInfoLoading,
}) => {
  return (
    <AkinonSpin spinning={isPackageInfoLoading}>
      <div className="detail-item">
        <div className="detail-item">
          <div className="detail-item--title">{t('package_number')}</div>
          <span className="detail-item--desc">{packageDetail?.number ?? '-'}</span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('delivery_date')}</div>
          <span className="detail-item--desc">
            {packageDetail?.delivery_date ? dateFormatter(packageDetail.delivery_date, true) : '-'}
          </span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('shipping.company')}</div>
          <span className="detail-item--desc">{packageDetail?.shipping_company ?? '-'}</span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('delivery_option_type')}</div>
          <span className="detail-item--desc">{shippingMethod?.name ?? '-'}</span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('cargo_tracking_number')}</div>
          <If
            condition={!isEmpty(packageDetail?.tracking_url)}
            then={
              <Link target="_blank" to={packageDetail?.tracking_url}>
                {packageDetail?.tracking_number}
              </Link>
            }
            otherwise={<span className="detail-item--desc">-</span>}
          />
        </div>
        <div className="detail-item">
          <div className="detail-item--title">{t('invoice_number')}</div>
          <If
            condition={!isEmpty(orderDetail?.e_archive_url)}
            then={
              <Link target="_blank" to={orderDetail?.e_archive_url}>
                {packageDetail?.invoice_number}
              </Link>
            }
            otherwise={
              <span className="detail-item--desc">
                {packageDetail?.invoice_number ? packageDetail.invoice_number : '-'}
              </span>
            }
          />
        </div>
        <div className="detail-item">
          <div className="detail-item--title">{t('location')}</div>
          <span className="detail-item--desc">
            <If condition={!isEmpty(locationDetail)} then={locationDetail?.name} otherwise={'-'} />
          </span>
        </div>
      </div>
    </AkinonSpin>
  );
};

export default PackageInfo;
