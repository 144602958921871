import AkinonSpin from '@components/AkinonSpin';
import Link from '@components/Link';
import Show from '@components/Show';
import { RouteUrls } from '@constants/routeUrls';
import { Empty, List, Space, Typography } from 'antd';
import pick from 'lodash/pick';
import values from 'lodash/values';
import React from 'react';
import { generatePath } from 'react-router-dom';

const { Text } = Typography;

const ShippingInfo = ({ t, packages, orderDetail, isLoadingOrderDetail, isLoadingPackages }) => {
  const formattedShippingAddress = `${orderDetail?.shipping_address_obj?.line}\n${values(
    pick(orderDetail, ['shippingTownship', 'shippingDistrict', 'shippingCity', 'shippingCountry'])
  )
    .map((address) => address?.name)
    .join('/')}`;

  const formattedBillingAddress = `${orderDetail?.billing_address_obj?.line}\n${values(
    pick(orderDetail, ['billingTownship', 'billingDistrict', 'billingCity', 'billingCountry'])
  )
    .map((address) => address?.name)
    .join('/')}`;

  return (
    <AkinonSpin spinning={isLoadingOrderDetail || isLoadingPackages}>
      <div className="detail-item">
        <div className="detail-item">
          <div className="detail-item--title">{t('packages')}</div>
          <span className="detail-item--desc">
            <List
              className="order-detail__package-list"
              itemLayout="horizontal"
              dataSource={packages}
              split={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t('no_data_available')}
                  />
                ),
              }}
              renderItem={(orderPackage) => (
                <List.Item className="order-detail__package-list-item">
                  <List.Item.Meta
                    className="order-detail__package-list-item-meta"
                    title={
                      <Link
                        className="order-detail__package-list-item-meta-title"
                        to={generatePath(RouteUrls.order.packages.packageDetail, {
                          packageId: orderPackage.id,
                        })}
                      >
                        {orderPackage.number}
                      </Link>
                    }
                    description={
                      <Space>
                        <Text className="order-detail__package-list-item-meta-text">
                          {orderPackage.shipping_company}
                        </Text>
                        <Show when={orderPackage.tracking_number}>
                          <Text className="order-detail__package-list-item-meta-text">-</Text>
                        </Show>
                        <Link to={orderPackage.tracking_url} target="_blank">
                          {orderPackage.tracking_number}
                        </Link>
                      </Space>
                    }
                  />
                </List.Item>
              )}
            />
          </span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('shipping_address')}</div>
          <span className="detail-item--desc">
            {orderDetail?.shipping_address_obj?.line ? formattedShippingAddress : '-'}
          </span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('billing_address')}</div>
          <span className="detail-item--desc">
            {orderDetail?.billing_address_obj?.line ? formattedBillingAddress : '-'}
          </span>
        </div>
      </div>
    </AkinonSpin>
  );
};

export default ShippingInfo;
