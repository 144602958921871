import AkinonButton from '@components/AkinonButton';
import AkinonInput from '@components/AkinonInput';
import { useStringCaseConverter } from '@hooks/useStringCaseConverter';
import { isObject } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import { ExportFormKey } from './ExportModal';

const getTextOfReactElement = (element) => {
  if (element?.props?.children) {
    return getTextOfReactElement(element.props.children);
  }
  return element;
};

const ExportableColumnsSearch = ({
  exportableColumns,
  filteredExportableColumns,
  setFilteredExportableColumns,
  setValue,
}) => {
  const { toLowerCase } = useStringCaseConverter();
  const { t } = useTranslation();
  const [search, setSearch] = useState(null);
  const onSearch = (value) => {
    setSearch(value);
  };

  useDeepCompareEffect(() => {
    if (search) {
      setFilteredExportableColumns(
        exportableColumns.filter((column) => {
          const titleToSearch = column?.exportTitle ?? column?.title;
          const normalizedTitle = isObject(titleToSearch)
            ? getTextOfReactElement(titleToSearch)
            : titleToSearch;
          return toLowerCase(normalizedTitle)?.includes?.(toLowerCase(search));
        })
      );
    } else {
      setFilteredExportableColumns(exportableColumns);
    }
  }, [search, exportableColumns]);

  const onAddFiltered = () => {
    filteredExportableColumns.forEach((column) => {
      const key = [ExportFormKey.FIELDS_TO_EXPORT, column.exportKey].join('.');
      setValue(key, true);
    });
  };

  const onAddAll = () => {
    exportableColumns.forEach((column) => {
      const key = [ExportFormKey.FIELDS_TO_EXPORT, column.exportKey].join('.');
      setValue(key, true);
    });
  };

  const onRemoveAll = () => {
    exportableColumns.forEach((column) => {
      const key = [ExportFormKey.FIELDS_TO_EXPORT, column.exportKey].join('.');
      setValue(key, false);
    });
  };

  return (
    <>
      <AkinonInput
        defaultValue={search}
        onKeyUp={(e) => onSearch(e.target.value)}
        onBlur={(e) => onSearch(e.target.value)}
        style={{ width: 300 }}
        placeholder={t('search')}
        className="export-columns-search"
        suffix={<i className="icon-search" />}
        onSearch={onSearch}
      />
      <div className="table-export-modal__export-filter_actions">
        <AkinonButton type="button" onClick={onAddFiltered}>
          {t('add_filtered')}
        </AkinonButton>
        <AkinonButton type="button" onClick={onAddAll}>
          {t('add_all')}
        </AkinonButton>
        <AkinonButton type="button" onClick={onRemoveAll}>
          {t('remove_all')}
        </AkinonButton>
      </div>
    </>
  );
};

export default ExportableColumnsSearch;
