import { z } from 'zod';

import { FormKey } from '../../../OfferDetail/components/RejectModal/common';

export const getFormSchema = ({ rejectionReasonsOptions }) => {
  const schema = z.object({
    [FormKey.REJECTION_REASON]: z.union(
      rejectionReasonsOptions?.map((rejectionReason) => z.literal(rejectionReason.value))
    ),
    [FormKey.REJECTION_EXPLANATION]: z.string().optional(),
  });

  return schema;
};
