import './styles.scss';

import { useAppShell } from '@akinon/app-shell';
import { routeUrlSet } from '@constants/routeUrls';
import { useEnv } from '@hooks/index';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { Menu } from 'antd';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes } from 'react-router-dom';
import { useLocation } from 'react-use';

import { getMenuItems } from '../common';
import { findCurrentOpenKeys, getMenuItemLevelKeys } from './common';

const AkinonMenu = (props) => {
  const { t } = useTranslation(['Settings']);
  const { isProd } = useEnv();
  const { apps, configs } = useAppShell();
  const user = useUser();
  const { collapsed } = props;

  const navigate = useAppNavigate();
  const location = useLocation();
  const matches = matchRoutes(
    routeUrlSet.map((route) => ({ path: route })),
    location
  );
  const lastMatch = last(matches);

  const [openMenuKeys, setOpenMenuKeys] = useState([]);
  const [selectedMenuKeys, setSelectedMenuKeys] = useState([]);

  const menuItems = useMemo(
    () => getMenuItems({ apps, configs, user, t, isProd, collapsed }),
    [apps, configs, user, t, isProd, collapsed]
  );

  const levelKeys = useMemo(() => getMenuItemLevelKeys(menuItems), [menuItems]);

  useEffect(() => {
    (async () => {
      if (collapsed) {
        setOpenMenuKeys(undefined);
        setSelectedMenuKeys(undefined);
      } else {
        const openKeys = findCurrentOpenKeys(menuItems, lastMatch?.route.path);
        const selectedKeys = lastMatch ? [lastMatch.route.path] : [];
        setOpenMenuKeys(openKeys);
        setSelectedMenuKeys(selectedKeys);
      }
    })();
  }, [collapsed, isEmpty(menuItems)]);

  const onMenuClick = ({ key: routeUrl }) => {
    props.onClick?.({ key: routeUrl });
    setSelectedMenuKeys([routeUrl]);
    navigate(routeUrl);
    props?.closeMobileDrawer?.();
  };

  const handleOpenAndSelectedKeys = ({ openKeys, currentOpenKey = undefined, url }) => {
    const repeatIndex = openKeys
      ?.filter((key) => key !== (currentOpenKey ?? url))
      ?.findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey ?? url]);

    setOpenMenuKeys([
      ...openKeys
        ?.filter((_, index) => index !== repeatIndex)
        ?.filter((key) => levelKeys[key] <= levelKeys[currentOpenKey ?? url]),
    ]);

    setSelectedMenuKeys([url]);
    navigate(url);
  };

  const onOpenChange = (openKeys) => {
    if (collapsed) return undefined;

    const currentOpenKey = openKeys?.find((key) => openMenuKeys?.indexOf(key) === -1);

    if (currentOpenKey !== undefined) {
      const currentRouteUrl = last(openKeys);

      handleOpenAndSelectedKeys({ openKeys, currentOpenKey, url: currentRouteUrl });
    } else {
      const backToParentUrl = openMenuKeys?.find((key) => openKeys?.indexOf(key) === -1);
      handleOpenAndSelectedKeys({ openKeys, url: backToParentUrl });
    }
  };

  return (
    <Menu
      {...props}
      className={clsx('akinon-menu', props.className)}
      openKeys={openMenuKeys}
      selectedKeys={selectedMenuKeys}
      items={menuItems}
      onClick={onMenuClick}
      onOpenChange={onOpenChange}
      inlineCollapsed={collapsed}
    />
  );
};

export default memo(AkinonMenu);
