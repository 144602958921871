import AkinonSpin from '@components/AkinonSpin';
import If from '@components/If';
import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import { StatusText } from '@pages/Orders/common';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { generatePath } from 'react-router-dom';

const OrderInfo = ({ t, billingAddress, orderDetail, isPackageInfoLoading }) => {
  return (
    <AkinonSpin spinning={isPackageInfoLoading}>
      <div className="detail-item">
        <div className="detail-item">
          <div className="detail-item--title">{t('order.number')}</div>
          <If
            condition={!isEmpty(orderDetail?.number)}
            then={
              <Link
                to={generatePath(RouteUrls.order.orderDetail, { orderId: orderDetail?.id ?? 0 })}
              >
                {orderDetail?.number}
              </Link>
            }
            otherwise={<span className="detail-item--desc">-</span>}
          />
        </div>
        <div className="detail-item">
          <div className="detail-item--title">{t('order.status')}</div>
          <span className="detail-item--desc">
            {StatusText({ t })?.[orderDetail?.status] ?? '-'}
          </span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('customer_name')}</div>
          <span className="detail-item--desc">
            {billingAddress?.first_name && billingAddress?.last_name
              ? `${billingAddress.first_name} ${billingAddress.last_name}`
              : '-'}
          </span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('email')}</div>
          <span className="detail-item--desc">{billingAddress?.email ?? '-'}</span>
        </div>

        <div className="detail-item">
          <div className="detail-item--title">{t('phone_number')}</div>
          <span className="detail-item--desc">{billingAddress?.phone_number ?? '-'}</span>
        </div>
      </div>
    </AkinonSpin>
  );
};

export default OrderInfo;
