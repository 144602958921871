import { createNavigationItems, getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.marketing.productCollections.landing,
        title: t('collections'),
        description: t('collections_desc'),
        icon: <i className="icon-koleksiyonlar" />,
      },
      {
        to: RouteUrls.marketing.campaigns.landing,
        title: t('campaigns'),
        description: isSuperUser ? t('super_user_campaigns_desc') : t('seller_campaigns_desc'),
        icon: <i className="icon-kampanyalar" />,
      },
    ],
    userRole,
    isProd,
  });
};
