import './styles.scss';

import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getIntegrationAccessTokensUrl } from '@constants/apiUrls';
import { useDeleteIntegrationAccessToken } from '@services/api/hooks/useDeleteIntegrationAccessToken';
import { usePostIntegrationAccessTokenMutation } from '@services/api/hooks/usePostIntegrationAccessTokenMutation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import AccessTokenFormModal from './components/AccessTokenFormModal';
import RevokeModal from './components/RevokeModal';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import { usePageMeta } from './hooks/usePageMeta';

const AccessTokenList = () => {
  const { t, i18n } = useTranslation('Settings');

  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();

  const [isOpenFormModal, setIsOpenModalForm] = useState(false);
  const [isOpenRevokeModal, setIsRevokeModal] = useState(false);
  const [selectedAccessTokenId, setSelectedAccessTokenId] = useState(null);

  const { dataSource, isDataSourceLoading, total } = useDataSource({
    pagination,
    filters,
  });

  const { createIntegrationAccessToken, isCreatingNewIntegrationAccessToken } =
    usePostIntegrationAccessTokenMutation({
      mutationOptions: {
        onSuccess: () => {
          handleCloseFormModal();
        },
      },
    });

  const { deleteIntegrationAccessToken, isDeletingIntegrationAccessToken } =
    useDeleteIntegrationAccessToken({
      mutationOptions: {
        onSuccess: () => {
          handleCloseRevokeModal();
        },
      },
    });

  const handleOpenFormModal = () => {
    setIsOpenModalForm(true);
  };

  const handleCloseFormModal = () => {
    setIsOpenModalForm(false);
  };

  const handleOpenRevokeModal = ({ accessTokenId }) => {
    setSelectedAccessTokenId(accessTokenId);
    setIsRevokeModal(true);
  };

  const handleCloseRevokeModal = () => {
    setIsRevokeModal(false);
  };

  const handleRevokeModalConfirm = () => {
    deleteIntegrationAccessToken({
      accessTokenId: selectedAccessTokenId,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('access_tokens_message.remove.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('access_tokens_message.error'),
      },
    });
  };

  usePageMeta({ showOpenFormModal: handleOpenFormModal });
  useBreadcrumbs();

  const columns = getColumns({
    t,
    i18n,
    handleOpenRevokeModal,
  });

  return (
    <section className="access-tokens">
      <AkinonTable
        title={t('access_tokens_title')}
        rowKey="key"
        dataSource={dataSource}
        optionsUrl={getIntegrationAccessTokensUrl}
        columns={columns}
        loading={isDataSourceLoading}
        total={total}
        description={`${total} ${t('results.found')}`}
        pagination={pagination}
        setPagination={setPagination}
      />

      <AccessTokenFormModal
        show={isOpenFormModal}
        onClose={handleCloseFormModal}
        createIntegrationAccessToken={createIntegrationAccessToken}
        isCreatingNewIntegrationAccessToken={isCreatingNewIntegrationAccessToken}
      />

      <RevokeModal
        show={isOpenRevokeModal}
        onClose={handleCloseRevokeModal}
        onClick={handleRevokeModalConfirm}
        loading={isDeletingIntegrationAccessToken}
      />
    </section>
  );
};

export default AccessTokenList;
