import { openNotification } from '@components/AkinonNotification';
import { httpMethods } from '@constants/commontypes';

const useUpdateProductSpecialCommission = ({
  t,
  specialCommissionProductDataSource,
  postReconciliationRulesAsync,
  deleteReconciliationRulesAsync,
  refetchProductOffers,
  refetchReconciliationRules,
}) => {
  const onUpdateProductSpecialCommission = ({ formValues, selectedRows }) => {
    const promises = selectedRows.map((row) => {
      const selectedSpecialCommissionProduct = specialCommissionProductDataSource?.find(
        (specialCommissionProduct) => specialCommissionProduct.id === row
      );
      return async () => {
        try {
          await deleteReconciliationRulesAsync({
            ruleId: selectedSpecialCommissionProduct?.id,
            requestConfig: {
              suppressedNotifications: ['success', 'error'],
            },
          });

          await postReconciliationRulesAsync({
            requestBody: {
              ...formValues,
              datasource: selectedSpecialCommissionProduct?.datasource,
              product: selectedSpecialCommissionProduct?.productOfferId,
            },
            requestConfig: {
              suppressedNotifications: ['success', 'error'],
            },
          });

          openNotification({
            message: t('transaction_success'),
            description: t('transactions.body.create_commission_success'),
          });
        } catch (error) {
          if (error?.config?.method === httpMethods.DELETE.toLowerCase()) {
            openNotification({
              t,
              message: t('transaction_failed'),
              description: t('transactions.body.create_commission_failed'),
              type: 'error',
            });
          } else {
            openNotification({
              message: t('transaction.title.update_error'),
              description: t('transactions.body.update_commission_error'),
              type: 'error',
            });
          }
          return;
        }
      };
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      refetchProductOffers();
      refetchReconciliationRules();
    });
  };

  return { onUpdateProductSpecialCommission };
};

export default useUpdateProductSpecialCommission;
