import './style.scss';

import { MinusCircleOutlined } from '@ant-design/icons';
import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonDragger from '@components/AkinonDragger';
import AkinonFlex from '@components/AkinonFlex';
import AkinonForm from '@components/AkinonForm';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import AkinonSwitch from '@components/AkinonSwitch';
import CopyButton from '@components/CopyButton';
import TitleWithHint from '@components/TitleWithHint';
import Box from '@components/utility/box';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import {
  useOAuthOptionsQuery,
  useOAuthProviderBySlugQuery,
} from '@services/api/hooks/useOAuthProvidersQuery';
import { usePatchOauthProviderMutation } from '@services/api/hooks/usePatchOauthProviderMutation';
import { usePostOauthProviderMutation } from '@services/api/hooks/usePostOauthProviderMutation';
import { queryClient } from '@services/api/queryClient';
import { Form, Image, Typography } from 'antd';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  FormKey,
  getFormResolver,
  getInitialValues,
  getRequestBodyFromFormValues,
} from './common/index';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const { Text } = Typography;

export default function SsoSettingsForm() {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const { providerSlug } = useParams();
  const { oAuthOptions } = useOAuthOptionsQuery();
  const providerChoices = oAuthOptions?.actions?.POST?.provider?.choices;
  const providerOptions = createSelectOptions(providerChoices, {
    labelKey: 'display_name',
    valueKey: 'value',
  });
  const isEditing = Boolean(providerSlug);

  const resolver = getFormResolver({ providerOptions, isEditing });

  const { updateProvider, isProviderUpdating } = usePatchOauthProviderMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.OAUTH_PROVIDERS],
        });
        navigate(RouteUrls.settings.ssoLoginSettingsUrl);
      },
    },
  });
  const { createProvider, isProviderCreating } = usePostOauthProviderMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKey.OAUTH_PROVIDERS],
        });
        navigate(RouteUrls.settings.ssoLoginSettingsUrl);
      },
    },
  });
  const { provider, isFetchingProvider } = useOAuthProviderBySlugQuery({
    slug: providerSlug,
    queryOptions: {
      enabled: Boolean(providerSlug),
    },
  });

  const { control, watch, handleSubmit, reset, setValue } = useForm({
    mode: 'onChange',
    resolver,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: FormKey.ADDITIONAL_ARGS,
  });

  const redirectUrl = watch(FormKey.REDIRECT_URL);
  const icon = watch(FormKey.ICON);

  const initialValues = getInitialValues(provider);
  useBreadcrumbs();
  usePageMeta();

  useEffect(() => {
    reset(initialValues);
  }, [provider]);

  const onSlugChange = (event) => {
    setValue(
      FormKey.REDIRECT_URL,
      `${window.location.origin}/sign-in/${event.target.value}/callback`
    );
  };

  const onSubmit = (formValues) => {
    const submitValue = getRequestBodyFromFormValues({ formValues });

    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('sso_provider_message.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('sso_provider_message.error'),
    };

    if (providerSlug) {
      updateProvider({
        slug: providerSlug,
        requestBody: submitValue,
        requestConfig,
      });
    } else {
      createProvider({
        requestBody: submitValue,
        requestConfig,
      });
    }
  };

  const boxTitle = isEditing
    ? t('sso_settings.edit_form_title')
    : t('sso_settings.create_form_title');
  const boxSubtitle = isEditing
    ? t('sso_settings.edit_form_description')
    : t('sso_settings.create_form_description');

  return (
    <section>
      <Box
        title={boxTitle}
        subtitle={boxSubtitle}
        className="box-primary form-box sso-settings-form max-w-[800px]"
        loading={isFetchingProvider}
      >
        <AkinonSpin spinning={isFetchingProvider} tip="">
          <AkinonForm layout="vertical">
            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint text={t('sso_settings.name')} hint={t('sso_settings.name_hint')} />
                ),
                name: FormKey.NAME,
                required: true,
              }}
              placeholder={t('sso_settings.name')}
            />
            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint text={t('sso_settings.slug')} hint={t('sso_settings.slug_hint')} />
                ),
                name: FormKey.SLUG,
                required: true,
              }}
              onChange={onSlugChange}
              placeholder={t('sso_settings.slug')}
              readOnly={isEditing}
            />
            <AkinonSelect
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.provider')}
                    hint={t('sso_settings.provider_hint')}
                  />
                ),
                name: FormKey.PROVIDER,
                required: true,
              }}
              options={providerOptions}
              placeholder={t('sso_settings.provider')}
            />

            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.client_id')}
                    hint={t('sso_settings.client_id_hint')}
                  />
                ),
                name: FormKey.CLIENT_ID,
                required: true,
              }}
              placeholder={t('sso_settings.client_id')}
            />

            <AkinonTextArea
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.client_secret')}
                    hint={t('sso_settings.client_secret_hint')}
                  />
                ),
                name: FormKey.CLIENT_SECRET,
                required: !isEditing,
              }}
              placeholder={t('sso_settings.client_secret')}
            />
            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.authorization_url')}
                    hint={t('sso_settings.authorization_url_hint')}
                  />
                ),
                name: FormKey.AUTHORIZATION_URL,
                required: true,
              }}
              placeholder={t('sso_settings.authorization_url')}
            />
            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.token_url')}
                    hint={t('sso_settings.token_url_hint')}
                  />
                ),
                name: FormKey.TOKEN_URL,
                required: true,
              }}
              placeholder={t('sso_settings.token_url')}
            />
            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.userinfo_url')}
                    hint={t('sso_settings.userinfo_url_hint')}
                  />
                ),
                name: FormKey.USER_INFO_URL,
                required: true,
              }}
              placeholder={t('sso_settings.userinfo_url')}
            />
            <AkinonInput
              formItemProps={{
                control,
                label: (
                  <TitleWithHint
                    text={t('sso_settings.redirect_url')}
                    hint={t('sso_settings.redirect_url_hint')}
                  />
                ),
                name: FormKey.REDIRECT_URL,
                required: true,
              }}
              readOnly
              className="redirect-input"
              placeholder={t('sso_settings.redirect_url')}
              addonAfter={<CopyButton className="m-0 p-0" text={redirectUrl} />}
            />
            <AkinonFlex direction="column" className="gap-4 ">
              <Text className="text-white">{t('sso_settings.additional_args')}</Text>
              {fields.map((field, index) => (
                <div key={field.id} className="settings-form-list-wrapper">
                  <div className="settings-form-list-title-wrapper">
                    <MinusCircleOutlined onClick={() => remove(index)} />
                    <div className="settings-form-list-title">{`${index + 1}.${t('argument')}`}</div>
                  </div>
                  <AkinonInput
                    formItemProps={{
                      control,
                      label: 'key',
                      name: `${FormKey.ADDITIONAL_ARGS}[${index}].key`,
                      required: true,
                    }}
                    placeholder="key"
                  />
                  <AkinonInput
                    formItemProps={{
                      control,
                      label: 'value',
                      name: `${FormKey.ADDITIONAL_ARGS}[${index}].value`,
                      required: true,
                    }}
                    placeholder="value"
                  />
                </div>
              ))}
              <Form.Item>
                <AkinonButton
                  type="secondary"
                  className="mb-2 "
                  icon={<i className="icon-arti" />}
                  onClick={() =>
                    append({
                      key: '',
                      value: '',
                    })
                  }
                >
                  {t('add_argument')}
                </AkinonButton>
              </Form.Item>
            </AkinonFlex>

            <AkinonDragger
              formItemProps={{
                control,
                label: t('sso_settings.icon'),
                name: FormKey.ICON,
                required: true,
              }}
              accept="image/*"
              fileList={[icon?.fileList?.at?.(-1)].filter(Boolean)}
              listType="picture"
              maxCount={1}
              itemRender={(originNode, file) => {
                return (
                  <div className="upload-preview-image-wrapper">
                    <Image
                      src={file?.url ?? file?.thumbUrl}
                      className="upload-preview-image"
                      width={48}
                      height={48}
                    />
                    {originNode}
                  </div>
                );
              }}
            />
            <AkinonSwitch
              formItemProps={{
                control,
                label: t('sso_settings.is_active'),
                name: FormKey.IS_ACTIVE,
              }}
              placeholder={t('sso_settings.is_active')}
            />

            <Form.Item className="form-submit-button">
              <AkinonButton
                htmlType="button"
                onClick={handleSubmit(onSubmit)}
                type="primary"
                loading={isProviderUpdating || isProviderCreating}
              >
                {t('submit')}
              </AkinonButton>
            </Form.Item>
          </AkinonForm>
        </AkinonSpin>
      </Box>
    </section>
  );
}
