import { createNavigationItems } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';

export const getNavigationItems = ({ t, userRole, isProd }) => {
  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.productsAndCategories.products.productForm,
        title: t('add_product'),
        description: t('add_product_description'),
        icon: <i className="icon-add-single-product" />,
      },
      {
        to: RouteUrls.productsAndCategories.bulkActions.offers,
        title: t('add_bulk_product'),
        description: t('add_bulk_product_description'),
        icon: <i className="icon-add-multiple-products" />,
      },
      {
        to: RouteUrls.productsAndCategories.products.productPool,
        state: { isBulkSearchProductActive: true },
        title: t('search_bulk_product'),
        description: t('search_bulk_product_description'),
        icon: <i className="icon-search-multiple-products" />,
      },
    ],
    userRole,
    isProd,
  });
};
