import { resetPasswordWithTokenUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export function useResetPasswordQuery({ queryOptions, requestConfig, resetToken } = {}) {
  const { isSuccess, error, isFetched } = useAkinonQuery({
    queryKey: [QueryKey.RESET_PASSWORD, resetToken, requestConfig],
    queryFn: () =>
      client.get(resetPasswordWithTokenUrl, {
        ...requestConfig,
        urlParams: { resetToken },
        suppressedNotifications: ['success', 'error'],
      }),
    ...queryOptions,
  });

  return {
    isPasswordResetTokenValid: isFetched ? isSuccess : true,
    passwordResetTokenError: error,
  };
}
