import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import { AkinonInputNumber } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import If from '@components/If';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { usePatchCancellationReasonMutation } from '@services/api/hooks/usePatchCancelletionReasonMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CalculationType, FormKey, getFormResolver } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import { usePageMeta } from './hooks/usePageMeta';

const OrderRejectionDeductionDetail = () => {
  const { t } = useTranslation('Settings');
  const { cancellationReasonId } = useParams();
  const navigate = useAppNavigate();

  const userRole = useUserRole();
  const isSeller = userRole === UserRole.SELLER;

  useBreadcrumbs();
  usePageMeta();

  const {
    cancellationReason,
    calculationOptions,
    referenceOptions,
    isFetchingCancellationReason,
    isFetchingCancellationReasonOptions,
  } = useDataSource({ cancellationReasonId });

  const { updateCancellationReason, isCancellationReasonUpdating } =
    usePatchCancellationReasonMutation({
      mutationOptions: {
        onSuccess: () => {
          navigate(RouteUrls.settings.orderRejectionDeductions);
        },
      },
    });

  const resolver = getFormResolver({ t, referenceOptions });

  const initialValues = useMemo(() => {
    return {
      penalty_config: {
        calculation_type: cancellationReason?.penalty_config?.calculation_type,
        amount: parseInt(cancellationReason?.penalty_config?.amount, 10),
        reference: cancellationReason?.penalty_config?.reference,
        multiplier: parseFloat(cancellationReason?.penalty_config?.multiplier),
      },
    };
  }, [cancellationReason]);

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    resolver,
    disabled: isSeller,
    values: initialValues,
  });

  const onSubmit = (formValues) => {
    updateCancellationReason({
      cancellationReasonId,
      requestBody: formValues,
    });
  };

  const calculationType = watch(FormKey.PENALTY_CONFIG.CALCULATION_TYPE);

  return (
    <AkinonSpin spinning={isFetchingCancellationReason || isCancellationReasonUpdating}>
      <Box
        title={t('order_rejection_deduction_detail')}
        subtitle={t('order_rejection_deduction_detail_desc')}
        className="box-primary form-box reduction-detail-form"
      >
        <AkinonForm layout="vertical">
          <AkinonSelect
            formItemProps={{
              control,
              name: FormKey.PENALTY_CONFIG.CALCULATION_TYPE,
              label: t('calculation_type'),
              required: true,
              tooltip: {
                title: t('calculation_type_tooltip'),
              },
            }}
            placeholder={t('calculation_type')}
            options={calculationOptions}
            isLoading={isFetchingCancellationReasonOptions}
          />
          <If
            condition={calculationType === CalculationType.FIXED}
            then={
              <AkinonInputNumber
                formItemProps={{
                  name: FormKey.PENALTY_CONFIG.AMOUNT,
                  label: t('amount'),
                  control,
                  required: true,
                }}
                placeholder={t('amount')}
                step={1}
                min={0}
              />
            }
            otherwise={null}
          />
          <If
            condition={calculationType === CalculationType.PERCENTAGE}
            then={
              <>
                <AkinonSelect
                  formItemProps={{
                    control,
                    name: FormKey.PENALTY_CONFIG.REFERENCE,
                    label: t('reference'),
                    required: true,
                    tooltip: {
                      title: t('reference_tooltip'),
                    },
                  }}
                  placeholder={t('reference')}
                  options={referenceOptions}
                  isLoading={isFetchingCancellationReasonOptions}
                />
                <AkinonInputNumber
                  formItemProps={{
                    name: FormKey.PENALTY_CONFIG.MULTIPLIER,
                    label: t('multiplier'),
                    control,
                    required: true,
                    precision: 2,
                    step: 0.01,
                    min: 0.01,
                    max: 1.0,
                    tooltip: {
                      title: t('multiplier_tooltip'),
                    },
                  }}
                  placeholder={t('multiplier')}
                />
              </>
            }
            otherwise={null}
          />
          {!isSeller && (
            <AkinonButton htmlType="submit" type="primary" onClick={handleSubmit(onSubmit)}>
              {t('submit')}
            </AkinonButton>
          )}
        </AkinonForm>
      </Box>
    </AkinonSpin>
  );
};

export default OrderRejectionDeductionDetail;
