import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import i18n from '@root/i18n';
import { dateFormatter } from '@utils/index';
import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReconciliationStatusToColor } from '../ReconciliationListing/common';

const BoxItem = ({ label, children, isLoading }) => (
  <AkinonSpin spinning={isLoading}>
    <div className="card-box--item">
      <div className="card-box--header"> {label} </div>
      <div className="card-box--content"> {children} </div>
    </div>
  </AkinonSpin>
);

export function GeneralInfo({ reconciliation = {}, isReconciliationLoading }) {
  const { t } = useTranslation('Finance');

  const {
    name,
    status,
    total_income,
    total_expanse,
    commission,
    maturity,
    total_commission,
    start_date,
    end_date,
    datasource,
  } = reconciliation;

  const formattedTotalCommission = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
  }).format(total_commission);
  const formattedTotalIncome = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
  }).format(total_income);
  const formattedTotalExpanse = new Intl.NumberFormat(i18n.language, {
    minimumFractionDigits: 2,
  }).format(total_expanse);

  return (
    <>
      <Box className="box-primary reconciliation-main-info-row">
        <div className="reconciliation-main-info">
          <div>{`${t('reconciliation_info')}: ${name}${datasource?.name ?? ''}`}</div>
          <div className="reconciliation-status-tag">
            {status && (
              <Tag color={ReconciliationStatusToColor[status]}>
                {t(`reconciliation_status.${status}`)}
              </Tag>
            )}
          </div>
        </div>
      </Box>
      <div className="card-box">
        <BoxItem label={t('total_sales_count')} isLoading={isReconciliationLoading}>
          {total_income ? formattedTotalIncome : '-'}{' '}
        </BoxItem>
        <BoxItem label={t('total_expanse_count')} isLoading={isReconciliationLoading}>
          {total_expanse ? formattedTotalExpanse : '-'}{' '}
        </BoxItem>
        <BoxItem label={t('unified_commision_rate')} isLoading={isReconciliationLoading}>
          {commission ? `%${Number(commission)}` : '-'}{' '}
        </BoxItem>
        <BoxItem label={t('selling_commision')} isLoading={isReconciliationLoading}>
          {total_commission ? formattedTotalCommission : '-'}
        </BoxItem>
        <BoxItem label={t('unified_maturity')} isLoading={isReconciliationLoading}>
          {maturity ? maturity : '-'}
        </BoxItem>
        <BoxItem label={t('reconciliation_start_date')} isLoading={isReconciliationLoading}>
          {start_date ? dateFormatter(start_date) : '-'}
        </BoxItem>
        <BoxItem label={t('reconciliation_end_date')} isLoading={isReconciliationLoading}>
          {end_date ? dateFormatter(end_date) : '-'}
        </BoxItem>
      </div>
    </>
  );
}
