import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.productsAndCategories.bulkActions.offers,
        title: t('bulk.actions.offer'),
        description: t('bulk.actions.offer.desc'),
        icon: <i className="icon-toplu-islemler" />,
      },
    ],
    userRole,
    isProd,
  });
};
