import { DatasourcePermissionLevel } from './commontypes';
import { RouteUrls } from './routeUrls';

export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const REQUEST_AUTHORIZATION_HEADER = 'Bearer';

export const HttpStatus = {
  PERMANENT_REDIRECT: 301,
  TEMPORARY_REDIRECT: 302,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
};

export const UserRole = {
  SUPER_USER: 'super_user',
  SELLER: 'seller',
  GUEST: 'guest',
};

export const urlUserRoleMap = {
  [RouteUrls.productsAndCategories.bulkActions.landing]: UserRole.SELLER,
  [RouteUrls.settings.sellerUserManagementSettings]: UserRole.SELLER,
  [RouteUrls.settings.dynamicSettings]: UserRole.SUPER_USER,
  [RouteUrls.settings.userManagement]: UserRole.SUPER_USER,
  [RouteUrls.settings.sellerOfferRejectReasons]: UserRole.SUPER_USER,
  [RouteUrls.settings.attributeSetAndCategoryMapping]: UserRole.SUPER_USER,
  [RouteUrls.settings.buyboxRules]: UserRole.SUPER_USER,
  [RouteUrls.productsAndCategories.products.productForm]: UserRole.SELLER,
  [RouteUrls.support.supportTicketForm]: UserRole.SELLER,
  [RouteUrls.reporting.sellerPerformance]: UserRole.SUPER_USER,
  [RouteUrls.settings.accessTokens]: UserRole.SELLER,
  [RouteUrls.settings.integrationErrors]: UserRole.SUPER_USER,
  [RouteUrls.settings.ssoLoginSettingsUrl]: UserRole.SUPER_USER,
  [RouteUrls.settings.ssoLoginSettingsFormUrl]: UserRole.SUPER_USER,
  [RouteUrls.finance.sellerManagement.sellerDetail]: UserRole.SUPER_USER,
  [RouteUrls.notificationManagement.landing]: UserRole.SUPER_USER,
  [RouteUrls.finance.agreements]: UserRole.SUPER_USER,
  [RouteUrls.stockLocations.landing]: UserRole.SELLER,
  [RouteUrls.stockLocations.create]: UserRole.SELLER,
  [RouteUrls.stockLocations.detail]: UserRole.SELLER,
  [RouteUrls.finance.sellerManagement.permissions]: UserRole.SUPER_USER,
  [RouteUrls.finance.sellerManagement.deliverySettings]: UserRole.SUPER_USER,
  [RouteUrls.finance.sellerPerformanceMetrics.landing]: UserRole.SUPER_USER,
  [RouteUrls.settings.orderRejectionDeductions]: UserRole.SUPER_USER,
  [RouteUrls.settings.orderRejectionDeductionDetail]: UserRole.SUPER_USER,
};

export const urlPermissionLevelMap = {
  [RouteUrls.settings.sellerUserManagementSettings]: DatasourcePermissionLevel.ADMIN,
  [RouteUrls.settings.sellerUserManagementSettingsForm]: DatasourcePermissionLevel.ADMIN,
  [RouteUrls.settings.accessTokens]: DatasourcePermissionLevel.ADMIN,
};

export const directoriesVisibleInProd = [
  RouteUrls.productsAndCategories.landing,
  RouteUrls.order.landing,
  RouteUrls.finance.landing,
  RouteUrls.settings.landing,
  RouteUrls.messages.landing,
  RouteUrls.support.landing,
  RouteUrls.marketing.landing,
  RouteUrls.notificationManagement.landing,
  RouteUrls.appShell.landing,
  RouteUrls.stockLocations.landing,
];
export const pathsVisibleInProd = [
  RouteUrls.profile.activeUser,
  RouteUrls.download.landing,
  RouteUrls.dashboard.landing,
  RouteUrls.settings.accessTokens,
  RouteUrls.sellerStoreManagement.landing,
  RouteUrls.importExportOperations.landing,
  RouteUrls.reporting.salesReports,
];
export const pathsHiddenInProd = [RouteUrls.finance.dashboard, RouteUrls.messages.supportTickets];
export const directoriesHiddenInProd = [];

export const externalUrls = [RouteUrls.download.export];
