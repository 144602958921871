import { createNavigationItems, getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import {
  IconCategory,
  IconFiles,
  IconInfoSquareRounded,
  IconLock,
  IconTruckDelivery,
} from '@tabler/icons-react';
import { generatePath } from 'react-router-dom';

export const getNavigationItems = ({ t, user, isProd, id }) => {
  const userRole = getUserRole({ user });
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return createNavigationItems({
    navigationItems: [
      {
        key: RouteUrls.finance.sellerManagement.generalInfo,
        to: generatePath(RouteUrls.finance.sellerManagement.generalInfo, { id }),
        title: t('general_information'),
        description: t('general_information_description'),
        icon: <IconInfoSquareRounded size={32} />,
      },
      {
        key: RouteUrls.finance.sellerManagement.documents,
        to: generatePath(RouteUrls.finance.sellerManagement.documents, { id }),
        title: isSuperUser ? t('documents') : t('documents_seller'),
        description: isSuperUser ? t('documents_description') : t('documents_description_seller'),
        icon: <IconFiles size={32} />,
      },
      {
        key: RouteUrls.finance.sellerManagement.deliverySettings,
        to: generatePath(RouteUrls.finance.sellerManagement.deliverySettings, { id }),
        title: t('delivery_settings.title'),
        description: t('delivery_settings.description'),
        icon: <IconTruckDelivery size={32} />,
      },
      {
        key: RouteUrls.finance.sellerManagement.permissions,
        to: generatePath(RouteUrls.finance.sellerManagement.permissions, { id }),
        title: t('permissions'),
        description: t('permissions_description'),
        icon: <IconLock size={32} />,
      },
      {
        key: RouteUrls.finance.sellerManagement.commissions,
        to: generatePath(RouteUrls.finance.sellerManagement.commissions, { id }),
        title: t('commissions'),
        description: t('commissions_description'),
        icon: <IconCategory size={32} />,
      },
    ],
    userRole,
    isProd,
  });
};
