import { openNotification } from '@components/AkinonNotification';
import { getPackagesUrl } from '@constants/apiUrls';
import { getPackageStatuses } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { T } from '@utils/languageProvider';

/**
 * @typedef {import('@tanstack/react-query').UseMutationResult} UseMutationResult
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */

export const usePackageStatusesMutation = () => {
  const mutationKey = ['package-statuses'];

  const mutationFn = () => getPackageStatuses(getPackagesUrl);

  const { data, isLoading, mutate, error, ...rest } = useAkinonMutation({
    mutationKey,
    mutationFn,
    //NOSONAR
    onError: (error) => {
      const message = error?.response?.data?.detail || error.message;

      openNotification({
        message: T('error'),
        description: message,
        type: 'error',
      });
    },
  });

  return {
    ...rest,
    data,
    isLoading,
    mutate,
    error,
  };
};
