import './index.scss';

import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useDynamicFilterFields } from '@utils/hooks/useDynamicFilterFields';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import StandardAkinonTable from './components/StandardAkinonTable';
import StandardAkinonTableFooter from './components/StandardAkinonTableFooter';
import StandardAkinonTableHeader from './components/StandardAkinonTableHeader';
import useColumns from './hooks/useColumns';
import useDisplayedColumns from './hooks/useDisplayedColumns';
/**
 * @param {import('antd').TableProps & { tableActions: import('./common').TableAction[], total: number, title: string, description: string, shouldShowTableActionsInFooter: boolean, pagination: import('antd').PaginationProps, setPagination: (pagination) => void, pageSizeLabel: string}} props
 */
const AkinonTable = ({
  title,
  description,
  tableActions,
  shouldShowTableActionsInFooter,
  pagination,
  setPagination,
  total = 0,
  pageSizeLabel,
  exportOptions,
  exportFormats,
  appendDynamicColumnsAt,
  enableDynamicColumns,
  onExport,
  tableHeaderExtra,
  optionsUrl,
  ...tableProps
}) => {
  const modifiedDescription = tableProps?.loading ? '' : description;
  const { attributes, isAttributesLoading } = useAttributesQuery({
    queryOptions: {
      enabled: Boolean(enableDynamicColumns),
    },
  });

  const dynamicFilter = useDynamicFilterFields({ attributes, isAttributesLoading });
  const { columns } = useColumns({
    columns: tableProps?.columns,
    dynamicFilterFields: dynamicFilter.fields,
    appendDynamicColumnsAt,
    enableDynamicColumns,
    optionsUrl,
  });
  const { displayedColumns, columnDisplayStatuses, setColumnDisplayStatus } = useDisplayedColumns({
    columns,
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRowKeys = useMemo(
    () =>
      tableProps.rowSelection?.selectedRowKeys ?? selectedRows.map((row) => row[tableProps.rowKey]),
    [selectedRows, tableProps.rowKey, tableProps.rowSelection?.selectedRowKeys]
  );

  const rowSelection = tableProps.rowSelection && {
    ...tableProps.rowSelection,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      tableProps.rowSelection?.onChange?.(selectedRowKeys, selectedRows);
      setSelectedRows(selectedRows);
    },
  };
  const maxPossiblePage = total === 0 ? 0 : parseInt(total / Number(pagination?.pageSize)) + 1;

  const modifiedPagination = pagination
    ? {
        ...pagination,
        total: total,
        current: Math.min(pagination?.current, maxPossiblePage),
      }
    : pagination;

  const modifiedOnRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        if (tableProps?.rowSelection && event.target.closest('td.ant-table-selection-column')) {
          event.stopPropagation();
        } else {
          tableProps?.onRow?.(record, rowIndex)?.onClick?.(event);
        }
      },
    };
  };

  return (
    <StandardAkinonTable
      tableProps={{
        ...tableProps,
        columns: displayedColumns,
        pagination: modifiedPagination,
        rowSelection,
        onRow: modifiedOnRow,
      }}
      header={
        <StandardAkinonTableHeader
          title={title}
          description={modifiedDescription}
          pagination={modifiedPagination}
          setPagination={pagination ? setPagination : null}
          tableActions={tableActions}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          pageSizeLabel={pageSizeLabel}
          exportFormats={exportFormats}
          columns={columns}
          columnDisplayStatuses={columnDisplayStatuses}
          setColumnDisplayStatus={setColumnDisplayStatus}
          exportOptions={exportOptions}
          onExport={onExport}
          tableHeaderExtra={tableHeaderExtra}
        />
      }
      footer={
        <StandardAkinonTableFooter
          pagination={modifiedPagination}
          setPagination={pagination ? setPagination : null}
          tableActions={tableActions}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          shouldShowTableActionsInFooter={shouldShowTableActionsInFooter}
          pageSizeLabel={pageSizeLabel}
        />
      }
    />
  );
};

AkinonTable.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tableActions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isSelectionRequired: PropTypes.bool,
      actionHandler: PropTypes.func.isRequired,
    })
  ),
  shouldShowTableActionsInFooter: PropTypes.bool,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  total: PropTypes.number,
  pageSizeLabel: PropTypes.string,
};

AkinonTable.disabledRowClassName = 'standard-akinon-table__row--disabled';

AkinonTable.defaultProps = {
  tableActions: [],
  shouldShowTableActionsInFooter: false,
};

export default AkinonTable;
