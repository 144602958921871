import { urlUserRoleMap, UserRole } from '@constants/auth';
import { isPathAllowedInProd } from '@layouts/AppLayout/common';
import store from '@zustand-store/index';
import first from 'lodash/first';
import compose from 'lodash/fp/compose';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

export const getAuthObjects = () => store.getState().tokens;

export const addAuthObject = (tokenMeta) => {
  const addToken = store.getState().addToken;
  addToken(tokenMeta);
};

export const getActiveAuthObject = compose(first, getAuthObjects);

export const updateAuthObject = (access, tokenMeta) => {
  const updateToken = store.getState().updateToken;
  updateToken(access, tokenMeta);
};

export const removeAuthObject = (access) => {
  const removeToken = store.getState().removeToken;
  removeToken(access);
};

export const resetAuthObjects = () => {
  const resetTokens = store.getState().resetTokens;
  resetTokens();
};

export const getUserRole = ({ user }) => {
  if (isEmpty(user)) return UserRole.GUEST;

  return user.is_superuser ? UserRole.SUPER_USER : UserRole.SELLER;
};

export const hasRequiredPermissionLevel = ({ permissionLevel, requiredPermissionLevel }) => {
  return isUndefined(requiredPermissionLevel) || requiredPermissionLevel === permissionLevel;
};

export const isRoleAuthorized = ({ role, requiredRole }) => {
  return isUndefined(requiredRole) || requiredRole === role;
};

/**
 * @param {{ navigationItems: import('@components/Navigation/List').NavigationListItem[], userRole: string, isProd: boolean }} args
 * @returns {import('@components/Navigation/List').NavigationListItem[]}
 */
export const createNavigationItems = ({ navigationItems, userRole, isProd }) => {
  if (isUndefined(isProd)) throw new Error('isProd is required');
  const isDev = !isProd;

  return navigationItems.map((navigationItem) => ({
    ...navigationItem,
    isVisible:
      (navigationItem.isVisible ?? true) &&
      isRoleAuthorized({
        role: userRole,
        requiredRole: urlUserRoleMap[navigationItem.key ?? navigationItem.to],
      }) &&
      (isDev || isPathAllowedInProd(navigationItem.to)),
  }));
};
