import { getStaticFilters } from '../common';

const useFilterFields = ({ t, userRole, disabledStartDate, disabledEndDate }) => {
  const staticFields = getStaticFilters({
    t,
    userRole,
    disabledStartDate,
    disabledEndDate,
  });

  return {
    dynamic: { fields: [], isLoading: false },
    static: staticFields,
  };
};

export default useFilterFields;
