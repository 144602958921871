import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import { AkinonRadio } from '@components/AkinonRadio';
import AkinonSelect from '@components/AkinonSelect';
import AsyncSelect from '@components/AkinonSelect/AsyncSelect';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import { getDataSourcesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import useImageDimensionsDataSourceQuery from '@services/api/hooks/useImageDimensionsDataSourceQuery';
import { useNotificationQuery } from '@services/api/hooks/useNotificationQuery';
import { usePostNotificationAttachmentMutation } from '@services/api/hooks/usePostNotificationAttachmentMutation';
import { usePostNotificationAudienceMutation } from '@services/api/hooks/usePostNotificationAudienceMutation';
import { usePostNotificationMutation } from '@services/api/hooks/usePostNotificationMutation';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  DeliveryType,
  FormKey,
  getDeliveryTypeOptions,
  getFormSchema,
  getVisibilityOptions,
  SchemaKey,
  VisibilityType,
} from './common';
import PopupImageUpload from './components/PopupImageUpload';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const NotificationsForm = () => {
  const { t, i18n } = useTranslation(TranslationKey.NOTIFICATION_MANAGEMENT);
  const { id: notificationId } = useParams();
  const { notificationDetail, isFetchingNotificationDetail } = useNotificationQuery({
    notificationId,
  });
  const { postNotification, isPostingNotification } = usePostNotificationMutation();
  const { postNotificationAudience } = usePostNotificationAudienceMutation({});
  const { postNotificationAttachment } = usePostNotificationAttachmentMutation({});

  const navigate = useAppNavigate();

  const { dimensionBounds } = useImageDimensionsDataSourceQuery();
  const schema = getFormSchema({ t, dimensionBounds });

  const { control, handleSubmit, watch, reset, formState } = useForm({
    resolver: zodResolver(schema),
    disabled: Boolean(notificationId),
  });
  useBreadcrumbs();
  usePageMeta();

  const initialAttachments =
    notificationDetail?.attachments?.map((image) => {
      return {
        url: image?.file,
        uuid: image?.file,
      };
    }) ?? [];

  useEffect(() => {
    if (notificationId && notificationDetail) {
      reset({
        ...notificationDetail,
        [FormKey.AUDIENCES]: notificationDetail?.datasources,
        [FormKey.ATTACHMENTS]: initialAttachments,
        [FormKey.DELIVERY_TYPE]: notificationDetail?.[SchemaKey.DELIVERY_TYPE],
      });
    }
  }, [notificationId, notificationDetail]);

  const deliveryType = watch(FormKey.DELIVERY_TYPE);
  const attachments = watch(FormKey.ATTACHMENTS);
  const visibilityType = watch(FormKey.VISIBILITY);
  const shouldShowAttachment =
    deliveryType === DeliveryType.POPUP ||
    notificationDetail?.[FormKey.DELIVERY_TYPE] === DeliveryType.POPUP;
  const shouldShowAudiences = visibilityType === VisibilityType.PRIVATE;

  const onSubmit = (formValues) => {
    const requestConfig = {
      successMessage: t('transaction_success'),
      successDescription: t('notification_management_form_message.success'),
      errorMessage: t('transaction_failed'),
      errorDescription: t('notification_management_form_message.error'),
    };

    postNotification(
      {
        requestBody: {
          ntype: get(formValues, FormKey.DELIVERY_TYPE),
          title: get(formValues, FormKey.TITLE),
          message: get(formValues, FormKey.MESSAGE),
          visibility: get(formValues, FormKey.VISIBILITY),
        },
        requestConfig: {
          errorMessage: t('transaction_failed'),
          errorDescription: t('notification_management_form_message.error'),
        },
      },
      {
        onSuccess: (result) => {
          shouldShowAudiences &&
            postNotificationAudience({
              requestBody: {
                datasources: formValues[FormKey.AUDIENCES],
              },
              requestConfig:
                attachments?.length === 0
                  ? requestConfig
                  : {
                      errorMessage: t('transaction_failed'),
                      errorDescription: t('notification_management_form_message.error'),
                    },
              notificationId: result.id,
            });
          if (attachments?.length > 0) {
            const requestBody = new FormData();
            attachments.forEach((file) => {
              requestBody.append(SchemaKey.ATTACHMENTS, file.originFileObj);
            });
            postNotificationAttachment({
              requestBody: requestBody,
              requestConfig,
              notificationId: result.id,
            });
          }
          navigate(RouteUrls.notificationManagement.landing);
        },
      }
    );
  };

  const deliveryTypeOptions = getDeliveryTypeOptions({ t });
  const visibilityOptions = getVisibilityOptions({ t });

  return (
    <AkinonSpin spinning={Boolean(notificationId && isFetchingNotificationDetail)}>
      <section className="notifications-form">
        <Box className="box-primary form-box">
          <div className="notifications-form__title">{t('notification_detail')}</div>
          <AkinonForm layout="vertical">
            <AkinonSelect
              options={visibilityOptions}
              optionFilterProp="label"
              showSearch
              placeholder={t('visibility')}
              formItemProps={{
                name: FormKey.VISIBILITY,
                label: t('visibility'),
                control: control,
                required: true,
              }}
            />
            {shouldShowAudiences && (
              <AkinonFormItem
                name={FormKey.AUDIENCES}
                label={t('target_audience')}
                control={control}
                required
              >
                <AsyncSelect
                  mode="multiple"
                  labelInValue={Boolean(notificationId)}
                  queryProps={{
                    remoteKey: QueryKey.DATA_SOURCES,
                    remoteUrl: getDataSourcesUrl,
                    params: {
                      limit: 20,
                      sort: 'name',
                      is_active: true,
                    },
                    searchKey: 'name__icontains',
                  }}
                  showSearch
                  placeholder={t('target_audience')}
                />
              </AkinonFormItem>
            )}
            <AkinonFormItem
              name={FormKey.TITLE}
              label={t('message_title')}
              control={control}
              required
            >
              <AkinonInput placeholder={t('message_title')} />
            </AkinonFormItem>
            <AkinonFormItem name={FormKey.MESSAGE} label={t('message')} control={control} required>
              <AkinonTextArea placeholder={t('message')} />
            </AkinonFormItem>
            <AkinonFormItem
              name={FormKey.DELIVERY_TYPE}
              label={t('delivery_type')}
              control={control}
              required
            >
              <AkinonRadio options={deliveryTypeOptions} />
            </AkinonFormItem>
            {shouldShowAttachment && (
              <AkinonFormItem
                name={FormKey.ATTACHMENTS}
                label={t('popup_attachment')}
                control={control}
                required
                help={attachments?.length > 0 ? () => null : true}
              >
                <PopupImageUpload
                  multiple
                  itemRender={() => null}
                  placeholder={t('popup_attachment')}
                  fileList={attachments ?? initialAttachments}
                  errors={formState.errors?.delivery?.attachments?.message}
                />
              </AkinonFormItem>
            )}
            <div className="notifications-form__footer">
              <AkinonFormItem>
                <AkinonButton
                  htmlType="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={Boolean(notificationId) || isPostingNotification}
                >
                  {t('send').toLocaleUpperCase(i18n.language)}
                </AkinonButton>
              </AkinonFormItem>
            </div>
          </AkinonForm>
        </Box>
      </section>
    </AkinonSpin>
  );
};

export default NotificationsForm;
