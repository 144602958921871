import { client } from '@services/api/client';
import { safeParseJSON } from '@utils/index';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import isInteger from 'lodash/isInteger';
import isNil from 'lodash/isNil';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import stubTrue from 'lodash/stubTrue';
import upperFirst from 'lodash/upperFirst';
/**
 * @deprecated Don't use this. Use React Query instead with server instance.
 */
export const sendRequest = (config) => client(config);

export const transformApiErrorToErrorMessages = (error) => {
  const parsedError = safeParseJSON(error.request.response);
  const isApiErrorArray = Array.isArray(parsedError);
  const isApiErrorObject = isPlainObject(parsedError);
  const isApiErrorString = isString(parsedError);
  const isApiErrorNil = isNil(parsedError);
  const baseApiError = isApiErrorArray
    ? parsedError
    : isApiErrorObject
      ? omit(parsedError, ['code'])
      : isApiErrorString || isApiErrorNil
        ? {
            [error.request.status]: error.request.statusText || error.message,
          }
        : parsedError;

  const getFieldErrors = ({ errorLayer, layerKey, fieldErrors = [] }) => {
    if (Array.isArray(errorLayer) && !isPlainObject(errorLayer[0])) {
      fieldErrors.push(
        ...errorLayer.map((errorMessage) => `${upperFirst(layerKey)}: ${errorMessage}`)
      );
    } else if (isString(errorLayer)) {
      fieldErrors.push(`${upperFirst(layerKey)}: ${errorLayer}`);
    } else {
      Object.entries(errorLayer ?? {}).forEach(([key, value]) => {
        getFieldErrors({
          errorLayer: value,
          fieldErrors,
          layerKey: isInteger(parseInt(key)) ? layerKey : layerKey ? `${layerKey}.${key}` : key,
        });
      });
    }

    return fieldErrors;
  };

  const parseDetailField = (detail) => {
    if (isString(detail)) {
      const matches = detail.match(/message=\{(.+?)\}/);
      if (matches) {
        const messageContent = safeParseJSON(`{${matches[1]}}`);

        return messageContent || detail;
      }
    }
    return detail;
  };

  const apiErrorMessages = cond([
    [() => baseApiError?.detail, () => [parseDetailField(baseApiError?.detail)]],
    [() => baseApiError?.non_field_errors, constant(baseApiError?.non_field_errors)],
    [() => isApiErrorArray, constant(baseApiError)],
    [
      stubTrue,
      () =>
        getFieldErrors({
          errorLayer: baseApiError,
          layerKey: isApiErrorString || isApiErrorNil ? error.request.status : undefined,
        }),
    ],
  ])();

  return {
    errorCode: parsedError?.code,
    apiErrorMessages: isApiErrorArray ? apiErrorMessages : [apiErrorMessages],
  };
};
