import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { useSalesReportsQuery } from '@services/api/hooks/useSalesReportsQuery';
import { PATH_KEY_LENGTH } from '@utils/index';
import { Divider } from 'antd';
import { last, pick, values } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImmer } from 'use-immer';

import useFilterFields from '../hooks/useFilterFields';
import { getCategoryTableColumns } from './common';

export default function CategoryBasedTable({ params, reportType }) {
  const { t } = useTranslation('Reporting');
  const [pagination, setPagination] = usePagination();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [dataSourceTree, setDataSourceTree] = useImmer({});
  const filters = usePageFilters();
  const { report: categoriesReport, isReportFetching: isCategoriesReportFetching } =
    useSalesReportsQuery({
      params: {
        ...params,
        type: 'category',
        path: last(expandedRowKeys),
      },
      filters,
    });
  const categoriesReportDataSource =
    categoriesReport?.results?.map?.((row) => {
      return { ...row };
    }) ?? [];

  const filterFields = useFilterFields({
    t,
    showSkuFilter: false,
    showBaseCodeFilter: false,
  });

  useEffect(() => {
    if (!last(expandedRowKeys)) {
      setDataSourceTree((draft) => {
        draft['root'] = categoriesReportDataSource;
      });
    }
    setDataSourceTree((draft) => {
      categoriesReportDataSource?.forEach((dsRow) => {
        draft[dsRow.path] = [dsRow];
      });
    });
  }, [categoriesReport]);

  const total = dataSourceTree?.root?.length || 0;
  const columns = getCategoryTableColumns({
    t,
    expandedRowKeys,
    setExpandedRowKeys,
    key: 'category',
    reportType,
  });

  useEffect(() => {
    return () => {
      setExpandedRowKeys([]);
    };
  }, []);

  const getExpandable = ({ level = 0 }) => ({
    expandedRowKeys,
    showExpandColumn: false,
    expandedRowClassName: () => `expanded-row expanded-row-${level}`,
    expandedRowRender: (record) => {
      const subDataSourceKeys = Object.keys(dataSourceTree).filter((key) => {
        return (
          key !== record.path &&
          key.startsWith(record.path) &&
          key.length === record.path.length + PATH_KEY_LENGTH
        );
      });
      const subDataSource = values(pick(dataSourceTree, subDataSourceKeys)).flat();

      if (!subDataSource?.length && !isCategoriesReportFetching) return t('data.not.found');

      const subColumns = getCategoryTableColumns({
        t,
        expandedRowKeys,
        setExpandedRowKeys,
        key: 'path',
      });

      return (
        <AkinonTable
          style={{ marginLeft: 20, marginTop: 0 }}
          rowKey="path"
          className="expanded-table"
          dataSource={subDataSource}
          columns={subColumns}
          pagination={false}
          expandable={getExpandable({ level: level + 1 })}
        />
      );
    },
  });

  return (
    <div>
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <Divider />
      <AkinonTable
        title={t('category_based')}
        columns={columns}
        dataSource={dataSourceTree?.root}
        pagination={pagination}
        setPagination={setPagination}
        loading={isCategoriesReportFetching}
        rowKey="path"
        total={total}
        description={`${total} ${t('results.found')}`}
        expandable={getExpandable({ level: 0 })}
      />
    </div>
  );
}
