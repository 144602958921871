import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';

import { ReportType } from '../common';

export const getCategoryTableColumns = ({
  t,
  expandedRowKeys,
  setExpandedRowKeys,
  key = 'base_code',
  reportType,
}) => {
  return [
    {
      title: key === 'path' ? t('sale_reports.subcategory') : t('sale_reports.category'),
      dataIndex: 'name',
      key: 'name',
      render: (category, record) => {
        const expanded = expandedRowKeys.includes(record.path);
        return (
          <div className="flex gap-2">
            {expanded ? (
              <IconChevronDown
                onClick={() => {
                  setExpandedRowKeys((prev) => prev.filter((path) => path !== record.path));
                }}
                className="cursor-pointer text-info"
              />
            ) : (
              <IconChevronRight
                onClick={() => {
                  setExpandedRowKeys((prev) => [...prev, record.path]);
                }}
                className="cursor-pointer text-info"
              />
            )}
            <span>{category}</span>
          </div>
        );
      },
    },
    key === 'path' && {
      title: t('sale_reports.total_orders_involving_this_subcategory'),
      dataIndex: 'involved_order_count',
    },
    {
      title: t('sale_reports.sales_quantity'),
      dataIndex: 'sales_quantity',
      key: 'sales_quantity',
    },
    {
      title: t('sale_reports.sales_amount'),
      dataIndex: 'sales_amount',
      key: 'sales_amount',
    },
    {
      title: t('sale_reports.commission'),
      dataIndex: 'commission_amount',
      key: 'commission_amount',
      render: (commission) => (commission ? commission : '-'),
    },
  ].filter(Boolean);
};

export const getProductsTableColumns = ({
  t,
  expandedRowKeys,
  setExpandedRowKeys,
  key = 'base_code',
  isExpendable = true,
  title = 'base_code',
  reportType,
}) => {
  const salesQuantityTMap = {
    [ReportType.SALES]: 'sale_reports.sold_quantity',
    [ReportType.REFUND]: 'sale_reports.refund_quantity',
    [ReportType.CANCEL]: 'sale_reports.cancel_quantity',
  };
  const salesAmountTMap = {
    [ReportType.SALES]: 'sale_reports.total_order_amount',
    [ReportType.REFUND]: 'sale_reports.total_refund_amount',
    [ReportType.CANCEL]: 'sale_reports.total_cancel_amount',
  };

  return [
    {
      title: t(title),
      dataIndex: key,
      key,
      render: (category, record) => {
        const expanded = expandedRowKeys.includes(record[key]);
        return (
          <div className="flex gap-2">
            {isExpendable && expanded ? (
              <IconChevronDown
                onClick={() => {
                  setExpandedRowKeys((prev) => prev.filter((rowKey) => rowKey !== record[key]));
                }}
                className="cursor-pointer text-info"
              />
            ) : (
              isExpendable && (
                <IconChevronRight
                  onClick={() => {
                    setExpandedRowKeys((prev) => [...prev, record[key]]);
                  }}
                  className="cursor-pointer text-info"
                />
              )
            )}
            <span>{category}</span>
          </div>
        );
      },
    },
    key === 'sku' && {
      title: t('sale_reports.total_orders_involving_this_sku'),
      dataIndex: 'involved_order_count',
    },
    {
      title: t(salesQuantityTMap[reportType]),
      dataIndex: 'sales_quantity',
      key: 'sales_quantity',
    },
    {
      title: t(salesAmountTMap[reportType]),
      dataIndex: 'sales_amount',
      key: 'sales_amount',
    },
    {
      title: t('sale_reports.commission'),
      dataIndex: 'commission_amount',
      key: 'commission_amount',
      render: (commission) => (commission ? commission : '-'),
    },
  ].filter(Boolean);
};
