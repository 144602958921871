import './style.scss';

import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { defaultRowKey } from '@constants';
import { getPackageItemsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { OrderStatus, PackageStatus } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import PackagesDetailList from '@pages/Orders/Packages/PackageDetail/components/PackagesDetailList';
import { useTour } from '@reactour/tour';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { queryClient } from '@services/api/queryClient';
import { usePackageReCreateMutation } from '@services/hooks/packages/usePackageReCreateMutation';
import { usePatchPackageMutation } from '@services/hooks/packages/usePatchPackageMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Tag } from 'antd';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  getActionButtons,
  getCargoInfoModalConditionalFormMeta,
  getInvoiceInfoModalFormMeta,
  getPackageProductsColumns,
  getPackagesStatusRenderMap,
  getTourSteps,
  InvoiceDataType,
  invoiceInfoFormResolver,
  InvoiceInfoModalFormMetaKey,
  shippAllFormResolver,
} from './common';
import ChangeCargoCompanyModal from './components/ChangeCargoCompanyModal';
import ChangeLocationModal from './components/ChangeLocationModal';
import ModalWithForm from './components/ModalWithForm';
import ProductInfoDrawer from './components/ProductInfoDrawer';
import { useDataSource, usePackageInfo, useShippingDetail, useShippingOptionList } from './hooks';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';
import { useProductDetail } from './hooks/useProductDetail';

export default function PackageDetail() {
  const { t } = useTranslation('Orders');
  const navigate = useAppNavigate();
  const { packageId } = useParams();
  const filters = usePageFilters();
  const [isCargoInfoModalVisible, setIsCargoInfoModalVisible] = useState(false);
  const [isChangeLocationModalVisible, setIsChangeLocationModalVisible] = useState(false);
  const [isShipAllModalVisible, setIsShipAllModalVisible] = useState(false);
  const [isInvoiceInfoModalVisible, setIsInvoiceInfoModalVisible] = useState(false);
  const [isProductInfoDrawerVisible, setIsProductInfoDrawerVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { setSteps } = useTour();

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const getProductClickHandler = (record) => () => {
    setSelectedProduct(record);
    setIsProductInfoDrawerVisible(true);
  };
  const packageProductsColumns = getPackageProductsColumns({ t, getProductClickHandler });

  const [packageItemsPagination, setPackageItemsPagination] = usePagination();
  const {
    packageDetail,
    packages,
    packageInfo,
    billingAddress,
    orderDetail,
    sellerDetail,
    refetchGenerateLabel,
    locationDetail,
    isPackageInfoLoading,
  } = usePackageInfo({
    t,
    packageId,
  });
  const { activeShippingOptionList, refetchShippingOptionList } = useShippingOptionList({
    isSuperUser,
  });
  const { shippingDetail } = useShippingDetail({ packageDetail });

  const { dataSource, total, isDataSourceLoading } = useDataSource({
    pagination: packageItemsPagination,
    filters,
    packageId,
    shippingDetail,
    packageDetail,
  });

  const { productDetail, isProductDetailLoading } = useProductDetail({
    product: selectedProduct,
    shouldFetch: isProductInfoDrawerVisible,
  });

  const { mutate: patchPackageMutate, mutateAsync: patchPackageMutateAsync } =
    usePatchPackageMutation({
      packageId,
    });
  const { mutate: packageReCreateMutate } = usePackageReCreateMutation({
    onSuccess: () => {
      navigate(RouteUrls.order.packages.packageList);
    },
  });

  const onShipAllHandler = (params) => {
    const { cargo_tracking_number, cargo_tracking_url, shipping_company } = params;
    patchPackageMutateAsync({
      params: {
        status: PackageStatus.READY,
        tracking_number: cargo_tracking_number,
        tracking_url: cargo_tracking_url,
        shipping_company,
      },
    }).then(() => {
      patchPackageMutateAsync(
        {
          params: {
            status: PackageStatus.SHIPPED,
            tracking_number: cargo_tracking_number,
            tracking_url: cargo_tracking_url,
            shipping_company,
          },
          requestConfig: { suppressedNotifications: ['error', 'success'] },
        },
        {
          onSuccess: (response) => {
            openNotification({
              message: t('success'),
              description: t('ship_all_success_message'),
            });
            queryClient.invalidateQueries([QueryKey.PACKAGE_DETAIL, packageId]);
            queryClient.refetchQueries({
              queryKey: [
                QueryKey.SHIPPING_OPTION_DETAIL,
                { shippingMethodId: response?.shipping_method },
              ],
            });
          },
          onError() {
            openNotification({
              type: 'error',
              message: t('error'),
              description: t('ship_all_error_message'),
            });
          },
        }
      );
    });
  };

  const onCreatePackage = (selectedOrderItemKeys) => {
    packageReCreateMutate({
      order: orderDetail?.id,
      order_items: selectedOrderItemKeys,
      shipping_method: packageDetail?.shipping_method,
      requestConfig: {
        successMessage: t('transaction_success'),
        successDescription: t('package_detail_message.success'),
        errorMessage: t('transaction_failed'),
        errorDescription: t('package_detail_message.error'),
      },
    });
  };

  usePageMeta({
    packageStatus: packageDetail?.status,
    patchPackageMutate,
    refetchShippingOptionList,
    setIsCargoInfoModalVisible,
    setIsChangeLocationModalVisible,
    packageDetail,
    onShipAllHandler,
    shippingDetail,
    setIsShipAllModalVisible,
    setIsInvoiceInfoModalVisible,
    refetchGenerateLabel,
  });
  useBreadcrumbs();

  const isRowDisabled = (record) => record.status === OrderStatus.CANCELLED;
  const rowSelection = {
    getCheckboxProps: (record) => {
      return {
        disabled: isRowDisabled(record),
        name: record.name,
      };
    },
  };

  const actionButtons = getActionButtons({
    t,
    packageDetail,
    onCreatePackage,
  });

  const isActionButtonsVisible = !isEmpty(actionButtons);
  useEffect(() => {
    setSteps(
      getTourSteps({
        t,
        isActionButtonsVisible,
      })
    );
  }, [t, isActionButtonsVisible]);

  const packageStatusRenderMap = useMemo(() => {
    return getPackagesStatusRenderMap({ t });
  }, [t]);

  return (
    <section className="package-detail">
      <AkinonSpin spinning={isPackageInfoLoading}>
        <Box className="reconciliation-main-info-row package-detail-title">
          <div className="reconciliation-main-info">
            <div>
              {t('packaging_no')}: {packageDetail?.number}
            </div>
            <div className="reconciliation-status-tag">
              <Tag color={packageStatusRenderMap[packageDetail?.status]?.tagColor}>
                {packageStatusRenderMap[packageDetail?.status]?.label}
              </Tag>
            </div>
          </div>
          <Show when={isSuperUser}>
            <div className="reconciliation-main-info">
              <div>
                {t('seller')}: {sellerDetail?.name ?? ''}
              </div>
            </div>
          </Show>
        </Box>
      </AkinonSpin>

      {packageDetail?.number && (
        <PackagesDetailList
          t={t}
          packageDetail={packageDetail}
          billingAddress={billingAddress}
          shippingMethod={shippingDetail?.shipping_method}
          shippingCompany={shippingDetail?.shipping_company}
          orderDetail={orderDetail}
          packages={packages}
          packageInfo={packageInfo}
          locationDetail={locationDetail}
          isPackageInfoLoading={isPackageInfoLoading}
        />
      )}

      <ProductInfoDrawer
        open={isProductInfoDrawerVisible}
        onClose={() => setIsProductInfoDrawerVisible(false)}
        productDetail={productDetail}
        isProductDetailLoading={isProductDetailLoading}
        selectedProduct={selectedProduct}
      />

      <Box className="akinon-datatable">
        <AkinonTable
          title={t('package_items')}
          id="package-items-table"
          columns={packageProductsColumns}
          dataSource={dataSource}
          optionsUrl={getPackageItemsUrl}
          loading={isDataSourceLoading}
          total={total}
          rowKey={defaultRowKey}
          rowSelection={packageDetail?.status !== PackageStatus.SHIPPED ? rowSelection : false}
          pagination={packageItemsPagination}
          setPagination={setPackageItemsPagination}
          description={`${total} ${t('results.found')}`}
          tableActions={actionButtons}
          rowClassName={(record) =>
            clsx({ [AkinonTable.disabledRowClassName]: isRowDisabled(record) })
          }
        />
      </Box>

      {!isEmpty(activeShippingOptionList) && (
        <ChangeCargoCompanyModal
          isVisible={isCargoInfoModalVisible}
          setIsVisible={setIsCargoInfoModalVisible}
          onCancel={() => setIsCargoInfoModalVisible(false)}
          patchPackageMutate={patchPackageMutate}
          shippingOptionList={activeShippingOptionList}
          activeShippingOptionList={activeShippingOptionList}
        />
      )}

      <ChangeLocationModal
        isVisible={isChangeLocationModalVisible}
        setIsVisible={setIsChangeLocationModalVisible}
        onCancel={() => setIsChangeLocationModalVisible(false)}
      />

      <ModalWithForm
        isModalVisible={isShipAllModalVisible}
        setModalVisible={setIsShipAllModalVisible}
        formResolver={shippAllFormResolver}
        title={t('ship_all')}
        onFinish={onShipAllHandler}
        onCancel={() => setIsShipAllModalVisible(false)}
        formMeta={() => ({
          fields: [...getCargoInfoModalConditionalFormMeta({ packageDetail, t })],
        })}
      />

      <ModalWithForm
        isModalVisible={isInvoiceInfoModalVisible}
        setModalVisible={setIsInvoiceInfoModalVisible}
        title={t('invoice_info')}
        formResolver={invoiceInfoFormResolver}
        onFinish={(formValues) => {
          patchPackageMutate(
            {
              params: {
                invoice_data: formValues[InvoiceInfoModalFormMetaKey.INVOICE_DATA],
                invoice_data_type: InvoiceDataType.URL,
                invoice_number: formValues[InvoiceInfoModalFormMetaKey.INVOICE_NUMBER],
                invoice_date: formValues[InvoiceInfoModalFormMetaKey.INVOICE_DATE],
                tracking_number: formValues[InvoiceInfoModalFormMetaKey.TRACKING_NUMBER],
                tracking_url: formValues[InvoiceInfoModalFormMetaKey.TRACKING_URL],
              },
            },
            {
              onSuccess() {
                openNotification({
                  message: t('success'),
                  description: t('enter_invoice_info_all_success_message'),
                });
              },
              onError() {
                openNotification({
                  type: 'error',
                  message: t('error'),
                  description: t('enter_invoice_info_all_error_message'),
                });
              },
            }
          );
        }}
        onCancel={() => setIsInvoiceInfoModalVisible(false)}
        formMeta={() => getInvoiceInfoModalFormMeta({ t })}
      />
    </section>
  );
}
