import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.productsAndCategories.products.productForm,
        title: t('add.single.product'),
        description: t('add.single.product.desc'),
        icon: <i className="icon-tekli-urun-ekle" />,
      },
      {
        to: RouteUrls.productsAndCategories.offerList.approved,
        title: t('approved_offers'),
        description: t('approved_offers_desc'),
        icon: <i className="icon-teklif-listesi" />,
      },
      {
        to: RouteUrls.productsAndCategories.offerList.pendingApproval,
        title: t('product.offer.waiting.confirmation'),
        description: t('product.offer.waiting.confirmation.desc'),
        icon: <i className="icon-teklif-listesi" />,
      },
      {
        to: RouteUrls.productsAndCategories.offerList.rejected,
        title: t('product.offer.declined'),
        description: t('product.offer.declined.desc'),
        icon: <i className="icon-teklif-listesi" />,
      },
    ],
    userRole,
    isProd,
  });
};
