import React from 'react';

import ChangePasswordForm from './components/ChangePasswordForm';
import LanguageSettings from './components/LanguageSettings';
import UserProfileForm from './components/UserProfileForm';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const Profile = () => {
  useBreadcrumbs();
  usePageMeta();

  return (
    <>
      <UserProfileForm />
      <ChangePasswordForm />
      <LanguageSettings />
    </>
  );
};

export default Profile;
