import { defaultDateFormat } from '@constants/index';
import { StatusText } from '@pages/Orders/common';
import moment from 'moment';

export const FormFieldKey = {
  QUICK_MESSAGE: 'quick_message',
  MESSAGE_CONTENT: 'message_content',
};

export const ContentType = {
  USER: 'user',
  DATASOURCE: 'datasource',
};

export const getOrderInformations = ({ t, conversation, order, product }) => {
  const { item_object, user } = conversation ?? {};
  const { date_placed, status } = order ?? {};

  return [
    {
      name: t('order.number'),
      value: item_object?.order_number,
    },
    {
      name: t('order.date'),
      value: moment(date_placed).format(defaultDateFormat),
    },
    {
      name: t('order.status'),
      value: StatusText({ t })?.[status],
    },
    {
      name: t('customer.name'),
      value: [user?.first_name, user?.last_name].join(' '),
    },
    {
      name: t('sku'),
      value: product?.sku,
    },
    {
      name: t('product_name'),
      value: product?.name,
    },
  ];
};
