import './styles.scss';

import { getRequestFiltersParams } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonFilter from '@components/AkinonFilter';
import { useFilterParams } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/AkinonNotification';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { getProductsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import usePageData from '@hooks/usePageData';
import { BulkActionProgressModal } from '@pages/Marketing/BasketOfferDetail/Collections/AddEditCollection/components/BulkActionProgressModal';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useGetProductBulkStateQuery } from '@services/api/hooks/useGetProductBulkStateQuery';
import { usePostFetchProductsMutation } from '@services/api/hooks/usePostFetchProductsMutation';
import { useSetBulkCategoryMutation } from '@services/api/hooks/useSetBulkCategoryMutation';
import { IconFilterUp } from '@tabler/icons-react';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useBoolean } from 'react-use';

import { getDynamicColumns } from '../common';
import { getColumns, getTableActions } from './common';
import BulkSearchProducts from './components/BulkSearchProducts';
import BulkSetCategoryModal from './components/BulkSetCategoryModal';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const ProductPool = () => {
  const { t, i18n } = useTranslation(TranslationKey.PRODUCTS_AND_CATEGORIES);
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const navigate = useAppNavigate();
  const { state } = useLocation();
  const [isBulkSearchProductActive, toggleIsBulkSearchProductActive] = useBoolean(
    state?.isBulkSearchProductActive
  );
  const [pagination, setPagination] = usePagination();
  const [isSetBulkCategoryModalVisible, setIsSetBulkCategoryModalVisible] = useBoolean(false);
  const { pageData } = usePageData();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const visibleFilters = pageData?.visibleFilters ?? [];
  usePageMeta();

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });

  const { attributes, isAttributesLoading } = useAttributesQuery();

  const filterFields = useFilterFields({
    t,
    attributes,
    categoryTreeData,
    isCategoriesLoading,
    isAttributesLoading,
  });

  const filters = useFilterParams({ filterFields });
  const {
    setBulkCategory,
    isSettingBulkCategory,
    setBulkCategoryCacheKey,
    isSettingBulkCategorySuccess,
    setBulkCategoryError,
    resetSetBulkCategory,
  } = useSetBulkCategoryMutation({
    filters,
    selectedRowKeys,
    mutationOptions: {
      onSuccess: () => refetchProducts(),
    },
  });

  const { status: setCategoryStatus, statusPercentage: setCategoryStatusPercentage } =
    useGetProductBulkStateQuery({
      cacheKey: setBulkCategoryCacheKey,
    });

  const hasFilter = Object.values(filters).some((filter) => filter !== undefined);

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const { dynamicFormMetaFields } = getDynamicColumns({
    visibleFilters,
    dynamicFilterFields: filterFields.dynamic.fields,
  });

  const { dataSource, total, isDataSourceLoading, refetchProducts } = useDataSource({
    pagination,
    filters,
    dynamicFormMetaFields,
  });

  const { isPostFetchingProducts, postFetchProducts } = usePostFetchProductsMutation({
    mutationOptions: {
      onSuccess: () => {
        refetchProducts();
      },
      onError: (error) => {
        openNotification({
          message: t('transaction_failed'),
          description:
            error.response?.data?.detail ?? error.response?.data?.non_field_errors ?? error.message,
          type: 'error',
        });
      },
    },
  });

  const columns = getColumns({ t, i18n, categoryTreeData });
  const tableActions = getTableActions({
    t,
    isSuperUser,
    onShowSetBulkCategoryModal: () => {
      setIsSetBulkCategoryModalVisible(true);
    },
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.products.detail, {
        productId: record.id,
      });
    },
  });

  const onGotoAddSingleProduct = () => {
    navigate(RouteUrls.productsAndCategories.products.productForm);
  };

  const handlePostFetchProducts = () => {
    if (!hasFilter) return;

    postFetchProducts({
      requestBody: {
        ...getRequestFiltersParams(filters),
      },
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <section className="product-pool">
      <BulkSetCategoryModal
        isVisible={isSetBulkCategoryModalVisible}
        onCancel={() => setIsSetBulkCategoryModalVisible(false)}
        onConfirm={(formValues) => setBulkCategory(formValues)}
        isLoading={isSettingBulkCategory}
        selectedRowKeys={selectedRowKeys}
      />
      <BulkActionProgressModal
        title={t('bulk_change_category')}
        open={isSettingBulkCategorySuccess && !setBulkCategoryError}
        onCancel={() => {
          resetSetBulkCategory();
          refetchProducts();
        }}
        progressStatus={setCategoryStatus}
        progressPercentage={setCategoryStatusPercentage}
        succeededCountTransKey="bulk_set_category_succeeded_count"
        failedCountTransKey="bulk_set_category_failed_count"
      />
      <Show when={isBulkSearchProductActive} animateProps={Animations.fade}>
        <BulkSearchProducts loading={isDataSourceLoading} />
      </Show>
      <AkinonFilter
        title={t('filters')}
        filterActions={
          <Tooltip title={t('search_bulk_product')}>
            <AkinonButton
              onClick={toggleIsBulkSearchProductActive}
              className="filter-options__export-item"
            >
              <IconFilterUp className="text-white" size={16} />
            </AkinonButton>
          </Tooltip>
        }
        filterFields={filterFields}
        total={total}
        actions={
          <Show when={isSuperUser}>
            <AkinonButton
              onClick={handlePostFetchProducts}
              disabled={!hasFilter || isDataSourceLoading || isPostFetchingProducts}
            >
              {t('omnitron_sync')}
            </AkinonButton>
          </Show>
        }
      />
      <AkinonTable
        locale={{
          ...(!isSuperUser && {
            emptyText: (
              <div className="product-pool__navigate-to-form-container">
                <AkinonButton onClick={onGotoAddSingleProduct}>
                  {t('add.single.product')}
                </AkinonButton>
              </div>
            ),
          }),
        }}
        loading={isDataSourceLoading}
        dataSource={dataSource}
        optionsUrl={getProductsUrl}
        total={total}
        columns={columns}
        appendDynamicColumnsAt={columns.length}
        enableDynamicColumns
        title={t('product.pool')}
        description={`${total} ${t('results.found')}`}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        tableActions={tableActions}
        onRow={onRow}
        rowSelection={rowSelection}
      />
    </section>
  );
};

export default ProductPool;
