import AkinonButton from '@components/AkinonButton';
import Box from '@components/utility/box';
import { Modal, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SubmitConfirmationModal = ({ isVisible, onCancel, onOk }) => {
  const { t, i18n } = useTranslation('ProductsAndCategories');

  return (
    <Modal
      open={isVisible}
      title={t('offer_submit_title')}
      className="offer-submit-modal"
      onCancel={onCancel}
      footer={null}
    >
      <Box className="box-primary form-box">
        <Typography.Text className="offer-submit-description">
          {t('offer_submit_confirmation')}
        </Typography.Text>
        <Space style={{ width: '100%', justifyContent: 'center' }}>
          <AkinonButton onClick={onOk} type="primary">
            {t('just_submit').toLocaleUpperCase(i18n.language)}
          </AkinonButton>
        </Space>
      </Box>
    </Modal>
  );
};

export default SubmitConfirmationModal;
