import './style.scss';

import If from '@components/If';
import Show from '@components/Show';
import useDocumentDownloadQuery from '@services/api/hooks/useDocumentDownloadQuery';
import { useExportQuery } from '@services/api/hooks/useExportQuery.js';
import { Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function Download() {
  const { t } = useTranslation('Download');
  const { cacheKey } = useParams();
  const { exportData, exportError, isExporting } = useExportQuery({ cacheKey });
  const [isDownloadSuccessTooltip, setDownloadSuccessTooltip] = useState(false);

  const [documentUrl, setDocumentUrl] = useState();
  const { isFetching: isDownloadMutating, error: downloadMutationError } = useDocumentDownloadQuery(
    {
      documentUrl: documentUrl,
      queryOptions: {
        enabled: exportData && Boolean(documentUrl),
      },
      onSettled: (data) => {
        setDocumentUrl(null);
        if (data?.url) {
          setDownloadSuccessTooltip(true);
          window.location.href = data?.url;
        }
      },
    }
  );

  useEffect(() => {
    if (exportData) {
      setDocumentUrl(`exports/${exportData?.id}/download`);
    }
  }, [exportData]);

  return (
    <section>
      <If
        condition={isExporting}
        then={
          <div>
            <span className="download-info-message">{t('download.page.info.unready')}</span>
            <Spin className="download-info-message__spinner" size="small" />
          </div>
        }
        otherwise={
          <If
            condition={!isExporting && !exportError}
            then={
              <div>
                <div className="download-info-message">{t('download.page.info.ready')}</div>
                <Popover
                  content={t('download.success')}
                  open={isDownloadSuccessTooltip}
                  disabled={!isDownloadSuccessTooltip || exportError || isExporting}
                  placement="bottomLeft"
                >
                  <span
                    className="download-action"
                    onClick={() => {
                      setDocumentUrl(`exports/${exportData?.id}/download`);
                    }}
                    onMouseLeave={() => setDownloadSuccessTooltip(false)}
                  >
                    {t('click_here')}
                  </span>
                </Popover>
              </div>
            }
            otherwise={
              <Show when={(!isDownloadMutating && downloadMutationError) || exportError}>
                <div>
                  <span className="download-info-message">{t('encountered.error.try.again')}</span>
                </div>
              </Show>
            }
          />
        }
      />
    </section>
  );
}
