import { useMeQuery } from '@services/api/hooks';
import { useActionRequiredPackagesQuery } from '@services/api/hooks/useActionRequiredPackagesQuery';
import { useActiveSkuQuery } from '@services/api/hooks/useActiveSkuQuery';
import { useCancellationRefundCountQuery } from '@services/api/hooks/useCancellationRefundCountQuery';
import { useCancelRatioQuery } from '@services/api/hooks/useCancelRatioQuery';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useFulfillmentTimeQuery } from '@services/api/hooks/useFulfillmentTimeQuery';
import { useGradeQuery } from '@services/api/hooks/useGradeQuery';
import { useMostSellingCategoriesQuery } from '@services/api/hooks/useMostSellingCategoriesQuery';
import { useMostSellingProductsQuery } from '@services/api/hooks/useMostSellingProductsQuery';
import { useOrderCountByDateQuery } from '@services/api/hooks/useOrderCountByDateQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useTotalAmountQuery } from '@services/api/hooks/useTotalAmountQuery';
import { useTotalItemsQuery } from '@services/api/hooks/useTotalItemsQuery';
import { useTotalStockQuery } from '@services/api/hooks/useTotalStockQuery';
import { useDataSourceDetail } from '@services/hooks';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import property from 'lodash/property';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export function useDashboardQueries({ params }) {
  const { me: currentUser, isMeFetched } = useMeQuery();
  const { data: sellerDetail, isLoading: isLoadingSellerDetail } = useDataSourceDetail({
    dataSourceId: currentUser?.datasource,
    enabled: isMeFetched,
  });

  const { activeSku, isFetchingActiveSku } = useActiveSkuQuery({ params });
  const { totalStock, isFetchingTotalStock } = useTotalStockQuery({ params });
  const { totalAmount, isFetchingTotalAmount } = useTotalAmountQuery({ params });
  const { totalItems, isFetchingTotalItems } = useTotalItemsQuery({ params });
  const { fulfillmentTime, isFetchingFulfillmentTime } = useFulfillmentTimeQuery({ params });
  const { grade, isFetchingGrade } = useGradeQuery({ params });
  const { cancelRatio, isFetchingCancelRatio } = useCancelRatioQuery({ params });
  const { orderCountByDate, isFetchingOrderCountByDate } = useOrderCountByDateQuery({ params });
  const { mostSellingCategories, isFetchingMostSellingCategories } = useMostSellingCategoriesQuery({
    params,
  });
  const { mostSellingProducts, isFetchingMostSellingProducts } = useMostSellingProductsQuery({
    params,
  });
  const { cancellationRefundCount, isFetchingCancellationRefundCount } =
    useCancellationRefundCountQuery({ params });
  const { actionRequiredPackages, isFetchingActionRequiredPackages } =
    useActionRequiredPackagesQuery({ params });

  const productIds = reject(uniq(map(mostSellingProducts, property('product_id'))), isNil);
  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      id__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(productIds) && !isFetchingMostSellingProducts,
    },
  });

  const categoryIds = reject(uniq(map(mostSellingCategories, property('category_id'))), isNil);
  const { categories, isFetchingCategories } = useCategoriesQuery({
    params: {
      id__in: categoryIds?.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(categoryIds) && !isFetchingMostSellingCategories,
    },
  });

  return {
    activeSku,
    isFetchingActiveSku,
    totalStock,
    isFetchingTotalStock,
    totalAmount,
    isFetchingTotalAmount,
    totalItems,
    isFetchingTotalItems,
    fulfillmentTime,
    isFetchingFulfillmentTime,
    grade,
    isFetchingGrade,
    cancelRatio,
    isFetchingCancelRatio,
    orderCountByDate,
    isFetchingOrderCountByDate,
    mostSellingProducts: map(
      reject(mostSellingProducts, ({ product_id }) => isNil(product_id)),
      (sellingProducts) => ({
        ...sellingProducts,
        ...products?.find(({ id }) => sellingProducts.product_id === id),
      })
    ),
    isFetchingMostSellingProducts,
    isFetchingProducts,
    mostSellingCategories: map(
      reject(mostSellingCategories, ({ category_id }) => isNil(category_id)),
      (sellingCategories) => ({
        ...sellingCategories,
        ...categories?.find(({ id }) => sellingCategories.category_id === id),
      })
    ),
    isFetchingMostSellingCategories,
    isFetchingCategories,
    cancellationRefundCount: {
      ...cancellationRefundCount,
      total: [cancellationRefundCount?.cancel, cancellationRefundCount?.refund]
        .filter(Boolean)
        .reduce((acc, count) => acc + count, 0),
    },
    isFetchingCancellationRefundCount,
    actionRequiredPackages,
    isFetchingActionRequiredPackages,
    sellerDetail,
    isLoadingSellerDetail,
  };
}
