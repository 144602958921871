import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedRestrictedProductsQuery } from '@services/api/hooks/usePaginatedProductRestrictionsQuery';
import { usePaginatedProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import entries from 'lodash/entries';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';

export const useDataSource = ({
  filters,
  productsPagination,
  restrictedProductsPagination,
  datasourceId,
  dynamicFormMetaFields,
}) => {
  const { products, isFetchingProducts, totalProducts, refetchProducts } =
    usePaginatedProductsQuery({
      pagination: productsPagination,
      filters,
      params: {
        has_no_restriction_for: datasourceId,
        is_active: true,
      },
    });

  const {
    products: restrictedProducts,
    isFetchingProducts: isFetchingRestrictedProducts,
    totalProducts: totalRestrictedProducts,
    refetchProducts: refetchRestrictedProducts,
  } = usePaginatedProductsQuery({
    filters,
    pagination: restrictedProductsPagination,
    params: {
      has_restriction_for: datasourceId,
      is_active: true,
    },
  });

  const productIds = uniq(products?.map?.((product) => product?.id));
  const restrictedProductIds = uniq(restrictedProducts?.map?.((product) => product?.id));

  const { restrictedProductDetails, isFetchingRestrictedProductDetails } =
    usePaginatedRestrictedProductsQuery({
      params: {
        product__in: restrictedProductIds?.join(','),
        datasource: datasourceId,
        // limit: restrictedProductIds?.length,
        limit: 9999,
      },
      queryOptions: {
        enabled: !isEmpty(restrictedProductIds),
      },
    });

  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: productIds?.join(','),
      main: true,
    },
    queryOptions: {
      enabled: !isFetchingProducts && !isEmpty(productIds),
    },
  });

  const {
    productImages: restrictedProductImages,
    isFetchingProductImages: isFetchingRestrictedProductImages,
  } = useProductImagesQuery({
    params: {
      limit: restrictedProductIds?.length,
      parent__in: restrictedProductIds?.join(','),
      main: true,
    },
    queryOptions: {
      enabled: !isFetchingRestrictedProducts && !isEmpty(restrictedProductIds),
    },
  });

  const attributeIds = uniq(map(dynamicFormMetaFields, 'id'));
  const attributeKeys = uniq(map(dynamicFormMetaFields, 'key'));
  const attributeVals = uniq(
    flatten(
      map([...(products ?? []), ...(restrictedProducts ?? [])], (product) => {
        return Object.values(pick(product.attributes, attributeKeys));
      })
    )
  );

  const { attributeValues, isFetchingAttributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      value__in: attributeVals.join(','),
    },
    queryOptions: {
      enabled:
        !isFetchingRestrictedProducts &&
        !isFetchingProducts &&
        !isEmpty(attributeVals) &&
        !isEmpty(attributeIds),
    },
  });

  const productsDataSource = products?.map((product) => ({
    ...product,
    image: productImages?.find((productImage) => productImage.parent === product.id)?.image,
    attributes: reduce(
      entries(product.attributes),
      (acc, [key, value]) => {
        const attribute = find(dynamicFormMetaFields, { key });
        if (!attribute) return acc;
        const valueLabel = get(
          find(attributeValues, { value, attribute: attribute.id }),
          'label',
          value
        );
        return {
          ...acc,
          [key]: valueLabel,
        };
      },
      {}
    ),
  }));

  const restrictedProductsDataSource = restrictedProducts?.map((product) => {
    const restrictedProductDetail = restrictedProductDetails?.find(
      (detail) => detail.product === product.id && detail.datasource === datasourceId
    );
    return {
      ...product,
      ...restrictedProductDetail,
      image: restrictedProductImages?.find((productImage) => productImage.parent === product.id)
        ?.image,
      attributes: reduce(
        entries(product.attributes),
        (acc, [key, value]) => {
          const attribute = find(dynamicFormMetaFields, { key });
          if (!attribute) return acc;
          const valueLabel = get(
            find(attributeValues, { value, attribute: attribute.id }),
            'label',
            value
          );
          return {
            ...acc,
            [key]: valueLabel,
          };
        },
        {}
      ),
    };
  });

  return {
    productsDataSource,
    isFetchingProducts: isFetchingProducts || isFetchingProductImages || isFetchingAttributeValues,
    isFetchingRestrictedProducts:
      isFetchingRestrictedProducts ||
      isFetchingRestrictedProductDetails ||
      isFetchingRestrictedProductImages,
    totalProducts,
    restrictedProductsDataSource: restrictedProductsDataSource,
    totalRestrictedProducts,
    refetchRestrictedProducts,
    refetchProducts,
  };
};
