import './ExportModal.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import { openNotification } from '@components/AkinonNotification';
import Modal from '@components/Modal/Modal';
import { zodResolver } from '@hookform/resolvers/zod';
import { useStringCaseConverter } from '@hooks/useStringCaseConverter';
import { Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import ExportableColumnsSearch from './ExportableColumnsSearch';

export const ExportFormat = {
  XLSX: 'XLSX',
};
export const ExportFormKey = {
  FIELDS_TO_EXPORT: 'fieldsToExport',
};
const exportFormSchema = z.object({
  [ExportFormKey.FIELDS_TO_EXPORT]: z.any().optional(),
});

export const ExportModal = ({
  isExportModalVisible,
  setIsExportModalVisible,
  columns,
  tableExportColumns,
  setTableExportColumns,
  exportOption,
  exportFormat,
  onExport,
}) => {
  const { t, i18n } = useTranslation();
  const { toLowerCase } = useStringCaseConverter();

  const exportableColumns = columns.filter((column) => column?.exportKey);

  const [filteredExportableColumns, setFilteredExportableColumns] = useState(exportableColumns);

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: zodResolver(exportFormSchema),
    defaultValues: {},
  });

  const onSubmit = (formValues) => {
    const fieldsToExportSerialized = Object.entries(formValues[ExportFormKey.FIELDS_TO_EXPORT])
      .filter((item) => item[1] === true)
      .map((item) => item[0]);

    if (fieldsToExportSerialized.length === 0) {
      openNotification({
        type: 'error',
        message: t('error'),
        description: t('export_error'),
      });
      return;
    }

    const onExportPayload = {
      [ExportFormKey.FIELDS_TO_EXPORT]: fieldsToExportSerialized,
      exportOption,
      exportFormat,
    };
    onExport(onExportPayload);
    setIsExportModalVisible(false);
  };

  useEffect(() => {
    let initialColumns;

    initialColumns = exportableColumns.reduce((acc, column) => {
      acc[column.exportKey] = true;
      return acc;
    }, {});

    reset({
      [ExportFormKey.FIELDS_TO_EXPORT]: initialColumns,
    });
  }, []);

  return (
    <Modal
      title={t('export_settings')}
      visible={isExportModalVisible}
      onCancel={() => setIsExportModalVisible(false)}
      width={1200}
    >
      <AkinonForm layout="vertical" className="table-export-modal">
        {exportableColumns?.length > 0 && (
          <>
            <div className="table-export-modal__desc">{t('export_modal_desc')}</div>
            <div className="table-export-modal__export-filter">
              <ExportableColumnsSearch
                exportableColumns={exportableColumns}
                filteredExportableColumns={filteredExportableColumns}
                setFilteredExportableColumns={setFilteredExportableColumns}
                setValue={setValue}
              />
            </div>

            <div className="exported-columns-container">
              {filteredExportableColumns.map((column) => {
                const key = [ExportFormKey.FIELDS_TO_EXPORT, column.exportKey].join('.');
                const isChecked = Boolean(
                  watch(key) ?? tableExportColumns?.[column.exportKey] ?? true
                );
                return (
                  <AkinonFormItem
                    key={key}
                    name={key}
                    control={control}
                    className="exported-columns-form-item"
                  >
                    <Checkbox
                      className="table-export-modal__export-checkbox"
                      value={column.exportKey}
                      checked={isChecked}
                      onChange={() => {
                        setTableExportColumns(column.exportKey, !isChecked);
                        setValue(key, !isChecked);
                      }}
                    >
                      {toLowerCase(column?.exportTitle || column.title)}
                    </Checkbox>
                  </AkinonFormItem>
                );
              })}
            </div>
          </>
        )}
        <div className="flex gap-2 flex-col">
          <AkinonButton className="export-button" onClick={handleSubmit(onSubmit)}>
            {t('export').toLocaleUpperCase(i18n.language)}
          </AkinonButton>
        </div>
      </AkinonForm>
    </Modal>
  );
};
