import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs({ collectionId }) {
  const { t } = useTranslation('Marketing');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.marketing.landing} to={RouteUrls.marketing.landing}>
        {t('collections')}
      </Link>,
      <Link
        key={RouteUrls.marketing.productCollections.landing}
        to={RouteUrls.marketing.productCollections.landing}
      >
        {t('collections')}
      </Link>,
      collectionId ? t('edit_collection') : t('new_collection_add'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
