import './styles.scss';

import { getActiveAuthObject, getAuthObjects, removeAuthObject } from '@common/auth';
import { getImageResourceUrl, ImageExtension } from '@common/image';
import AkinonButton from '@components/AkinonButton';
import NoNetworkConnectionModal from '@components/NoNetworkConnectionModal';
import Show from '@components/Show';
import { Breakpoint, defaultBreakpoints } from '@constants/index';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { useSetDefaultLanguage } from '@hooks/index';
import BaseLayoutFallback from '@layouts/BaseLayout/BaseLayoutFallback';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { queryClient } from '@services/api/queryClient';
import {
  IconArchive,
  IconLayoutSidebarLeftCollapse,
  IconLayoutSidebarLeftExpand,
} from '@tabler/icons-react';
import useStore from '@zustand-store/index';
import {
  breadcrumbsSelector,
  pageMetaSelector,
  setIsLogCenterOpenSelector,
} from '@zustand-store/selectors/app';
import {
  Alert,
  Breadcrumb,
  Col,
  Image,
  Layout,
  Modal,
  Row,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import clsx from 'clsx';
import { isEmpty, omit } from 'lodash';
import { Suspense, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { createBreakpoint, useBoolean } from 'react-use';
import { Toaster } from 'sonner';

import AkinonLogDrawer from './AkinonLogDrawer';
import AkinonMenu from './AkinonMenu';
import AkinonMobileMenu from './AkinonMobileMenu';
import Announcements from './Announcements';
import ProfilePopover from './ProfilePopover';

const { Header, Sider, Content, Footer } = Layout;
const { Text, Title } = Typography;
const useBreakpoint = createBreakpoint(defaultBreakpoints);

const AppLayout = ({ children }) => {
  const breakpoint = useBreakpoint();
  const navigate = useAppNavigate();
  const { t } = useTranslation(['Settings']);
  const user = useUser();
  const isUserFetching = queryClient.isFetching({
    queryKey: [QueryKey.USER_ME],
  });
  const [isMenuCollapsed, toggleIsMenuCollapsed] = useBoolean(false);
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);
  const breadcrumbs = useStore(breadcrumbsSelector);
  const pageMeta = useStore(pageMetaSelector);
  const setIsLogCenterOpen = useStore(setIsLogCenterOpenSelector);
  useSetDefaultLanguage();

  const MenuIcon = isMenuCollapsed ? IconLayoutSidebarLeftExpand : IconLayoutSidebarLeftCollapse;
  const tokens = getAuthObjects();
  const isImpersonatedLogin = tokens.length > 1;
  useEffect(() => {
    toggleIsMenuCollapsed(breakpoint === Breakpoint.MOBILE);
  }, [breakpoint]);

  const onLogoClick = () => {
    navigate(RouteUrls.dashboard.landing);
  };

  const onMenuCollapseIconClick = () => {
    toggleIsMenuCollapsed();
  };

  const onLogCenterIconClick = () => {
    setIsLogCenterOpen(true);
  };

  const handleTutorialIconClick = () => {
    setIsTutorialModalOpen(true);
  };

  const handleTutorialModalClose = () => {
    setIsTutorialModalOpen(false);
  };

  const onImpersonateLogout = () => {
    const { redirectUrl, access } = getActiveAuthObject();
    removeAuthObject(access);
    navigate(redirectUrl);
    queryClient.invalidateQueries({
      queryKey: [QueryKey.USER_ME],
    });
  };
  const userName =
    user?.first_name + ' ' + user?.last_name + (user?.email ? ` (${user?.email})` : '');

  return (
    <Layout className="app-layout">
      <Toaster className="akinon-toaster" />
      <Header className="app-layout__header">
        {isImpersonatedLogin &&
          (isUserFetching ? (
            <Skeleton
              active
              title={{
                style: {
                  width: '100%',
                  height: 40,
                  marginTop: 0,
                },
              }}
              paragraph={{ rows: 0 }}
            />
          ) : (
            <Alert
              message={
                <Trans
                  i18nKey="layout.header.impersonateLoginWarning"
                  values={{
                    userName,
                    click: t('click').toLowerCase(),
                  }}
                  components={[
                    <AkinonButton
                      key="impersonate-logout"
                      type="link"
                      size="small"
                      onClick={onImpersonateLogout}
                    />,
                    <strong key="strong">Datasource</strong>,
                  ]}
                />
              }
              banner
            />
          ))}
        <Announcements isImpersonatedLogin={isImpersonatedLogin} />
        <Row className="app-layout__header-row" align="middle" justify="space-between">
          <Col className="app-layout__header-left-part">
            <Image
              onClick={onLogoClick}
              className={clsx('app-layout__header-logo', {
                'app-layout__header-logo--collapsed': isMenuCollapsed,
              })}
              preview={false}
              src={getImageResourceUrl({
                name: isMenuCollapsed ? 'collapsed-seller-center-logo' : 'seller-center-logo',
                extension: ImageExtension.WEBP,
              })}
              alt={t('seller.center')}
            />
            <MenuIcon
              onClick={onMenuCollapseIconClick}
              className="app-layout__header-menu-collapse-icon"
            />
          </Col>
          <Col className="app-layout__header-right-part">
            <div className="app-layout__header-horizontal-border-box app-layout__header-horizontal-border-box--leftmost ">
              {breakpoint === Breakpoint.MOBILE && (
                <AkinonMobileMenu theme="dark" mode="inline" style={{ height: '100%' }} />
              )}
            </div>
            <ProfilePopover user={user} />
            <div className="app-layout__header-horizontal-border-box ">
              <IconArchive
                className="app-layout__header-log-center-icon"
                onClick={onLogCenterIconClick}
              />
              <AkinonLogDrawer />
            </div>
          </Col>
        </Row>
      </Header>
      <Layout className="app-layout__main">
        <Sider
          trigger={null}
          collapsible
          collapsed={isMenuCollapsed}
          className={clsx('app-layout__menu', {
            'app-layout__menu--collapsed': isMenuCollapsed,
          })}
        >
          {breakpoint !== Breakpoint.MOBILE && (
            <AkinonMenu theme="dark" collapsed={isMenuCollapsed} mode="inline" />
          )}
        </Sider>
        <Layout
          className={clsx('app-layout__content-with-meta', {
            'app-layout__content-with-meta--collapsed': isMenuCollapsed,
          })}
        >
          <Show when={!isEmpty(breadcrumbs)}>
            <Breadcrumb className="app-layout__breadcrumbs">
              {breadcrumbs.map((breadcrumb, index) => (
                <Breadcrumb.Item
                  className={clsx('app-layout__breadcrumb', {
                    'app-layout__breadcrumb--active': index === breadcrumbs.length - 1,
                  })}
                  key={breadcrumb}
                >
                  {breadcrumb}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </Show>
          <Show when={!isEmpty(pageMeta)}>
            <div className="app-layout__page-meta">
              <div className="app-layout__page-meta-info">
                <Space>
                  <Title level={1} className="app-layout__page-meta-title">
                    {pageMeta.title}
                  </Title>
                  <Show when={!isEmpty(pageMeta.tutorials)}>
                    <i
                      onClick={handleTutorialIconClick}
                      className="icon-info app-layout__page-meta-tutorial-icon"
                    />
                  </Show>
                  <Modal
                    className="app-layout__page-meta-tutorial-modal"
                    footer={null}
                    open={isTutorialModalOpen}
                    onCancel={handleTutorialModalClose}
                  >
                    <Space size="large" direction="vertical">
                      {pageMeta.tutorials?.map(({ title, description }) => (
                        <Space size="small" direction="vertical" key={title}>
                          <Title
                            className="app-layout__page-meta-tutorial-modal-item-title"
                            level={1}
                          >
                            {title}
                          </Title>
                          <Text className="app-layout__page-meta-tutorial-modal-item-description">
                            {description}
                          </Text>
                        </Space>
                      ))}
                    </Space>
                  </Modal>
                </Space>
                <Text className="app-layout__page-meta-description">{pageMeta.description}</Text>
              </div>
              {pageMeta.customElement}
              <Space size="middle" className="app-layout__page-meta-actions">
                {pageMeta.actions?.map?.((action) => (
                  <AkinonButton
                    key={action.label}
                    className={clsx('app-layout__page-meta-action', action.className)}
                    type="secondary"
                    {...omit(action, ['label', 'className'])}
                  >
                    {action.label}
                  </AkinonButton>
                ))}
              </Space>
            </div>
          </Show>
          <Content className="app-layout__content">
            <NoNetworkConnectionModal />
            <Suspense fallback={<BaseLayoutFallback />}>
              <div className="app-layout__content-wrapper">{children ?? <Outlet />}</div>
            </Suspense>
            <Footer className="app-layout__footer">
              <Text className="app-layout__footer-text">
                {t('layout.footer.copyRight', {
                  year: new Date().getFullYear(),
                })}
              </Text>
            </Footer>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
