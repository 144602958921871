import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';

import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import cx from 'classnames';
import uniqueId from 'lodash/uniqueId';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';

import { QuillToolbar } from './QuillToolbar';

export const defaultQuillFormats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'video',
  'code-block',
  'color',
  'background',
  'script',
  'raw-html',
  'undo',
  'redo',
  'formula',
  'clean',
];

export const QuillEditor = forwardRef(
  (
    {
      placeholder,
      value,
      onChange,
      readOnly = false,
      quillFormats,
      isReactHookForm,
      maxLength = false,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [editorHtml, setEditorHTML] = useState(value ?? '');
    const [rawHtml, setRawHTML] = useState(value ?? '');
    const [charCount, setCharCount] = useState(0); // Track character count
    const [showRaw] = useReducer((showRaw) => !showRaw, false);
    const toolbarId = useMemo(() => uniqueId('quill-toolbar-'), []);
    const quillRef = useRef(null);
    const isInternalUpdate = useRef(false); // İçerik güncellemeleri için koruma bayrağı

    const handleUndo = () => {
      quillRef.current?.editor?.history.undo();
    };

    const handleRedo = () => {
      quillRef.current?.editor?.history.redo();
    };

    useEffect(() => {
      if (isReactHookForm) return;
      if (value !== editorHtml) {
        setEditorHTML(value);
        setCharCount(value ? value.replace(/<[^>]+>/g, '').length : 0);
      }
    }, [value]);

    useEffect(() => {
      setEditorHTML(rawHtml);
    }, [rawHtml]);

    const handleOnChange = (content) => {
      if (isInternalUpdate.current) {
        isInternalUpdate.current = false;
        return;
      }

      const plainText = quillRef.current?.editor?.getText();
      if (maxLength && plainText.length > maxLength) {
        isInternalUpdate.current = true;
        const truncatedContent = content.replace(/<[^>]+>/g, '').slice(0, maxLength);

        setEditorHTML(truncatedContent);
        quillRef.current?.editor?.history.undo();
        return;
      }

      setEditorHTML(content);
      setCharCount(plainText?.length);
      onChange?.(content);
    };

    const handleOnRawChange = (e) => {
      setRawHTML(e.target.value);
    };

    const quillModules = useMemo(() => ({
      toolbar: {
        container: `#${toolbarId}`,
        handlers: {},
      },
      history: {
        delay: 1000,
        maxStack: 500,
        userOnly: true,
      },
    }));

    const toolbarFormats = [
      [
        {
          name: 'header',
          Element: 'select',
          elementProps: {
            children: (
              <>
                <option value="1" />
                <option value="2" />
                <option value="3" />
              </>
            ),
          },
        },
        {
          name: 'size',
          Element: 'select',
        },
      ],
      [
        {
          name: 'bold',
          Element: 'button',
        },
        {
          name: 'italic',
          Element: 'button',
        },
        {
          name: 'underline',
          Element: 'button',
        },
        { name: 'strike', Element: 'button' },
      ],
      [
        { name: 'color', Element: 'select' },
        { name: 'background', Element: 'select' },
      ],
      [
        { name: 'script', Element: 'button', elementProps: { value: 'sub' } },
        { name: 'script', Element: 'button', elementProps: { value: 'super' } },
      ],
      [
        { name: 'header', Element: 'button', elementProps: { value: '1' } },
        { name: 'header', Element: 'button', elementProps: { value: '2' } },
        { name: 'blockquote', Element: 'button' },
      ],
      [
        { name: 'list', Element: 'button', elementProps: { value: 'ordered' } },
        { name: 'list', Element: 'button', elementProps: { value: 'bullet' } },
        { name: 'indent', Element: 'button', elementProps: { value: '-1' } },
        { name: 'indent', Element: 'button', elementProps: { value: '+1' } },
      ],
      [
        {
          name: 'undo',
          Element: 'button',
          elementProps: { onClick: handleUndo, children: <UndoOutlined title="Undo" /> },
        },
        {
          name: 'redo',
          Element: 'button',
          elementProps: { onClick: handleRedo, children: <RedoOutlined title="Redo" /> },
        },
      ],
      [
        { name: 'link', Element: 'button' },
        { name: 'image', Element: 'button' },
      ],
      [{ name: 'clean', Element: 'button' }],
    ];

    return (
      <div
        className={cx('quill-editor-wrapper', {
          'show-raw': showRaw,
        })}
      >
        <QuillToolbar
          id={toolbarId}
          formats={toolbarFormats.map((group) =>
            group.filter((groupItem) => quillFormats.includes(groupItem.name))
          )}
        />
        <ReactQuill
          ref={quillRef}
          theme="snow"
          placeholder={placeholder ?? t('editor.placeholder.text')}
          value={editorHtml}
          onChange={handleOnChange}
          modules={quillModules}
          formats={quillFormats}
          readOnly={readOnly}
        />
        {maxLength && (
          <div className="w-100 pt-2 flex justify-end items-center">
            <span className="text-white">
              {charCount}/{maxLength} characters
            </span>
          </div>
        )}
        <Input.TextArea
          ref={ref}
          className={'quill-raw-editor'}
          onChange={handleOnRawChange}
          value={rawHtml}
          maxLength={maxLength}
        />
      </div>
    );
  }
);

QuillEditor.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

QuillEditor.defaultProps = {
  value: '',
  onChange: () => {},
  quillFormats: defaultQuillFormats,
};
