import { limitQuery, PackageStatus } from '@constants/commontypes';
import { getPackageItems } from '@services/api/packages';
import { usePackagesQuery } from '@services/hooks/packages/usePackagesQuery';
import { useQueries } from '@tanstack/react-query';

export const usePackagesItems = ({ orderId }) => {
  const { packages, isLoadingPackages } = usePackagesQuery({
    requestConfig: { params: { order: orderId, ...limitQuery } },
  });

  const filterPackageByReadyStatus = () => {
    return packages?.filter((item) => item.status === PackageStatus.READY);
  };

  const getPackagesIds = () => {
    return filterPackageByReadyStatus()?.map((item) => item.id);
  };

  const packageIdsArray = getPackagesIds();

  const queryFn = (packageId) =>
    getPackageItems({
      ...limitQuery,
      package: packageId,
    });

  const packageItemsQueries = useQueries({
    queries: packages
      ? packageIdsArray?.map((packageId) => ({
          queryKey: ['packageItems', packageId],
          queryFn: () => queryFn(packageId),
        }))
      : [],
  });

  const packageItemsIds = packageItemsQueries[0]?.data?.results?.map((item) => item.order_item);

  return {
    packages,
    packageItemsIds,
    isLoadingPackages,
  };
};
