import { QueryKey } from '@constants/query';
import { getPreOffer } from '@services/api';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';

/**
 * @param {{ preOfferId: string, queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function usePreOfferQuery({ preOfferId, queryOptions }) {
  const { data, isLoading, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PRE_OFFER, preOfferId],
    queryFn: () => getPreOffer({ preOfferId }),
    ...queryOptions,
  });

  return {
    preOffer: data,
    isPreOfferLoading: isLoading,
    isPreOfferFetching: isFetching,
    preOfferError: error,
  };
}
