import { isFileObject } from '@components/AkinonFilter/components/ActiveFilterItem/common';
import merge from 'lodash/merge';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createAppSlice } from './slices/app';
import { createFiltersSlice } from './slices/filters';
import { createLanguageSlice } from './slices/language';
import { createPageDataSlice } from './slices/pageData';
import { createPreOfferSlice } from './slices/preOffer';

const storeInitializer = (...storeArgs) => ({
  ...createPreOfferSlice(...storeArgs),
  ...createFiltersSlice(...storeArgs),
  ...createPageDataSlice(...storeArgs),
  ...createAppSlice(...storeArgs),
  ...createLanguageSlice(...storeArgs),
});

export const useStore = create(
  devtools(
    persist(storeInitializer, {
      name: 'persistedStore',
      storage: createJSONStorage(() => localStorage, {
        replacer: (key, value) => {
          // Do not persist non-serializable file objects
          if (key === 'filter_file' || isFileObject(value)) {
            return;
          } else {
            return value;
          }
        },
      }),
      partialize: (state) => ({
        logCenterRecords: state.logCenterRecords,
        filters: state.filters,
        pageData: state.pageData,
        tokens: state.tokens,
      }),
      merge,
    })
  )
);

export default useStore;
