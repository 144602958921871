import { createNavigationItems, getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import { IconReportAnalytics } from '@tabler/icons-react';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });
  const isSuperuser = userRole === UserRole.SUPER_USER;

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.reporting.sellerPerformance,
        title: t('seller.performance.dashboard'),
        description: t('seller.performance.dashboard.desc'),
        icon: <i className="icon-iade" />,
      },
      {
        to: RouteUrls.reporting.salesReports,
        title: t('sale_reports.card_title'),
        description: isSuperuser
          ? t('sale_reports.card_description')
          : t('sale_reports.card_description_seller'),
        icon: <IconReportAnalytics size={32} />,
      },
    ],
    userRole,
    isProd,
  });
};
