import { getRequestFiltersParams } from '@common/index';
import { getSalesReportsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useSalesReportsQuery({ queryOptions, params, filters } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.SALES_REPORTS, params, filters],
    queryFn: () =>
      client.get(getSalesReportsUrl, {
        params: {
          ...params,
          ...getRequestFiltersParams(filters),
        },
      }),
    ...queryOptions,
  });

  return {
    report: data,
    isReportFetching: isFetching,
  };
}
