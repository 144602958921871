import { useProductOffersQuery } from '@services/api/hooks/useProductOffersQuery';
import { useProductQuery } from '@services/api/hooks/useProductQuery';
import first from 'lodash/first';
import uniq from 'lodash/uniq';

import { useConversationQuery } from './useConversationQuery';
import { useOrdersQuery } from './useOrdersQuery';

export function useConversationAndOrdersQuery({ conversationId }) {
  const { conversation, isConversationFetching, isConversationLoading } = useConversationQuery({
    conversationId,
  });

  const productSku = conversation?.item_object?.product?.sku.split('-').slice(1).join('-');

  const { orders, isOrdersLoading } = useOrdersQuery({
    requestConfig: {
      params: {
        number: conversation?.item_object?.order_number,
      },
    },
    queryOption: {
      enabled: !isConversationFetching && !!conversation?.item_object?.order_number,
    },
  });

  const { productOffers, isProductOffersFetching } = useProductOffersQuery({
    params: {
      sku: productSku,
    },
    queryOptions: {
      enabled: !isConversationFetching && !!productSku,
    },
  });

  const productIds = uniq(productOffers?.map((productOffer) => productOffer.product));

  const { product, isFetchingProduct } = useProductQuery({
    productId: productIds,
    queryOptions: {
      enabled: !isProductOffersFetching && !!productIds,
    },
  });

  return {
    conversation,
    order: first(orders),
    product,
    isConversationLoading,
    isOrdersLoading,
    isFetchingProduct,
  };
}
