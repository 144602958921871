import CopyButton from '@components/CopyButton';
import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import { Tooltip } from 'antd';

export const FormKey = {
  NAME: 'name',
  SLUG: 'slug',
  BEGINNING_DATE: 'beginning_date',
  EXPIRATION_DATE: 'expiration_date',
  IS_ACTIVE: 'is_active',
};

export const getMappedProductOffers = ({ collectionItems, collectionProducts, productOffers }) => {
  const mappedProductOffers = productOffers?.map((item) => {
    const collectionItem = collectionItems?.find?.(
      (collectionItem) => collectionItem?.offer === item?.id
    );
    const product = collectionProducts?.find?.((product) => product?.id === item?.product);
    if (!product) return false;

    return {
      ...item,
      product,
      collectionItem,
    };
  });
  return mappedProductOffers;
};

export const getProductListColumns = ({
  t,
  addProductToCollectionAsync,
  collectionId,
  isMobile,
}) => [
  {
    dataIndex: ['product', 'name'],
    title: t('product_name'),
    className: 'col-type-product-name',
    render: (name) => <Tooltip title={name}>{name}</Tooltip>,
  },
  {
    dataIndex: 'sku',
    title: t('sku'),
    render: (sku) => <CopyButton text={sku} label={sku} isColumn />,
  },
  {
    dataIndex: ['attributes', 'SELLER_BASECODE'],
    title: 'Base Code',
    className: 'uppercase',
    render: (baseCode) => <CopyButton text={baseCode} label={baseCode} isColumn />,
  },
  {
    dataIndex: 'add_button',
    title: ' ',
    fixed: isMobile ? false : 'right',
    render: (_, row) => {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();
            addProductToCollectionAsync({
              requestBody: {
                offer: row.id,
                collection: collectionId,
              },
            }).finally(() => {
              queryClient.invalidateQueries([
                QueryKey.COLLECTION_ITEMS,
                { collection: collectionId },
              ]);
              queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
            });
          }}
          className="bulk-action-button-container"
        >
          <i className="icon-plus" />
        </div>
      );
    },
  },
];

export const getCollectionProductsColumns = ({ t, deleteProductFromCollectionAsync, isMobile }) => [
  {
    dataIndex: ['product', 'name'],
    title: t('product_name'),
  },
  {
    dataIndex: ['product', 'sku'],
    title: t('sku'),
    render: (sku) => <CopyButton text={sku} label={sku} isColumn />,
  },
  {
    dataIndex: ['product', 'base_code'],
    title: t('basecode'),
    render: (baseCode) => <CopyButton text={baseCode} label={baseCode} isColumn />,
  },
  {
    dataIndex: ['collectionItem', 'id'],
    title: ' ',
    fixed: isMobile ? false : 'right',
    render: (collectionItemId) => {
      return (
        <div
          onClick={(event) => {
            event.stopPropagation();

            deleteProductFromCollectionAsync({
              id: collectionItemId,
            }).finally(() => {
              queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
            });
          }}
          className="bulk-action-button-container"
        >
          <i className="icon-minus" />
        </div>
      );
    },
  },
];

export const getTableActions = ({
  t,
  onShowAddSelectedItemsModal,
  onShowAddAllProductsModal,
  selectedRowKeys,
  totalProductOffers,
}) => {
  const hasSelectedRows = selectedRowKeys?.length > 0;
  if (hasSelectedRows) {
    return [
      {
        label: t('Marketing:bulk_add_selected_items'),
        actionHandler: onShowAddSelectedItemsModal,
        isSelectionRequired: true,
      },
    ];
  } else {
    return totalProductOffers > 0
      ? [
          {
            label: t('Marketing:add_all_products'),
            actionHandler: onShowAddAllProductsModal,
            isSelectionRequired: false,
          },
        ]
      : [];
  }
};

export const getCollectionProductsTableActions = ({
  t,
  onShowDeleteItemsModal,
  onShowRemoveAllProductsModal,
  selectedRowKeys,
  totalProductOffers,
}) => {
  const hasSelectedRows = selectedRowKeys?.length > 0;

  if (hasSelectedRows) {
    return [
      {
        label: t('Marketing:bulk_delete_selected_items'),
        actionHandler: onShowDeleteItemsModal,
        isSelectionRequired: true,
      },
    ];
  } else {
    return totalProductOffers > 0
      ? [
          {
            label: t('Marketing:remove_all_items'),
            actionHandler: onShowRemoveAllProductsModal,
            isSelectionRequired: false,
          },
        ]
      : [];
  }
};

export const StaticFilterKey = {
  BASE_CODE: 'product__base_code__iexact',
  SKU: 'product__sku__iexact',
  PRODUCT_NAME: 'product__name__icontains',
};
