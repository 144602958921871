import { dig } from '@utils/index';
import { Col, Form } from 'antd';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { withTooltip } from './withTooltip';

/** @type {StyledFormItemProps} */
const StyledFormItem = styled(Form.Item)`
  .ant-form-item-required {
    width 100%;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-style: normal;
    font-size: 12px;
    font-family: SimSun, sans-serif;
    align-self: center;
    ${(props) => props.requiredMarkPosition === 'right' && 'position: absolute; right: 0;'}
    line-height: 1;
    content: ${(props) => (props.requiredMarkContent ? `'${props.requiredMarkContent}'` : '*')};
  }
`;

/**
 * @param {AkinonFormItemProps} props
 */
const AkinonFormItem = ({
  children,
  label,
  name,
  control,
  required,
  disabled,
  initialValue,
  help,
  inputRef,
  isWithColumn = false,
  valuePropName,
  valuePropParser = (val) => val,
  shouldUnregister,
  colProps = {},
  colSpan = 24,
  ...rest
}) => {
  return control ? (
    <Controller
      name={name}
      control={control}
      disabled={disabled}
      defaultValue={initialValue}
      key={name}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => {
        const child = React.Children.toArray(children)[0];
        const component = (
          <StyledFormItem
            label={label}
            required={required}
            validateStatus={fieldState.invalid ? 'error' : undefined}
            help={
              isFunction(help)
                ? help(fieldState.error)
                : help ?? fieldState.error
                  ? dig(fieldState.error, 'message')
                  : null
            }
            {...rest}
          >
            {React.cloneElement(child, {
              ...child.props,
              ...field,
              onChange: (...params) => {
                child.props.onChange && child.props.onChange(...params);
                field.onChange(...params);
              },
              onBlur: () => {
                child.props.onBlur && child.props.onBlur();
                field.onBlur();
              },
              ref: (_ref) => {
                if (child.ref) {
                  typeof child.ref === 'function' ? child.ref(_ref) : (child.ref.current = _ref);
                }
                field.ref({ focus: () => inputRef?.current?.input?.focus?.(), ..._ref });
                if (inputRef) {
                  if (inputRef.current?.input) {
                    inputRef.current = { input: inputRef.current.input, ..._ref };
                  } else {
                    inputRef.current = { input: inputRef.current, ..._ref };
                  }
                }
              },
              ...(valuePropName && {
                [valuePropName]: valuePropParser(get(field.value, valuePropName, field.value)),
              }),
            })}
          </StyledFormItem>
        );

        return isWithColumn ? (
          <Col {...colProps} span={colSpan}>
            {component}
          </Col>
        ) : (
          component
        );
      }}
    />
  ) : (
    <Form.Item label={label} required={required} initialValue={initialValue} {...rest}>
      {children}
    </Form.Item>
  );
};

export default memo(AkinonFormItem);

const AkinonFormItemWithTooltip = withTooltip(memo(AkinonFormItem));
export { AkinonFormItemWithTooltip };
