import { useAppMeta } from '@root/contexts/AppMetaContext';

export function useEnv() {
  const appMeta = useAppMeta();
  const liveAppVersionRegex = /^v\d+\.\d+\.\d+(f\d+)?$/;
  const isProd = liveAppVersionRegex.test(appMeta.version);

  return {
    isProd,
    isDev: !isProd,
  };
}
