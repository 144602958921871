import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isProd }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.order.ordersList,
        title: t('orders'),
        description: t('orders_desc'),
        icon: <i className="icon-packages-list" />,
      },
      {
        to: RouteUrls.order.packages.landing,
        title: t('packages'),
        description: t('packages_desc'),
        icon: <i className="icon-urunlistesi" />,
      },
      {
        to: RouteUrls.order.cancellationRequests,
        title: t('cancel.refund.transactions'),
        description: t('cancel_refund_transactions_desc'),
        icon: <i className="icon-iade" />,
      },
    ],
    userRole,
    isProd,
  });
};
