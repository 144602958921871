import { openNotification } from '@components/AkinonNotification';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { variantsListAttributeMetaFieldsSelector } from '@zustand-store/selectors/preOffer';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';

import { BasicProductInfoKey } from '../components/BasicProductInfoForm/common';
import { DescriptionFormKey } from '../components/DescriptionForm/common';
import { ProductsToBeAddedTableKey } from '../components/ProductsToBeAddedTableForm/common';

export function useOnSubmit({
  productsToBeAddedTableFormDataSource,
  submitAllPreOffers,
  basicProductInfoForm,
  productsToBeAddedTableForm,
  descriptionForm,
  priceLists,
  stockLists,
  dataSources,
  attributeSetId,
  postPreOffer,
}) {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();

  const variantsListAttributeMetaFields = useStore(variantsListAttributeMetaFieldsSelector);

  const variantsListAttributeKeys = variantsListAttributeMetaFields.map(({ key }) => key);

  const onSubmit = () => {
    // validate all forms
    Promise.allSettled(
      [basicProductInfoForm, productsToBeAddedTableForm, descriptionForm].map((form) =>
        form.trigger()
      )
    ).then((formsPromiseResult) => {
      const areFormsValid = formsPromiseResult.every(
        ({ status, value }) => status === 'fulfilled' && value !== false
      );
      if (!areFormsValid) {
        openNotification({
          message: t('transaction_failed'),
          description: t('required_fields_error_message'),
          type: 'error',
        });
        return;
      }
      const pricesKeys = [
        ProductsToBeAddedTableKey.CURRENCY_TYPE,
        ProductsToBeAddedTableKey.PRICE,
        ProductsToBeAddedTableKey.RETAIL_PRICE,
        ProductsToBeAddedTableKey.TAX_RATE,
      ];
      const stocksKeys = [ProductsToBeAddedTableKey.STOCK];
      const priceListMasterId = priceLists.find(({ is_master }) => is_master)?.id;
      const stockListMasterId = stockLists.find(({ is_master }) => is_master)?.id;
      const sellerDescription = descriptionForm.getValues(DescriptionFormKey.SELLER_DESCRIPTION);

      const baseCode = basicProductInfoForm.getValues(BasicProductInfoKey.BASE_CODE);
      const commonRequestBody = {
        name: basicProductInfoForm.getValues(BasicProductInfoKey.NAME),
        base_code: baseCode,
        attribute_set: attributeSetId,
        datasource: first(dataSources)?.id,
        description: sellerDescription,
      };
      const requestBodies = productsToBeAddedTableFormDataSource.map((preOffer) => {
        const offerValues = productsToBeAddedTableForm.getValues(preOffer.id);

        return {
          ...commonRequestBody,
          sku: offerValues[ProductsToBeAddedTableKey.SKU],
          attributes: {
            ...preOffer,
            ...variantsListAttributeKeys.reduce(
              (preOfferAttributes, attributeKey) => ({
                ...preOfferAttributes,
                [attributeKey]: offerValues[attributeKey],
              }),
              {}
            ),
            SELLER_BARCODE: productsToBeAddedTableForm.getValues(
              [preOffer.id, ProductsToBeAddedTableKey.SELLER_BARCODE].join('.')
            ),
            SELLER_DESCRIPTION: sellerDescription,
            SELLER_BASECODE: baseCode,
          },
          prices: [
            {
              price_list: priceListMasterId,
              ...Object.entries(pick(offerValues, pricesKeys)).reduce(
                (priceItem, [priceKey, priceValue]) => {
                  return {
                    ...priceItem,
                    [priceKey]: priceValue,
                  };
                },
                {}
              ),
            },
          ],
          stocks: [
            {
              stock_list: stockListMasterId,
              ...Object.entries(pick(offerValues, stocksKeys)).reduce(
                (stockItem, [stockKey, stockValue]) => {
                  return {
                    ...stockItem,
                    [stockKey]: stockValue,
                  };
                },
                {}
              ),
            },
          ],
          product: preOffer.id,
        };
      });

      const succeededPreOfferIds = [];

      const requestPromises = requestBodies.map(async (requestBody) => {
        try {
          const response = await postPreOffer({
            requestBody,
          });
          succeededPreOfferIds.push(response?.product);
        } catch (error) {
          openNotification({
            message: t('transaction_failed'),
            description: error?.response?.data?.detail || t('create_product_offer_error'),
            type: 'error',
          });
        }
      });

      Promise.all(requestPromises).then(() => {
        if (!isEmpty(succeededPreOfferIds)) {
          submitAllPreOffers(
            {
              preOfferIds: succeededPreOfferIds,
            },
            {
              onSuccess() {
                basicProductInfoForm.reset();
                openNotification({
                  message: t('transaction_success'),
                  description: t('create_product_offer_success'),
                });
                navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
              },
            }
          );
        }
      });
    });
  };

  return onSubmit;
}
