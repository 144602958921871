import '../style.scss';

import { getFormRuleMap } from '@common/form';
import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/AkinonNotification';
import AkinonSelect from '@components/AkinonSelect';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import { DraggerInput } from '@components/FileInput';
import Box from '@components/utility/box/index';
import { AttributeDataType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useBulkImportPreOfferMutation } from '@services/api/hooks/useBulkImportPreOfferMutation';
import { queryClient } from '@services/api/queryClient';
import { useDownloadBulkTemplateAttribute } from '@services/hooks';
import { useDownloadBulkTemplatePreOffer } from '@services/hooks/preOffers/useDownloadBulkTemplatePreOffer';
import { IconInfoCircle } from '@tabler/icons-react';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { Divider, Form, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ALLOWED_OFFER_FILE_TYPES, FormField } from '../common';
import { BulkActionsProgressModal } from '../components/BulkActionsProgressModal';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const { Text, Title } = Typography;

const BulkActionsOffers = () => {
  const { t } = useTranslation('ProductsAndCategories');
  usePageMeta();
  useBreadcrumbs();

  const {
    mutate: downloadBulkPreOfferTemplate,
    isMutating: isDownloadBulkPreOfferTemplateMutating,
  } = useDownloadBulkTemplatePreOffer();

  const { downloadBulkTemplateAttribute, isDownloadBulkDownloadTemplateMutating } =
    useDownloadBulkTemplateAttribute();

  const { attributes, isAttributesLoading } = useAttributesQuery();

  const attributeOptions = createSelectOptions(
    attributes?.filter((attribute) => attribute?.data_type === AttributeDataType.DROPDOWN),
    {
      labelKey: 'name',
      valueKey: 'id',
    }
  );

  const formRuleMap = getFormRuleMap({ t });

  const [form] = Form.useForm();
  const [attributeForm] = Form.useForm();
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      useItemId: true,
      disableItemBy: (item) => !item?.attribute_set,
    },
  });

  const {
    bulkImportPreOffers,
    resetBulkImportPreOffers,
    isBulkImportingPreOffers,
    isBulkImportPreOffersSuccess,
    isBulkImportPreOffersError,
    bulkImportPreOffersData,
  } = useBulkImportPreOfferMutation({
    mutationOptions: {
      onSuccess: async ({ key }) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKey.IMPORT_STATUS, key],
        });
        openNotification({
          message: t('transaction_success'),
          description: t('bulk_import_message.success'),
        });
      },
      onError: (error) => {
        openNotification({
          message: t('transaction_failed'),
          description: t('bulk_import_message.error', {
            reason:
              error.response?.data?.detail ??
              error?.response?.data?.invalid_headers ??
              error.message,
          }),
          type: 'error',
        });
      },
    },
  });

  const handleFileUpload = () => {
    form.validateFields().then((fields) => {
      const formData = new FormData();

      const categoryId = fields[FormField.ID].value;
      const file = fields[FormField.FILE];

      file.fileList.forEach((fileItem) => {
        formData.append(FormField.FILE, fileItem.originFileObj);
      });
      formData.append('category', categoryId);
      bulkImportPreOffers({ formData });
    });
  };

  const downloadOffersXLSXTemplate = () => {
    form.validateFields([FormField.ID]).then((fields) => {
      const categoryId = fields[FormField.ID].value;
      downloadBulkPreOfferTemplate(
        { category: categoryId, type: 'XLSX' },
        {
          onSuccess: () => {
            openNotification({
              message: t('file.preparing'),
              description: t('file.download.modal.description'),
            });
          },
        }
      );
    });
  };

  const downloadAttributeValuesXLSXTemplate = () => {
    attributeForm.validateFields([FormField.ATTRIBUTE]).then(({ attribute }) => {
      downloadBulkTemplateAttribute(
        { attribute },
        {
          onSuccess: () => {
            openNotification({
              message: t('file.preparing'),
              description: t('file.download.modal.description'),
            });
          },
        }
      );
    });
  };

  return (
    <section className="bulk-actions flex gap-8 [&_.box-primary.form-box:nth-child(2)_.ant-select-selector]:join-[!bg-white,text-black]">
      <Box className="box-primary form-box" title={t('bulk.actions.offer')}>
        <Form className="ant-form" form={form} layout="vertical">
          <div>
            <Form.Item
              rules={[formRuleMap.required]}
              name={FormField.ID}
              label={t('select.category')}
              style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}
            >
              <AkinonTreeSelect
                allowClear
                showSearch
                virtual={false}
                treeNodeFilterProp="title"
                treeDefaultExpandAll
                loading={isCategoriesLoading}
                placeholder={t('select.attribute.set.name')}
                treeData={categoryTreeData}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                onSelect={(_, node) => {
                  form.setFieldsValue({
                    [FormField.ID]: {
                      label: node.title,
                      value: node.intrinsicValue,
                    },
                  });
                }}
              />
            </Form.Item>
          </div>
          <div className="download-template">
            <span>{t('bulk.actions.download')}</span>
            <div style={{ marginInlineStart: 'auto', display: 'flex', gap: '1rem' }}>
              <AkinonButton
                loading={isCategoriesLoading || isDownloadBulkPreOfferTemplateMutating}
                type="default"
                icon={<i className="icon-download" />}
                size="small"
                onClick={downloadOffersXLSXTemplate}
              >
                XLSX
              </AkinonButton>
            </div>
          </div>

          <Divider />

          <Form.Item
            name={FormField.FILE}
            rules={[formRuleMap.file()]}
            label={t('bulk.actions.upload.offer')}
          >
            <DraggerInput
              accept={ALLOWED_OFFER_FILE_TYPES}
              singleAction
              multiple={false}
              maxCount={1}
              beforeUpload={() => false}
            />
          </Form.Item>

          <div className="actions">
            <AkinonButton
              loading={isCategoriesLoading || isBulkImportingPreOffers}
              style={{
                marginTop: '24px',
                fontWeight: '600',
                fontSize: '14px',
              }}
              type="primary"
              onClick={handleFileUpload}
            >
              {t('save')}
            </AkinonButton>
          </div>
        </Form>
      </Box>
      <Box className="box-primary form-box !bg-astra">
        <Title className="text-olive-drab flex gap-1.5" level={4}>
          <IconInfoCircle className="text-olive-drab" />
          {t('bulk.actions.download.attribute.values')}
        </Title>
        <Form className="ant-form" form={attributeForm} layout="vertical">
          <div>
            <Form.Item
              rules={[formRuleMap.required]}
              name={FormField.ATTRIBUTE}
              label={<Text>{t('attribute')}</Text>}
              style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}
            >
              <AkinonSelect
                virtual={false}
                showSearch
                optionFilterProp="label"
                placeholder={t('select.attribute')}
                options={attributeOptions}
              />
            </Form.Item>
          </div>

          <div className="download-template">
            <AkinonButton
              loading={isAttributesLoading || isDownloadBulkDownloadTemplateMutating}
              type="default"
              size="small"
              className="m-0 !text-chinese-gold uppercase !border-none"
              onClick={downloadAttributeValuesXLSXTemplate}
              icon={<i className="icon-download before-with-content:text-chinese-gold" />}
            >
              {t('bulk.actions.download')}
            </AkinonButton>
          </div>
        </Form>
      </Box>
      <BulkActionsProgressModal
        bulkImportKey={bulkImportPreOffersData?.key}
        open={!isBulkImportPreOffersError && isBulkImportPreOffersSuccess}
        navigateTo={RouteUrls.productsAndCategories.offerList.pendingApproval}
        onCancel={() => {
          resetBulkImportPreOffers();
          form.resetFields();
        }}
        messages={{
          successCount: ({ succeededCount }) =>
            t('offer_import_progress_succeeded_count', { count: succeededCount }),
          failCount: ({ failedCount }) =>
            t('offer_import_progress_failed_count', { count: failedCount }),
          finishButtonText: t('go_to_created_items', {
            to: t('to_offer'),
          }),
        }}
      />
    </section>
  );
};

export default BulkActionsOffers;
