import { UserRole } from '@constants/auth';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Reporting');
  const setPageMeta = useStore(setPageMetaSelector);
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  useEffect(() => {
    setPageMeta({
      title: t('sale_reports.meta_title'),
      description: isSuperUser
        ? t('sale_reports.meta_description')
        : t('sale_reports.meta_description_seller'),
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
