import { setBulkCategoryUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function useSetBulkCategoryMutation({ selectedRowKeys, filters, mutationOptions } = {}) {
  const { mutate, isLoading, data, error, isSuccess, reset } = useAkinonMutation({
    mutationFn: ({ category_id, requestConfig }) => {
      const params =
        selectedRowKeys?.length > 0
          ? {
              id__in: selectedRowKeys.join(','),
            }
          : filters;

      return client.post(
        setBulkCategoryUrl,
        {
          category_id,
        },
        {
          params,
          ...requestConfig,
        }
      );
    },
    ...mutationOptions,
  });

  return {
    setBulkCategory: mutate,
    isSettingBulkCategory: isLoading,
    setBulkCategoryCacheKey: data?.key,
    isSettingBulkCategorySuccess: isSuccess,
    setBulkCategoryError: error,
    resetSetBulkCategory: reset,
  };
}
