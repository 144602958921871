import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import { Tag } from 'antd';
import { generatePath } from 'react-router-dom';

const DataIndex = {
  ID: 'id',
  NAME: 'name',
  CODE: 'code',
  IS_ACTIVE: 'is_active',
};
export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: DataIndex.NAME,
      title: t('location_name'),
      render: (name, record) => {
        return (
          <Link
            to={generatePath(RouteUrls.stockLocations.detail, {
              locationId: record?.id,
            })}
          >
            {name}
          </Link>
        );
      },
    },
    {
      dataIndex: DataIndex.CODE,
      title: t('location_code'),
    },
    {
      dataIndex: DataIndex.IS_ACTIVE,
      title: t('location_is_active'),
      render: (isActive) => {
        return (
          <Tag color={isActive ? 'green' : 'red'}>{isActive ? t('active') : t('passive')}</Tag>
        );
      },
    },
  ];
};
