import './index.scss';

import { getPathFragments } from '@common/routing';
import AkinonFlex from '@components/AkinonFlex';
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons-react';
import useStore from '@zustand-store/index';
import { filtersSelector, setFiltersSelector } from '@zustand-store/selectors/filters';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

const AkinonTableSorter = ({ title, sortKey }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { pathname, search } = location;
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;

  const setFilters = useStore(setFiltersSelector);
  const pageFilters = useStore(filtersSelector);

  const currentPageFilters = pageFilters[filterKey];

  const sortKeys = currentPageFilters?.sort ? currentPageFilters.sort.split(',') : [];
  const activeSortKeyIndex = sortKeys.findIndex((key) => key === sortKey || key === `-${sortKey}`);
  const activeSortKey = sortKeys[activeSortKeyIndex];

  const direction = activeSortKey
    ? activeSortKey[0] === '-'
      ? SortDirection.DESC
      : SortDirection.ASC
    : null;

  const handleSorterClick = () => {
    const newSortKeys = sortKeys.filter((_, index) => index !== activeSortKeyIndex);

    if (direction === null) {
      newSortKeys.push(sortKey);
    } else if (direction === SortDirection.ASC) {
      newSortKeys.push(`-${sortKey}`);
    }

    setFilters({
      pagePath: filterKey,
      filters: {
        ...currentPageFilters,
        sort: isEmpty(newSortKeys) ? undefined : newSortKeys.join(','),
      },
    });
  };

  return (
    <>
      <div>{isString(title) ? title?.toLocaleUpperCase(i18n.language) : title}</div>
      <AkinonFlex
        justifyContent="space-between"
        alignItems="center"
        className="akinon-table-sorter"
      >
        <AkinonFlex
          direction="column"
          className="cursor-pointer akinon-table-sorter__icon-container"
          onClick={handleSorterClick}
        >
          <IconArrowNarrowUp
            size={16}
            className={clsx({
              'sort-icon': true,
              'sort-icon-active': direction === SortDirection.ASC,
            })}
          />
          <IconArrowNarrowDown
            size={16}
            className={clsx({
              'sort-icon': true,
              'sort-icon-active': direction === SortDirection.DESC,
            })}
          />
        </AkinonFlex>
      </AkinonFlex>
    </>
  );
};

export default AkinonTableSorter;
