import CopyButton from '@components/CopyButton';
import SmallColumnImage from '@components/SmallColumnImage';

export const StaticFilterKey = {
  BASE_CODE: 'base_code',
  SKU: 'sku',
  CATEGORY: 'category',
  NAME: 'name__icontains',
};

export const ColumnKey = {
  SKU: 'sku',
  BASE_CODE: 'base_code',
  NAME: 'name',
  CATEGORY: 'category',
  BRAND: 'brand',
  IMAGE: 'image',
};

export const DataIndexKey = {
  ...ColumnKey,
  BRAND: ['attributes', 'brand'],
};

export const getColumns = ({ t, categories }) => [
  {
    dataIndex: DataIndexKey.IMAGE,
    key: ColumnKey.IMAGE,
    title: <div className="image-table-head" />,
    render: (productImage) => <SmallColumnImage src={productImage} />,
    className: 'image-table-column',
  },
  {
    dataIndex: DataIndexKey.SKU,
    key: ColumnKey.SKU,
    title: t('sku'),
    render: (sku) => <CopyButton text={sku} label={sku} isColumn />,
  },
  {
    dataIndex: DataIndexKey.BASE_CODE,
    key: ColumnKey.BASE_CODE,
    title: t('base.code'),
    render: (baseCode) => <CopyButton text={baseCode} label={baseCode} isColumn />,
  },
  {
    dataIndex: DataIndexKey.NAME,
    key: ColumnKey.NAME,
    title: t('product_name'),
  },
  {
    dataIndex: DataIndexKey.CATEGORY,
    key: ColumnKey.CATEGORY,
    title: t('category'),
    render: (categoryId) => categories?.find(({ id }) => id === categoryId)?.name,
  },
];
