import './styles.scss';

import { useIsMobile } from '@hooks/useIsMobile';
import { Steps } from 'antd';
import clsx from 'clsx';
import React from 'react';

/**
 * @param {import('antd').StepsProps} props
 */

const AkinonSteps = ({
  labelPlacement = 'vertical',
  className,
  innerClassName = 'md:w-1/2',
  children,
  ...otherProps
}) => {
  const isMobile = useIsMobile();
  return (
    <div className={clsx('akinon-steps', className)}>
      <Steps
        direction={isMobile ? 'vertical' : 'horizontal'}
        className={clsx('akinon-steps-inner', innerClassName)}
        labelPlacement={labelPlacement}
        {...otherProps}
      >
        {children}
      </Steps>
    </div>
  );
};

export const AkinonStep = Steps.Step;

export default AkinonSteps;
