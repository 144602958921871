import './styles.scss';

import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getTicketsUrl } from '@constants/apiUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import { useUserRole } from '@utils/hooks/useUserRole';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns, StaticFilterKey } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import useFilterFields from './hooks/useFilterFields';
import { usePageMeta } from './hooks/usePageMeta';

const SupportTicketList = () => {
  const { t } = useTranslation('Support');
  const navigate = useAppNavigate();
  const userRole = useUserRole();
  const [pagination, setPagination] = usePagination();
  const filters = usePageFilters();

  const { dataSource, total, isDataSourceLoading } = useDataSource({
    pagination,
    filters,
    userRole,
  });

  useBreadcrumbs();
  usePageMeta();
  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const disabledStartDate = useCallback(
    (selectedStartDate) => {
      const endDate = filters[StaticFilterKey.END_DATE];
      if (selectedStartDate && endDate) {
        return selectedStartDate.valueOf() > endDate.valueOf();
      }
    },
    [filters?.[StaticFilterKey.END_DATE]]
  );

  const disabledEndDate = useCallback(
    (selectedEndDate) => {
      const startDate = filters[StaticFilterKey.START_DATE];
      if (selectedEndDate && startDate) {
        return selectedEndDate.valueOf() < startDate.valueOf();
      }
    },
    [filters?.[StaticFilterKey.START_DATE]]
  );

  const filterFields = useFilterFields({
    t,
    userRole,
    disabledStartDate,
    disabledEndDate,
  });

  const columns = getColumns({ t, userRole, navigate });

  return (
    <section className="support-ticket-list">
      <AkinonFilter title={t('filters')} filterFields={filterFields} total={total} />
      <AkinonTable
        title={`${t('support_ticket_title')} (${total})`}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        dataSource={dataSource}
        optionsUrl={getTicketsUrl}
        loading={isDataSourceLoading}
        description={t('nResultsFound', { n: total })}
        total={total}
        rowKey={'id'}
      />
    </section>
  );
};

export default SupportTicketList;
