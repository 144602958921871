import './style.scss';

import { useParams } from 'react-router-dom';

import { MessagesBox } from './components/MessagesBox';
import { ProductInformationBox } from './components/ProductInformationBox';
import { ReplyMessageBox } from './components/ReplyMessageBox';
import { useConversationAndProductsQuery } from './hooks/api/useConversationAndProductsQuery';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

function ProductAnswer() {
  const { id } = useParams();

  useBreadcrumbs();
  usePageMeta();

  const { conversation, productImages, isConversationLoading } = useConversationAndProductsQuery({
    conversationId: id,
  });

  return (
    <section className="product-answer-detail">
      <div className="grid grid-cols-12 gap-8">
        <div
          direction="column"
          className="flex flex-col col-span-12 lg:col-span-8 xl:col-span-6 gap-8"
        >
          <MessagesBox isConversationLoading={isConversationLoading} conversation={conversation} />
          <ReplyMessageBox conversationId={id} />
        </div>
        <div
          style={{ maxHeight: 'fit-content' }}
          className="col-span-12 lg:col-span-6 xl:col-span-4"
        >
          <ProductInformationBox conversation={conversation} productImages={productImages} />
        </div>
      </div>
    </section>
  );
}

export default ProductAnswer;
