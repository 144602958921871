import { UserRole } from '@constants/auth';
import { useUser } from '@root/contexts/hooks/useUser';
import { useDataSourceDetailQuery } from '@services/api/hooks';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ id }) {
  const { t } = useTranslation('Finance');
  const setPageMeta = useStore(setPageMetaSelector);

  const { user } = useUser();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const dataSourceId = id ?? user?.datasource;
  const { dataSourceDetail } = useDataSourceDetailQuery({
    dataSourceId,
    queryOptions: {
      enabled: Boolean(dataSourceId) && isSuperUser,
    },
  });

  const sellerName = dataSourceDetail?.name;

  useEffect(() => {
    setPageMeta({
      title: isSuperUser
        ? `${t('seller_management_detail')}: ${sellerName ?? ''}`
        : t('account_management_detail'),
      description: t('seller.management.desc'),
    });
  }, [t, sellerName]);

  useUnmount(() => {
    setPageMeta({});
  });
}
