import { convertFileToBase64 } from '@common/file';
import { openNotification } from '@components/AkinonNotification';
import { Upload } from 'antd';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

const CustomDragger = ({ ...draggerProps }) => {
  const { t } = useTranslation();

  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    });
  };

  const beforeUpload = async (file) => {
    draggerProps.beforeUpload?.(file);

    const base64 = await convertFileToBase64(file);
    file.base64 = base64;
    return true;
  };

  const fileList = draggerProps.value ?? draggerProps.fileList ?? [];

  return (
    <Dragger
      data-testId="akinon-dragger"
      fileList={fileList}
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      {...draggerProps}
      onChange={({ fileList }) => {
        try {
          draggerProps.onChange?.(fileList);
        } catch (error) {
          openNotification({
            message: error?.message ?? t('error'),
            description: error?.description ?? t('error'),
            type: 'error',
          });
        }
      }}
      className={clsx('akinon-dragger', draggerProps.className)}
    >
      {draggerProps.children ?? t('dragger.desc')}
    </Dragger>
  );
};

export default CustomDragger;
