import './style.scss';

import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import useAppNavigate from '@hooks/useAppNavigate';
import { useCollectionDetailQuery } from '@services/api/hooks/useCollectionDetailQuery';
import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { getStepItems } from './common';
import { useBreadcrumbs } from './hooks/useBreadCrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const AddEditCollection = () => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const { id: collectionId } = useParams();
  const navigate = useAppNavigate();
  const location = useLocation();

  useBreadcrumbs({ collectionId });
  usePageMeta();

  const currentStepFromUrl = new URLSearchParams(location.search).get('step');
  const initialStep = currentStepFromUrl ? Number(currentStepFromUrl) : 0;

  const [currentStep, setCurrentStep] = useState(initialStep);

  const { collectionDetail, isFetchingCollectionDetail } = useCollectionDetailQuery({
    params: { collectionId },
    queryOptions: {
      enabled: Boolean(collectionId),
    },
  });

  const isCollectionDetailLoading = Boolean(collectionId && isFetchingCollectionDetail);

  const stepItems = getStepItems({
    t,
    collectionId,
    collectionDetail,
    isCollectionDetailLoading,
    setCurrentStep,
  });

  const stepHandleOnChange = (step) => {
    if (!collectionId) return;
    setCurrentStep(step);
    navigate(`?step=${step}`, { replace: true });
  };

  useEffect(() => {
    const stepFromUrl = new URLSearchParams(location.search).get('step');
    if (stepFromUrl && !isNaN(Number(stepFromUrl))) {
      setCurrentStep(Number(stepFromUrl));
    }
  }, [location.search]);

  return (
    <section className="add-edit-collection-form">
      <AkinonSteps current={currentStep} onChange={stepHandleOnChange}>
        {stepItems.map(({ key, title, icon, disabled }) => (
          <AkinonStep key={key} title={title} icon={icon} disabled={disabled} />
        ))}
      </AkinonSteps>
      <Divider />
      {stepItems.find((_, index) => index === currentStep)?.content}
    </section>
  );
};

export default AddEditCollection;
