import { Tag } from 'antd';
import cond from 'lodash/cond';
import React from 'react';

export const TicketStatus = {
  CLOSED: 0,
  AUTO_CLOSED: 1,
  OPEN: 2,
};

export const TicketPriority = {
  DEFAULT: 0,
  MEDIUM: 1,
  HIGH: 2,
};

export const getTicketStatusOptions = ({ t, isSuperUser }) => {
  const options = [{ value: TicketStatus.OPEN, label: t('ticket_status.open') }];
  if (isSuperUser) {
    options.push({ value: TicketStatus.AUTO_CLOSED, label: t('ticket_status.autoClosed') });
    options.push({ value: TicketStatus.CLOSED, label: t('ticket_status.closed') });
  } else {
    options.push({
      value: [TicketStatus.CLOSED, TicketStatus.AUTO_CLOSED].join(','),
      label: t('ticket_status.closed'),
    });
  }

  return options;
};

export const getTicketPriorityOptions = ({ t }) => {
  return [
    { value: TicketPriority.DEFAULT, label: t('default') },
    { value: TicketPriority.MEDIUM, label: t('medium') },
    { value: TicketPriority.HIGH, label: t('high') },
  ];
};

export const getTicketStatusTag = ({ status, t, isSuperUser }) => {
  if (isSuperUser) {
    return cond([
      [
        (status) => status === TicketStatus.CLOSED,
        () => <Tag color="red">{t('ticket_status.closed')}</Tag>,
      ],
      [
        (status) => status === TicketStatus.AUTO_CLOSED,
        () => <Tag color="volcano">{t('ticket_status.autoClosed')}</Tag>,
      ],
      [
        (status) => status === TicketStatus.OPEN,
        () => <Tag color="green">{t('ticket_status.open')}</Tag>,
      ],
    ])(status);
  } else {
    return cond([
      [
        (status) => status === TicketStatus.CLOSED || status === TicketStatus.AUTO_CLOSED,
        () => <Tag color="red">{t('ticket_status.closed')}</Tag>,
      ],
      [
        (status) => status === TicketStatus.OPEN,
        () => <Tag color="green">{t('ticket_status.open')}</Tag>,
      ],
    ])(status);
  }
};
