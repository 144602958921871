import './styles.scss';

import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import Box from '@components/utility/box';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal, Typography } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey } from '../../../OfferDetail/components/RejectModal/common';
import { getFormSchema } from './common';

const BulkRejectModal = ({
  open,
  onStartBulkAction,
  onCancel,
  rejectionReasons,
  rejectionDescription,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const rejectionReasonsOptions = createSelectOptions(rejectionReasons, {
    labelKey: 'reason',
    valueKey: 'id',
  });
  const schema = getFormSchema({ rejectionReasonsOptions });

  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
  });

  const handleCancel = () => {
    reset();
    onCancel();
  };

  const handleFinish = (values) => {
    onStartBulkAction(values);
    reset();
  };

  return (
    <Modal
      open={open}
      title={t('rejection.reason.modal.title')}
      className="permission-save-modal"
      onCancel={handleCancel}
      footer={null}
    >
      <Box className="box-primary form-box">
        <AkinonForm layout="vertical">
          <AkinonSelect
            options={rejectionReasonsOptions}
            optionFilterProp="label"
            showSearch
            placeholder={t('rejection.reason')}
            formItemProps={{
              name: FormKey.REJECTION_REASON,
              label: t('rejection.reason'),
              control: control,
              required: true,
            }}
          />
          <AkinonInput
            formItemProps={{
              name: FormKey.REJECTION_EXPLANATION,
              label: t('rejection.reason.explanation'),
              control,
            }}
            placeholder={t('enter_variable', { variable: t('rejection.reason.explanation') })}
          />
          <Typography.Text className="rejection-description">
            {rejectionDescription}
          </Typography.Text>
          <AkinonButton htmlType="submit" type="primary" onClick={handleSubmit(handleFinish)}>
            {t('save')}
          </AkinonButton>
        </AkinonForm>
      </Box>
    </Modal>
  );
};

export default BulkRejectModal;
