import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import If from '@components/If';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { Spin } from 'antd';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigTypeInputMap } from '../../common';

/**
 * @param {{ productFeaturesForm: import('react-hook-form').UseFormReturn, basicProductInfoForm: import('react-hook-form').UseFormReturn, schema: import('zod').ZodObject, variantListAttributeFields: any[] }} props
 */
const ProductFeaturesForm = ({
  productFeaturesForm,
  basicProductInfoForm,
  variantListAttributeFields,
  isOfferProductsReadyToBeDisplayed,
  isVariantDynamicFieldsEmpty,
  setIsOfferProductsReadyToBeDisplayed,
}) => {
  const { t, i18n } = useTranslation('ProductsAndCategories');
  const { control } = productFeaturesForm;

  const formFields = useMemo(() => {
    return variantListAttributeFields?.map((field) => {
      const { data_type, label, required, initialValue, ...restProps } = field;
      const Component = ConfigTypeInputMap[data_type];

      return (
        <AkinonFormItem
          key={field.key}
          label={label}
          name={`productFeaturesForm.${field.key}`}
          id={`productFeaturesForm.${field.key}`}
          initialValue={initialValue}
          control={control}
          required={required}
          requiredMarkContent={`* ${t('required')}`}
          requiredMarkPosition="right"
          disabled={isOfferProductsReadyToBeDisplayed}
          labelCol={{ span: 24 }}
          isDynamicField
        >
          <Component {...restProps} />
        </AkinonFormItem>
      );
    });
  }, [variantListAttributeFields, isOfferProductsReadyToBeDisplayed]);

  const forms = [
    { instance: basicProductInfoForm, root: 'basicProductInfoForm' },
    { instance: productFeaturesForm, root: 'productFeaturesForm' },
  ];

  const onSubmit = useCallback(() => {
    Promise.all(forms.map(({ instance }) => instance.trigger())).then((formsPromiseResult) => {
      const inValidFormIndex = formsPromiseResult.findIndex((result) => !result);
      const areFormsValid = inValidFormIndex === -1;
      if (!areFormsValid) {
        const invalidForm = forms[inValidFormIndex];
        const inValidFormKeys = Object.keys(
          invalidForm.instance.getFieldState(invalidForm.root).error ?? {}
        );
        const firstKey = inValidFormKeys[0];
        invalidForm.instance.setFocus(`${invalidForm.root}.${firstKey}`);
      } else {
        setIsOfferProductsReadyToBeDisplayed(true);
      }
    });
  }, [productFeaturesForm, basicProductInfoForm]);

  return (
    <Box
      className="box-primary form-box"
      title={t('product.features.form.title')}
      subtitle={t('product.features.form.subtitle')}
    >
      <If
        condition={variantListAttributeFields?.length === 0}
        then={<Spin size="large" tip={t('product.features.loading')} />}
        otherwise={
          <AkinonForm name="productFeaturesForm" className="akn-form akn-form-v2" layout="vertical">
            {formFields}

            <Show when={!isOfferProductsReadyToBeDisplayed && isVariantDynamicFieldsEmpty}>
              <AkinonButton type="primary" htmlType="button" onClick={onSubmit}>
                {t('continue').toLocaleUpperCase(i18n.language)}
              </AkinonButton>
            </Show>
          </AkinonForm>
        }
      />
    </Box>
  );
};

export default memo(ProductFeaturesForm);
