import './styles.scss';

import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import { AkinonTextArea } from '@components/AkinonInput';
import { openNotification } from '@components/AkinonNotification';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';
import { UserRole } from '@constants/auth';
import { limitQuery, OfferApprovalStatus } from '@constants/commontypes';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useCreateProductMutation } from '@services/api/hooks/useCreateProductMutation';
import { useRejectionReasonsQuery } from '@services/hooks/common/useRejectionReasonsQuery';
import { usePreOfferQuery } from '@services/hooks/preOffers/usePreOfferQuery';
import { useRejectPreOfferMutation } from '@services/hooks/preOffers/useRejectPreOfferMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider, Modal, Typography } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { FormKey, getFormResolver } from './common';

const { Title } = Typography;

const RejectModal = ({ setRejectModalVisible, isRejectModalVisible, isSubmitPreOfferMutating }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { offerId } = useParams();
  const { preOffer } = usePreOfferQuery({ preOfferId: offerId });

  const navigate = useAppNavigate();

  const { rejectionReasons, isRejectionReasonsLoading } = useRejectionReasonsQuery({
    requestConfig: { params: { ...limitQuery, is_active: true } },
  });

  const rejectionReasonsOptions = createSelectOptions(rejectionReasons, {
    labelKey: 'reason',
    valueKey: 'id',
  });

  const resolver = getFormResolver({ rejectionReasonsOptions });

  const { handleSubmit, control } = useForm({
    resolver,
  });

  const { rejectPreOffer, isRejectPreOfferMutating } = useRejectPreOfferMutation();
  const { createProduct, isCreatingProduct } = useCreateProductMutation({
    mutationOptions: {
      onSuccess() {
        openNotification({
          message: t('operation_successful'),
          description: t('product_created_successfully'),
        });
      },
      onError() {
        openNotification({
          message: t('an_error_occurred'),
          description: t('product_created_error'),
          type: 'error',
        });
      },
      onSettled: () => {
        navigate(RouteUrls.productsAndCategories.offerList.rejected);
      },
    },
  });

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const isSubmitButtonLoading = isSubmitPreOfferMutating || isRejectPreOfferMutating;

  const preOfferApprovalStatus = preOffer?.approval_status;

  const onSubmit = (formValues) => {
    const isAddToCatalog = formValues[FormKey.ADD_TO_CATALOG];
    const rejectionReason = formValues[FormKey.REJECTION_REASON];
    const rejectionExplanation = formValues[FormKey.REJECTION_EXPLANATION];

    if (preOfferApprovalStatus === OfferApprovalStatus.PENDING_APPROVAL) {
      rejectPreOffer(
        {
          preOfferId: preOffer.id,
          requestBody: { reason: rejectionReason, explanation: rejectionExplanation },
          requestConfig: {
            successMessage: t('transaction_success'),
            successDescription: t('reject_modal_message.success'),
            errorMessage: t('transaction_failed'),
            errorDescription: t('reject_modal_message.error'),
          },
        },
        {
          onSuccess() {
            setRejectModalVisible(false);
            if (isAddToCatalog) {
              createProduct({ preOfferId: offerId });
            } else {
              navigate(RouteUrls.productsAndCategories.offerList.rejected);
            }
          },
        }
      );
    } else {
      navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
    }
  };
  const onModalCancel = () => {
    setRejectModalVisible(false);
  };

  return (
    <Modal
      onCancel={onModalCancel}
      className="reject-modal"
      footer={null}
      open={isRejectModalVisible}
      destroyOnClose
    >
      <div className="reject-modal__header">
        <div className="reject-modal__header-badge" />
        <Title className="reject-modal__header-title">{t('rejection.reason')}</Title>
      </div>
      <Divider className="reject-modal__divider" />
      <Typography.Text className="reject-modal__header-description">
        {t('add_to_marketplace_catalog_reject_desc')}
      </Typography.Text>
      <AkinonForm className="ant-form" layout="vertical" {...verticalFormItemLayout}>
        <AkinonSelect
          formItemProps={{
            control,
            name: FormKey.REJECTION_REASON,
            label: t('rejection.reason'),
            required: true,
          }}
          loading={isRejectionReasonsLoading}
          options={rejectionReasonsOptions}
          placeholder={t('rejection.reason')}
        />

        <AkinonTextArea
          formItemProps={{
            control,
            name: FormKey.REJECTION_EXPLANATION,
            label: t('rejection.reason.explanation'),
          }}
          placeholder={t('enter_variable', { variable: t('rejection.reason.explanation') })}
        />
        {isSuperUser && (
          <AkinonSwitch
            formItemProps={{
              control,
              name: FormKey.ADD_TO_CATALOG,
              label: t('add_to_catalog'),
            }}
            checkedChildren={t('yes')}
            unCheckedChildren={t('no')}
          />
        )}
        <AkinonButton
          className="reject-modal__form-submit-button"
          loading={isSubmitButtonLoading || isCreatingProduct}
          htmlType="button"
          type="primary"
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </AkinonButton>
      </AkinonForm>
    </Modal>
  );
};

export default RejectModal;
