import './style.scss';

import { getFormRuleMap } from '@common/form';
import AkinonButton from '@components/AkinonButton';
import { DraggerInputWithLabel } from '@components/AkinonDragger';
import Box from '@components/utility/box/index';
import { QueryKey } from '@constants/query';
import { FormField } from '@pages/ProductsAndCategories/BulkActions/common';
import { BulkActionsProgressModal } from '@pages/ProductsAndCategories/BulkActions/components/BulkActionsProgressModal';
import { useBulkImportPackagesMutation } from '@services/api/hooks/useBulkImportPackagesMutation';
import { useDownloadBulkPackagesTemplateMutation } from '@services/api/hooks/useDownloadBulkPackagesTemplateMutation';
import { queryClient } from '@services/api/queryClient';
import { downloadBinaryFile } from '@utils/index';
import { Divider, Form } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BulkUpdatePackages = () => {
  const { t } = useTranslation('Orders');
  const formRuleMap = getFormRuleMap({ t });
  const [form] = Form.useForm();

  const {
    bulkImportPackages,
    isBulkImportingPackages,
    isBulkImportPackagesError,
    isBulkImportPackagesSuccess,
    bulkImportPackagesData,
    resetBulkImportPackages,
  } = useBulkImportPackagesMutation({
    mutationOptions: {
      onSuccess: async ({ key }) => {
        await queryClient.invalidateQueries({
          queryKey: [QueryKey.IMPORT_STATUS, key],
        });
      },
    },
  });

  const handleFileUpload = () => {
    form.validateFields([FormField.FILE]).then(({ file }) => {
      const formData = new FormData();

      file.fileList.forEach((file) => {
        formData.append(FormField.FILE, file.originFileObj);
      });

      bulkImportPackages({
        formData,
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('packages_bulk_message.success'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('packages_bulk_message.error'),
        },
      });
    });
  };

  const { downloadBulkPackagesTemplate, isDownloadBulkPackagesTemplateMutating } =
    useDownloadBulkPackagesTemplateMutation({});

  const downloadTemplate = () => {
    downloadBulkPackagesTemplate(
      {},
      {
        onSuccess: (data) => {
          const filename = 'packages.xlsx';
          downloadBinaryFile(data, filename);
        },
      }
    );
  };

  return (
    <>
      <div className="bulk-actions mb-8">
        <Box when className="box-primary form-box bulk" title={t('bulk_actions_packages')}>
          <Form className="ant-form" form={form} layout="vertical">
            <div className="download-template">
              <span>{t('bulk.actions.download')}</span>
              <AkinonButton
                loading={isDownloadBulkPackagesTemplateMutating}
                type="default"
                icon={<i className="icon icon-download" />}
                size="small"
                onClick={downloadTemplate}
              >
                XLSX
              </AkinonButton>
            </div>

            <Divider />

            <Form.Item
              name="file"
              rules={[formRuleMap.file()]}
              label={t('bulk_actions_upload_packages')}
            >
              <DraggerInputWithLabel
                accept=".csv,.xlsx,.xls"
                singleAction
                multiple={false}
                beforeUpload={() => false}
              />
            </Form.Item>

            <div className="actions">
              <AkinonButton
                loading={isBulkImportingPackages}
                style={{
                  marginTop: '24px',
                  fontWeight: '600',
                  fontSize: '14px',
                }}
                type="primary"
                onClick={handleFileUpload}
              >
                {t('save')}
              </AkinonButton>
            </div>
          </Form>
        </Box>
      </div>
      <BulkActionsProgressModal
        bulkImportKey={bulkImportPackagesData?.key}
        open={!isBulkImportPackagesError && isBulkImportPackagesSuccess}
        onCancel={() => {
          resetBulkImportPackages();
          form.resetFields();
        }}
        messages={{
          successCount: ({ succeededCount }) =>
            t('package_import_progress_succeeded_count', {
              count: succeededCount,
            }),
          failCount: ({ failedCount }) =>
            t('package_import_progress_failed_count', {
              count: failedCount,
            }),
          finishButtonText: t('go_to_created_items', {
            to: t('to_package'),
            ns: 'ProductsAndCategories',
          }),
        }}
      />
    </>
  );
};

export default BulkUpdatePackages;
