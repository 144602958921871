import AkinonSpin from '@components/AkinonSpin';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import values from 'lodash/values';
import React from 'react';

const AddressInfo = ({ t, billingAddress, packageInfo, isPackageInfoLoading }) => {
  const formattedBillingAddress = !isEmpty(billingAddress)
    ? `${billingAddress?.first_name} ${billingAddress?.last_name}\n${
        billingAddress?.phone_number
      }\n${billingAddress?.line}\n${values(
        pick(packageInfo, ['billingTownship', 'billingDistrict', 'billingCity', 'billingCountry'])
      )
        .map((address) => address?.name)
        .join('/')}`
    : '-';

  return (
    <AkinonSpin spinning={isPackageInfoLoading}>
      <div className="detail-item">
        <div className="detail-item">
          <div className="detail-item--title">{t('shipping_address')}</div>
          <span className="detail-item--desc">{formattedBillingAddress}</span>
        </div>
      </div>
    </AkinonSpin>
  );
};

export default AddressInfo;
