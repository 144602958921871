import { AttributeDataType, attributeWidgetMap, WidgetType } from '@constants/commontypes';
import { cond, constant, matches, stubTrue } from 'lodash';
import { useMemo } from 'react';

import { getDynamicFilterWidgetProps } from './common';

const generateDynamicFilterKey = ({ prefixPairs, suffixPairs, widget, name }) => {
  const dynamicKeyPrefix = cond(prefixPairs)(widget);
  const dynamicKeySuffix = cond(suffixPairs)(widget);

  return [dynamicKeyPrefix, name, dynamicKeySuffix].filter(Boolean).join('__');
};

export function useDynamicFilterFields({ attributes, isAttributesLoading }) {
  const fields = useMemo(
    () =>
      attributes?.map(({ id, key, name, data_type }) => {
        const widget = attributeWidgetMap[data_type];
        const filterName = data_type !== AttributeDataType.DROPDOWN ? `${key}__icontains` : key;
        const dynamicFilterName = generateDynamicFilterKey({
          prefixPairs: [[stubTrue, constant('attributes')]],
          suffixPairs: [[matches(WidgetType.ASYNC_SELECT), constant('exact')]],
          widget,
          name: filterName,
        });
        const widgetProps = getDynamicFilterWidgetProps({
          widget,
          key,
          id,
        });

        return {
          id,
          name: dynamicFilterName,
          key,
          widget,
          data_type,
          options: [],
          placeholder: name,
          ...widgetProps,
        };
      }) ?? [],
    [attributes]
  );

  return useMemo(
    () => ({
      fields: fields,
      isLoading: isAttributesLoading,
    }),
    [fields, isAttributesLoading]
  );
}
