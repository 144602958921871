import AkinonSpin from '@components/AkinonSpin';
import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import Box from '@components/utility/box';
import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { IconCategoryFilled, IconTag } from '@tabler/icons-react';
import { useCurrentStep } from '@utils/hooks/useCurrentStep';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CategoryPermissions from './CategoryPermissions';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import ProductPermissions from './ProductPermissions';

const StepKey = {
  CATEGORY_PERMISSIONS: 'categoryPermissions',
  PRODUCT_PERMISSONS: 'productPermissions',
};

const getStepList = ({ t }) => [
  {
    title: t('category_permissions'),
    icon: <IconCategoryFilled size={20} />,
    key: StepKey.CATEGORY_PERMISSIONS,
    Component: CategoryPermissions,
  },

  {
    title: t('product_permissions'),
    icon: <IconTag size={20} style={{ transform: 'rotate(90deg)' }} />,
    key: StepKey.PRODUCT_PERMISSONS,
    Component: ProductPermissions,
  },
];

function Permissions() {
  const { t } = useTranslation('Finance');
  const { id: dataSourceId } = useParams();
  const { dataSourceDetail, isDataSourceDetailLoading } = useDataSourceDetailQuery({
    dataSourceId,
    queryOptions: {
      enabled: Boolean(dataSourceId),
    },
  });

  const { currentStep, setCurrentStep } = useCurrentStep({
    stepCount: 2,
    fallbackStep: 0,
  });

  const stepList = getStepList({ t });
  const { Component: StepComponent = () => null } = stepList[currentStep] ?? {};

  useBreadcrumbs();

  return (
    <section className="seller-management-detail">
      <AkinonSpin spinning={isDataSourceDetailLoading} wrapperClassName="max-w-max min-w-72">
        <Box className="seller-management-detail__header">
          <div className="seller-management-detail__header-info">
            <div>
              {t('seller')}: {dataSourceDetail?.name}
            </div>
          </div>
        </Box>
      </AkinonSpin>
      <AkinonSteps current={currentStep} onChange={setCurrentStep}>
        {stepList.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>

      <StepComponent />
    </section>
  );
}

export default Permissions;
