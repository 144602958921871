import './style.scss';

import { getRequestPaginationParams } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import { AkinonTextArea } from '@components/AkinonInput';
import AkinonSpin from '@components/AkinonSpin';
import AkinonPagination from '@components/AkinonTable/components/AkinonPagination';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getAgreementDownloadUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { Color } from '@constants/theme';
import { client } from '@services/api/client';
import { useAgreementsQuery } from '@services/api/hooks/useAgreementsQuery';
import { usePostAgreementConsentMutation } from '@services/api/hooks/usePostAgreementConsentMutation';
import { IconInfoCircle, IconPaperclip } from '@tabler/icons-react';
import { useUserRole } from '@utils/hooks/useUserRole';
import { dateFormatter, downloadBinaryFile, getFilenameFromURL } from '@utils/index';
import { Tooltip, Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

function AgreementBox() {
  const { t } = useTranslation('Finance');
  const [isDownloading, setIsDownloading] = useState(false);

  const userRole = useUserRole();
  const [pagination, setPagination] = usePagination({ pageSize: 1, pageSizeOptions: [] });
  const { agreements, isFetchingAgreements, totalAgreements, refetchAgreements } =
    useAgreementsQuery({
      queryOptions: {
        enabled: userRole === UserRole.SELLER,
      },
      params: {
        ...getRequestPaginationParams(pagination),
      },
    });

  const { acceptAgreementConsent, isAgreementConsentAccepting } = usePostAgreementConsentMutation();

  const startDownload = async (agreement) => {
    const url = agreement.file;
    setIsDownloading(true);
    const config = {
      responseType: 'blob',
      urlParams: {
        id: agreement.id,
      },
    };
    try {
      const data = await client.get(getAgreementDownloadUrl, config);
      downloadBinaryFile(data, getFilenameFromURL(url));
    } catch (e) {
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <AkinonBox
      title={t('agreement')}
      description={t('agreement_description')}
      bannerColor={Color.WILD_WATERMELON}
      className="box-primary form-box h-min agreement-box"
      titleClassName="mb-4"
      descriptionClassName="mb-8"
      shadow
    >
      <AkinonFlex className="gap-4" direction="column">
        <AkinonSpin spinning={isFetchingAgreements}>
          {!isFetchingAgreements &&
            agreements?.map((agreement) => (
              <AkinonFlex key={agreement.id} className="gap-4" direction="column">
                <AkinonFlex alignItems="center" className="gap-4">
                  <AkinonFlex
                    className="gap-2 bg-ebony-clay w-max p-3 border border-green-400 cursor-pointer rounded"
                    onClick={async () => await startDownload(agreement)}
                  >
                    <AkinonSpin spinning={isDownloading} tip="" size="small">
                      <IconPaperclip size={16} className="text-dodger-blue" />
                    </AkinonSpin>
                    <Text className="text-white">{getFilenameFromURL(agreement.file)}</Text>
                  </AkinonFlex>
                  <AkinonFlex alignItems="center">
                    <Tooltip
                      title={
                        <Text className="text-astra">
                          {agreement.consented_at
                            ? t('approved_consented_at', {
                                date: dateFormatter(agreement.consented_at),
                              })
                            : t('waiting_approval')}
                        </Text>
                      }
                    >
                      {agreement.consented_at ? (
                        <Text className="text-astra">
                          {t('consented_at').concat(' : ', dateFormatter(agreement.consented_at))}
                        </Text>
                      ) : (
                        <IconInfoCircle size={16} className="mr-2 text-astra" />
                      )}
                    </Tooltip>
                  </AkinonFlex>
                </AkinonFlex>

                <AkinonTextArea
                  formItemProps={{
                    label: t('description'),
                    placeholder: t('description'),
                    ...verticalFormItemLayout,
                  }}
                  value={agreement.description}
                  readOnly
                />
                <AkinonFlex justifyContent="space-between" alignItems="center">
                  <AkinonButton
                    className="w-1/3"
                    type="primary"
                    loading={isAgreementConsentAccepting}
                    disabled={agreement.consented_at}
                    onClick={() => {
                      acceptAgreementConsent(
                        {
                          agreementId: agreement.id,
                          requestConfig: {
                            successMessage: t('agreement_accepted'),
                            successDescription: t('agreement_accepted_description'),
                          },
                        },
                        {
                          onSuccess: () => {
                            if (pagination.current < totalAgreements) {
                              setPagination({ ...pagination, current: pagination.current + 1 });
                            } else {
                              refetchAgreements();
                            }
                          },
                        }
                      );
                    }}
                  >
                    {t('accept')}
                  </AkinonButton>
                  <AkinonPagination
                    pagination={{
                      ...pagination,
                      total: totalAgreements,
                      className: 'bg-white',
                    }}
                    setPagination={setPagination}
                  />
                </AkinonFlex>
              </AkinonFlex>
            ))}
        </AkinonSpin>
      </AkinonFlex>
    </AkinonBox>
  );
}

export default AgreementBox;
