import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import { Tag, Tooltip, Typography } from 'antd';
import { generatePath } from 'react-router-dom';

const { Text } = Typography;

const DataIndex = {
  NAME: 'name',
  DESCRIPTION: 'description',
  ID: 'id',
  STATUS: 'status',
};

const AgreementStatus = {
  PASSIVE: 0,
  ACTIVE: 1,
  DRAFT: 2,
};

export const getAgreementStatusMap = ({ t }) => {
  return {
    [AgreementStatus.PASSIVE]: {
      label: t('passive'),
      tagColor: 'red',
    },
    [AgreementStatus.ACTIVE]: {
      label: t('active'),
      tagColor: 'green',
    },
    [AgreementStatus.DRAFT]: {
      label: t('draft'),
      tagColor: 'yellow',
    },
  };
};

export const getColumns = ({ t }) => {
  const agreementStatusMap = getAgreementStatusMap({ t });
  return [
    {
      title: t('name'),
      dataIndex: DataIndex.NAME,
      render: (name, { id }) => {
        return (
          <Text
            ellipsis={{
              tooltip: <span>{name}</span>,
            }}
          >
            <Link
              to={generatePath(RouteUrls.finance.agreementDetail, {
                agreementId: id,
              })}
            >
              {name}
            </Link>
          </Text>
        );
      },
    },
    {
      title: t('description'),
      dataIndex: DataIndex.DESCRIPTION,
      render: (description) => {
        return (
          <Text
            ellipsis={{
              tooltip: <span>{description}</span>,
            }}
          >
            {description}
          </Text>
        );
      },
    },
    {
      title: t('status'),
      dataIndex: DataIndex.STATUS,
      render: (status) => {
        return (
          <Tooltip title={agreementStatusMap[status].label}>
            <Tag color={agreementStatusMap[status].tagColor}>
              {agreementStatusMap[status].label}
            </Tag>
          </Tooltip>
        );
      },
    },
  ];
};
