import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { preOffersUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useQuery } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { client } from '../client';

const fetchPreOffers = async (queryParams, fileFilter) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await client.post(preOffersUrl, formData, {
      params: queryParams,
      headers: { 'Origin-Method': HttpMethod.GET },
    });
  }
  return await client.get(preOffersUrl, { params: queryParams });
};

export const usePaginatedPreOffersQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
  onError,
} = {}) => {
  const { approvalStatus, is_active = true } = params;
  const approvalStatusKey = approvalStatus?.includes?.(',')
    ? 'approval_status__in'
    : 'approval_status';

  const { data, isFetching, error, refetch } = useQuery({
    queryKey: [QueryKey.PRE_OFFERS, approvalStatus, pagination, filters, params],
    async queryFn() {
      try {
        const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
        const filterParams = getRequestFiltersParams(filters);
        delete filterParams?.filter_file;
        const queryParams = {
          [approvalStatusKey]: approvalStatus,
          is_active,
          ...getRequestPaginationParams(pagination),
          ...omit(filterParams, ['approval_status', 'approval_status__in']),
          ...omit(params, ['approvalStatus', 'is_active']),
        };
        if (queryParams?.approval_status) delete queryParams.approval_status__in;

        const response = await fetchPreOffers(queryParams, fileFilter);
        onSuccess?.(response);
        return response;
      } catch (error) {
        onError?.(error);
      }
    },
    ...queryOptions,
  });

  return {
    preOffersResponse: data,
    preOffers: data?.results,
    totalPreOffers: data?.count,
    isFetchingPreOffers: isFetching,
    preOffersError: error,
    refetchPreOffers: refetch,
  };
};
