import { openNotification } from '@components/AkinonNotification';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const useRequestTimeoutNotificationOnce = ({ error }) => {
  const { t } = useTranslation();
  const [didShowTimeoutError, setDidShowTimeoutError] = useState(false);

  useEffect(() => {
    if (error?.code === 'ECONNABORTED' && !didShowTimeoutError) {
      openNotification({
        type: 'error',
        message: t('api.request.timeoutErrorMessage'),
        description: t('api.request.timeoutErrorMessageDesc'),
      });
      setDidShowTimeoutError(true);
    }
  }, [error]);

  const reset = () => {
    setDidShowTimeoutError(false);
  };

  return {
    hasTimedOut: didShowTimeoutError,
    reset,
  };
};

export default useRequestTimeoutNotificationOnce;
