import './style.scss';

import React from 'react';
import { useParams } from 'react-router-dom';

import { MessagesBox } from './components/MessagesBox';
import { OrderInformationBox } from './components/OrderInformationBox';
import { ReplyMessageBox } from './components/ReplyMessageBox';
import { useConversationAndOrdersQuery } from './hooks/api/useConversationAndOrdersQuery';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const OrderAnswer = () => {
  const { id } = useParams();

  useBreadcrumbs();
  usePageMeta();

  const {
    conversation,
    order,
    product,
    isConversationLoading,
    isOrdersLoading,
    isFetchingProduct,
  } = useConversationAndOrdersQuery({
    conversationId: id,
  });

  return (
    <section className="order-answer-detail">
      <div className="grid grid-cols-12 gap-8">
        <div
          direction="column"
          className="flex flex-col col-span-12 lg:col-span-8 xl:col-span-6 gap-8"
        >
          <MessagesBox isConversationLoading={isConversationLoading} conversation={conversation} />
          <ReplyMessageBox conversationId={id} />
        </div>
        <div style={{ maxHeight: 500 }} className="col-span-12 lg:col-span-6 xl:col-span-4">
          <OrderInformationBox
            conversation={conversation}
            order={order}
            product={product}
            isLoading={isOrdersLoading || isConversationLoading || isFetchingProduct}
          />
        </div>
      </div>
    </section>
  );
};

export default OrderAnswer;
