import * as React from 'react';

const IconStoreTopWarn = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={300} height={20} viewBox="0 0 300 20" {...props}>
    <g fill="#FFC500" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M16.922.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1A.75.75 0 0 1 1 .25h15.922Zm-.751 1.5H1.75v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M32.844.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.751 1.5H17.672v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M48.765.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.421 0V1a.75.75 0 0 1 .75-.75h15.921Zm-.75 1.5H33.594v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M64.687.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.75 1.5H49.514v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.211-7.21V1.75Z" />
        <path d="M80.609.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75H80.61Zm-.75 1.5H65.436v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.211-7.21V1.75Z" />
        <path d="M96.53.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.421 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.75 1.5H81.359v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M112.453.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.751 1.5H97.28v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M128.374.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.421 0V1a.75.75 0 0 1 .75-.75h15.921Zm-.75 1.5h-14.421v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M144.296.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.75 1.5h-14.422v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.211-7.21V1.75Z" />
        <path d="M160.218.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.75 1.5h-14.422v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.211-7.21V1.75Z" />
        <path d="M176.14.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.751 1.5h-14.421v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
        <path d="M192.062.25a.75.75 0 0 1 .75.75v3.04a8.71 8.71 0 1 1-17.422 0V1a.75.75 0 0 1 .75-.75h15.922Zm-.751 1.5H176.89v2.29a7.211 7.211 0 0 0 6.968 7.206l.243.004a7.21 7.21 0 0 0 7.21-7.21V1.75Z" />
      </g>
      <path
        fillOpacity={0.2}
        d="M40.805 12a7.961 7.961 0 0 1-7.961-7.96V1h15.921v3.04a7.96 7.96 0 0 1-7.96 7.96ZM72.648 12a7.961 7.961 0 0 1-7.96-7.96V1h15.921v3.04a7.96 7.96 0 0 1-7.96 7.96ZM104.492 12a7.961 7.961 0 0 1-7.961-7.96V1h15.922v3.04a7.96 7.96 0 0 1-7.961 7.96ZM136.335 12a7.961 7.961 0 0 1-7.96-7.96V1h15.921v3.04a7.96 7.96 0 0 1-7.96 7.96ZM168.179 12a7.961 7.961 0 0 1-7.961-7.96V1h15.922v3.04a7.96 7.96 0 0 1-7.961 7.96Z"
      />
    </g>
  </svg>
);
export default IconStoreTopWarn;
