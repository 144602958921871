import { useDataSourceDetail } from '@services/hooks/datasources/useDataSourceDetail';
import { useReconciliationDetail } from '@services/hooks/finance/useReconciliationDetail';

export const useMergedInfo = ({ reconciliationId }) => {
  const {
    data: reconciliation,
    isLoading,
    refetch,
  } = useReconciliationDetail({
    reconciliationId,
  });
  const { data: dataSource } = useDataSourceDetail({
    dataSourceId: reconciliation?.datasource_id,
    enabled: isLoading,
  });

  if (reconciliation) reconciliation.datasource = dataSource;

  return {
    reconciliation,
    isReconciliationLoading: isLoading,
    refetchDetail: refetch,
  };
};
