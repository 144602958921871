import { zodResolver } from '@hookform/resolvers/zod';
import { getFilenameFromURL } from '@utils/index';
import * as z from 'zod';

export const AgreementVisibility = {
  PUBLIC: 1,
  RESTRICTED: 2,
};

export const AgreementStatus = {
  PASSIVE: 0,
  ACTIVE: 1,
  DRAFT: 2,
};

export const FormKey = {
  NAME: 'name',
  VISIBILITY: 'visibility',
  STATUS: 'status',
  CONSENTS: 'consents',
  FILE: 'file',
  DESCRIPTION: 'description',
};
const size10MB = 1024 * 1024 * 10;

export const getFormResolver = ({ t, dataSourceOptions, agreementDetail }) => {
  const schema = z
    .object({
      [FormKey.STATUS]: z.union([
        z.literal(AgreementStatus.ACTIVE),
        z.literal(AgreementStatus.PASSIVE),
        z.literal(AgreementStatus.DRAFT),
      ]),
    })
    .extend(
      agreementDetail?.status !== AgreementStatus.ACTIVE &&
        z.object({
          [FormKey.NAME]: z.string().min(1),
          [FormKey.DESCRIPTION]: z.string().optional(),
          [FormKey.FILE]: z.object({
            fileList: z
              .array(
                z.object({
                  url: z.string().optional(),
                  size: z
                    .number()
                    .max(size10MB, { message: t('file_size_error_message', { fileSize: '10MB' }) })
                    .optional(),
                  originFileObj: z.instanceof(File).optional(),
                })
              )
              .min(1),
          }),
        }).shape
    );

  return zodResolver(
    z
      .discriminatedUnion(FormKey.VISIBILITY, [
        z.object({
          [FormKey.VISIBILITY]: z.literal(true),
        }),
        z.object({
          [FormKey.VISIBILITY]: z.literal(false),
          [FormKey.CONSENTS]: z
            .union(dataSourceOptions.map((dataSource) => z.literal(dataSource.value)))
            .array()
            .min(1, { message: t('consents_error_message', { at_least: 1 }) }),
        }),
      ])
      .and(schema)
  );
};

export const getFormInitialValues = ({ agreementDetail, agreementConsents }) => {
  if (agreementDetail) {
    return {
      ...agreementDetail,
      [FormKey.FILE]: {
        fileList: [
          {
            uid: '-1',
            name: getFilenameFromURL(agreementDetail?.file),
            status: 'done',
            url: agreementDetail?.file,
          },
        ],
      },
      [FormKey.DESCRIPTION]: '',
      [FormKey.VISIBILITY]: agreementDetail?.visibility === AgreementVisibility.PUBLIC,
      [FormKey.CONSENTS]: agreementConsents?.map((consent) => consent.datasource),
    };
  }
  return {
    [FormKey.VISIBILITY]: false,
    [FormKey.CONSENTS]: [],
  };
};

export const getFormData = (data) => {
  const formData = new FormData();

  for (const key in data) {
    if (key === FormKey.CONSENTS) {
      continue;
    } else if (key === FormKey.FILE) {
      if (!Boolean(data[key]?.fileList?.[0].originFileObj)) {
        continue;
      }
      formData.append(key, data[key]?.fileList?.[0].originFileObj);
      continue;
    } else if (key === FormKey.DESCRIPTION && !data[key]) {
      continue;
    } else if (key === FormKey.VISIBILITY) {
      formData.append(key, data[key] ? AgreementVisibility.PUBLIC : AgreementVisibility.RESTRICTED);
      continue;
    } else formData.append(key, data[key]);
  }

  return formData;
};
