import { findElementByInnerText } from '@common/index';
import { createTableActions } from '@components/AkinonTable/common';
import CopyButton from '@components/CopyButton';
import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import TitleWithHint from '@components/TitleWithHint';
import i18n from '@root/i18n';
import { Button, Tooltip, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { Trans } from 'react-i18next';

import { ApprovalStatus, StaticFilterKey } from '../common';

export const getTourSteps = ({ t, i18n }) => {
  return [
    {
      selector: '.filter-options',
      content: (
        <>
          <Typography.Title level={4}>{t('pending_offers_guide_step_1_title')}</Typography.Title>
          <Typography.Text>{t('pending_offers_guide_step_1_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.standard-akinon-table-header__title',
      content: (
        <>
          <Typography.Title level={4}>{t('pending_offers_guide_step_2_title')}</Typography.Title>
          <Typography.Text>{t('pending_offers_guide_step_2_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.akinon-table-actions',
      content: (
        <>
          <Typography.Title level={4}>{t('pending_offers_guide_step_3_title')}</Typography.Title>
          <Typography.Text>{t('pending_offers_guide_step_3_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: findElementByInnerText(t('offer.status').toLocaleUpperCase(i18n.language), 'th'),
      content: (
        <>
          <Typography.Title level={4}>{t('pending_offers_guide_step_4_title')}</Typography.Title>
          <Typography.Text>
            <Trans i18nKey={t('pending_offers_guide_step_4_desc')} />
          </Typography.Text>
        </>
      ),
    },

    {
      selector: '.standard-akinon-table-header__export-option',
      content: (
        <>
          <Typography.Title level={4}>{t('pending_offers_guide_step_5_title')}</Typography.Title>
          <Typography.Text>{t('pending_offers_guide_step_5_desc')}</Typography.Text>
        </>
      ),
    },
  ];
};
export const DataIndex = {
  IMAGE: ['image', 'image'],
  SELLER: {
    NAME: ['seller', 'name'],
  },
  NAME: 'name',
  BASE_CODE: 'base_code',
  SKU: 'sku',
  CATEGORY: ['category', 'path'],
  PRICES: {
    RETAIL_PRICE: ['prices', '0', 'retail_price'],
    PRICE: ['prices', '0', 'price'],
    CURRENCY: ['prices', '0', 'currency_type'],
  },
  REFERENCE_PRICE: 'buybox_price',
  STOCKS: {
    STOCK: ['stocks', '0', 'stock'],
  },
  PRODUCT: {
    SKU: ['product', 'sku'],
  },
  APPROVAL_STATUS: 'approval_status',
  REASON: ['reason', 'reason'],
};

const ExportKey = {
  SELLER: {
    NAME: 'seller',
  },
  NAME: 'product_name',
  BASE_CODE: 'base_code',
  SKU: 'sku',
  PRICES: {
    RETAIL_PRICE: 'retail_price',
    PRICE: 'price',
    CURRENCY: 'currency',
  },
  REFERENCE_PRICE: 'reference_price',
  STOCKS: {
    STOCK: 'stock',
  },
  PRODUCT: {
    SKU: 'related_product_sku',
  },
  APPROVAL_STATUS: 'approval_status',
  CATEGORY: 'category',
};

export const getColumns = ({ t, isSuperUser, approveSingleOffer, rejectSingleOffer, isMobile }) => {
  return [
    {
      dataIndex: DataIndex.IMAGE,
      visibleOnFilter: [StaticFilterKey.IMAGE_ISNULL],
      title: <ImageTableHead />,
      render: (image) => <SmallColumnImage src={image} />,
      align: 'center',
      width: 100,
    },
    isSuperUser && {
      dataIndex: DataIndex.SELLER.NAME,
      exportKey: ExportKey.SELLER.NAME,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      exportTitle: t('seller'),
      title: t('seller'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.NAME],
      exportKey: ExportKey.NAME,
      exportTitle: t('product_name'),
      title: t('product_name'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.BASE_CODE,
      visibleOnFilter: [StaticFilterKey.BASE_CODE],
      exportKey: ExportKey.BASE_CODE,
      exportTitle: 'Base Code',
      title: 'Base Code',
      className: 'uppercase',
      render: (baseCode) => <Tooltip title={baseCode}>{baseCode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.SKU,
      exportKey: ExportKey.SKU,
      visibleOnFilter: [StaticFilterKey.SKU],
      exportTitle: t('sku'),
      title: t('sku'),
      className: 'uppercase',
      render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
    },
    {
      dataIndex: DataIndex.CATEGORY,
      exportKey: ExportKey.CATEGORY,
      visibleOnFilter: [StaticFilterKey.CATEGORY],
      title: t('category'),
      render: (category) => <Tooltip title={category}>{category}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRICES.RETAIL_PRICE,
      exportKey: ExportKey.PRICES.RETAIL_PRICE,
      title: t('psf'),
    },
    {
      dataIndex: DataIndex.PRICES.PRICE,
      exportKey: ExportKey.PRICES.PRICE,
      title: t('price'),
    },
    {
      dataIndex: DataIndex.REFERENCE_PRICE,
      exportKey: ExportKey.REFERENCE_PRICE,
      exportTitle: t('reference_price'),
      title: (
        <TitleWithHint
          text={t('reference_price').toLocaleUpperCase(i18n.language)}
          hint={t('reference_price_desc')}
        />
      ),
    },
    {
      dataIndex: DataIndex.PRICES.CURRENCY,
      exportKey: ExportKey.PRICES.CURRENCY,
      title: t('currency'),
    },
    {
      dataIndex: DataIndex.STOCKS.STOCK,
      exportKey: ExportKey.STOCKS.STOCK,
      title: t('stock'),
      render: (stock) => <Tooltip title={stock}>{stock}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT.SKU,
      exportKey: ExportKey.PRODUCT.SKU,
      title: t('sku.related'),
      className: 'uppercase',
      render: (data) =>
        data ? <CopyButton tooltipVisible={true} text={data} label={data} isColumn /> : '-',
    },
    {
      dataIndex: DataIndex.APPROVAL_STATUS,
      exportKey: ExportKey.APPROVAL_STATUS,
      visibleOnFilter: [StaticFilterKey.APPROVAL_STATUS],
      fixed: isMobile ? false : 'right',
      className: isSuperUser ? 'offer-list__status_column' : '',
      exportTitle: t('offer.status'),
      title: t('offer.status'),
      width: 250,
      render: (approvalStatus) => {
        switch (approvalStatus) {
          case ApprovalStatus.SUBMITTED:
            return <div className="offer-status approval-submitted">{t('approval.submitted')}</div>;
          case ApprovalStatus.WAITING_FOR_APPROVAL:
            return (
              <div className="offer-status approval-waiting">
                {isSuperUser ? t('approval_waiting') : t('approval_waiting_seller')}
              </div>
            );

          case ApprovalStatus.SENDING:
          case ApprovalStatus.SUBMITTING_FOR_APPROVAL:
          case ApprovalStatus.APPROVAL_IN_PROGRESS:
            return <div className="offer-status approval-sending">{t('processing_approval')}</div>;
          default:
            return null;
        }
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.REASON,
      title: t('offer.actions'),
      fixed: isMobile ? false : 'right',
      className: 'cancel-refund-reason-column',
      width: 250,
      render: (_, record) => {
        if (record.approval_status === ApprovalStatus.WAITING_FOR_APPROVAL) return null;
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 6 }}>
              <Button
                type="primary"
                className="action-button approve-button"
                onClick={async (e) => {
                  e.stopPropagation();
                  await approveSingleOffer(record, record.approval_status);
                }}
                size="small"
              >
                {t('approve')}
              </Button>
              <Button
                type="danger"
                className="action-button reject-button"
                size="small"
                onClick={async (e) => {
                  e.stopPropagation();
                  await rejectSingleOffer(record);
                }}
              >
                {t('deny')}
              </Button>
            </div>
          </div>
        );
      },
    },
  ];
};

export const getTableActions = ({
  t,
  selectedRowKeys,
  isSuperUser,
  onShowApproveOffersModal,
  onShowRejectOffersModal,
  onShowSubmitOffersModal,
  onShowBulkApproveModal,
  onShowBulkRejectModal,
}) =>
  createTableActions(
    isSuperUser
      ? [
          {
            label: (selectedRows) =>
              t(isEmpty(selectedRows) ? 'approve_all_offer_items' : 'approve_selected_offer_items'),
            async actionHandler(selectedRows) {
              !isEmpty(selectedRows)
                ? onShowApproveOffersModal(selectedRows)
                : onShowBulkApproveModal();
            },
          },
          {
            label: (selectedRows) =>
              t(isEmpty(selectedRows) ? 'reject_all_offer_items' : 'reject_selected_offer_items'),
            actionHandler(selectedRows) {
              !isEmpty(selectedRows)
                ? onShowRejectOffersModal(selectedRows)
                : onShowBulkRejectModal();
            },
          },
        ]
      : [
          {
            label: t('submit_selected_offer_items'),
            async actionHandler(selectedRows) {
              selectedRowKeys?.length > 0 && onShowSubmitOffersModal(selectedRows);
            },
            isSelectionRequired: true,
          },
        ]
  );
