import { getCategoryNodesUrl } from '@constants/apiUrls';
import { WidgetType } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export function useStaticFilterFields({ product }) {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      fields: [
        {
          name: StaticFilterKey.NAME,
          widget: WidgetType.INPUT,
          placeholder: t('name'),
        },
        {
          name: StaticFilterKey.BASE_CODE,
          widget: WidgetType.INPUT,
          placeholder: t('base.code'),
        },
        {
          name: StaticFilterKey.SKU,
          widget: WidgetType.INPUT,
          placeholder: t('sku'),
          initialValue: product?.sku,
        },
        {
          name: StaticFilterKey.CATEGORY,
          widget: WidgetType.ASYNC_SELECT,
          placeholder: t('category'),
          showSearch: true,
          labelInValue: true,
          queryProps: {
            remoteKey: QueryKey.CATEGORIES,
            remoteUrl: getCategoryNodesUrl,
            params: {
              limit: 20,
              sort: 'name',
            },
            searchKey: 'name__icontains',
          },
        },
      ],
    }),
    [t, product]
  );
}
