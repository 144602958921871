import AsyncSelect from '@components/AkinonSelect/AsyncSelect';
import { getCancellationReasonsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useDynamicSettingsRegistryQuery } from '@pages/Settings/DynamicSettings/hooks/api/useDynamicSettingsRegistryQuery';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { Modal, Typography } from 'antd';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const BulkRejectOrderItemsModal = ({ open, onStartBulkAction, onCancel, title }) => {
  const { t } = useTranslation('Orders');

  const [cancellationReason, setCancellationReason] = useState(undefined);

  const { dynamicSettings, isDynamicSettingsError } = useDynamicSettingsQuery({
    params: {
      key: 'OMNITRON_ALLOWED_CANCELLATION_REASON_IDS',
    },
  });

  const isDynamicSettingFound = !isDynamicSettingsError && dynamicSettings?.length > 0;
  const { dynamicSettingsRegistry } = useDynamicSettingsRegistryQuery({
    queryOptions: {
      enabled: !isDynamicSettingFound,
    },
  });

  const allowedCancellationIds = get(
    find(isDynamicSettingFound ? dynamicSettings : dynamicSettingsRegistry, {
      key: 'OMNITRON_ALLOWED_CANCELLATION_REASON_IDS',
    }),
    isDynamicSettingFound ? 'value' : 'default'
  );

  return (
    <Modal
      open={open}
      title={title}
      destroyOnClose
      onCancel={() => {
        onCancel();
        setCancellationReason(undefined);
      }}
      onOk={() => {
        onStartBulkAction({
          cancellationReason,
        });
        setCancellationReason(undefined);
      }}
    >
      <div className="flex flex-col gap-2">
        <Text className="text-white">{t('cancellation_reason')}</Text>
        <AsyncSelect
          valueKey="id"
          labelKey="subject"
          placeholder={t('cancellation_reason')}
          onChange={(value) => {
            setCancellationReason(value);
          }}
          value={cancellationReason}
          queryProps={{
            remoteUrl: getCancellationReasonsUrl,
            remoteKey: QueryKey.CANCELLATION_REASONS,
            searchKey: 'subject__icontains',
            params: {
              is_active: true,
              remote_id__in: allowedCancellationIds?.join?.(','),
              limit: 20,
              sort: 'subject',
            },
            queryOptions: {
              enabled: !isEmpty(allowedCancellationIds),
            },
          }}
        />
        <Text className="text-white">{t('bulk_reject_order_items_desc')}</Text>
      </div>
    </Modal>
  );
};

export default BulkRejectOrderItemsModal;
