const useRemoveAllProductsToCollection = ({ removeAllProductsToCollection, collectionId }) => {
  const onRemoveAllProducts = async () => {
    removeAllProductsToCollection({
      requestBody: { collection_id: collectionId },
    });
  };

  return { onRemoveAllProducts };
};

export default useRemoveAllProductsToCollection;
