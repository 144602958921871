import { limitQuery } from '@constants/commontypes';
import { CancellationRequestStatus, ProductStatus } from '@pages/Orders/common';
import { usePaginatedProductImagesQuery } from '@services/api/hooks';
import {
  useCancellationRequestsQuery,
  useOrderItemQuery,
  usePaginatedProductOffersQuery,
  useProductDetailQuery,
} from '@services/hooks';
import { uniq } from 'lodash';

export const useDataSource = ({ pagination, filters, status, orderId }) => {
  const { data: cancellationRequests, isFetching: isCancellationRequestsFetching } =
    useCancellationRequestsQuery({
      params: {
        order_item__order: orderId,
        ...limitQuery,
      },
    });

  const {
    data: orderItems,
    isFetching: isLoadingOrderItems,
    total,
  } = useOrderItemQuery({
    pagination,
    filters,
    status,
    orderId,
  });

  const productOfferIds = orderItems?.map((item) => item?.product_offer);

  const { data: productOffers, isFetching: isLoadingProductOffers } =
    usePaginatedProductOffersQuery({
      ids: [...new Set(productOfferIds)],
      enabled: Boolean(productOfferIds?.length),
    });

  const orderItemsWithProductOffers = orderItems?.map((item) => {
    const product = productOffers?.find((product) => product.id === item.product_offer);
    return { ...item, product_offer_obj: product };
  });

  const productIds = orderItemsWithProductOffers
    ?.map((item) => item?.product_offer_obj?.product)
    ?.filter(Boolean);

  const { data: orderItemsWithProductDetail, isFetching: isProductDetailLoading } =
    useProductDetailQuery({
      ids: [...new Set(productIds)],
      enabled: Boolean(productIds?.length),
    });

  const { productImages, isFetchingProductImages } = usePaginatedProductImagesQuery({
    queryOptions: {
      enabled: Boolean(productIds?.length),
    },
    params: {
      limit: productIds?.length,
      parent__in: uniq(productIds).join(','),
      order: 0,
    },
  });

  const mergedOrderItems = orderItemsWithProductOffers?.map((item) => {
    const product = orderItemsWithProductDetail?.find(
      (product) => product?.id === item?.product_offer_obj?.product
    );

    const image = productImages?.find((data) => data?.parent === item?.product_offer_obj?.product);

    return { ...item, product_obj: product, image };
  });

  const dataSource = mergedOrderItems?.map((item) => {
    const orderCancellationRequests = cancellationRequests?.results?.filter(
      (cancellationRequest) => cancellationRequest.order_item === item.id
    );
    if (orderCancellationRequests) {
      item.orderCancellationRequests = orderCancellationRequests;
    }
    const waitingCancellationRequest = orderCancellationRequests?.find(
      (cancellationRequest) => cancellationRequest.status === CancellationRequestStatus.WAITING
    );

    if (waitingCancellationRequest) {
      item.status = ProductStatus.REFUND_WAITING;
      item.cancellation_type = waitingCancellationRequest.cancellation_type;
      item.cancellation_id = waitingCancellationRequest.id;
    }

    return item;
  });

  const loading =
    isLoadingOrderItems ||
    isLoadingProductOffers ||
    isProductDetailLoading ||
    isFetchingProductImages ||
    isCancellationRequestsFetching;

  return {
    dataSource,
    isDataSourceLoading: loading,
    total,
  };
};
