import uniq from 'lodash/uniq';

export const RouteUrls = {
  root: '/',
  errors: {
    notFound: '/404',
    systemError: '/500',
    unexpectedError: '/unexpected-error',
  },
  public: {
    signIn: '/sign-in',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password/:resetToken',
    signInCallback: '/sign-in/:provider/callback',
  },
  profile: {
    activeUser: '/active-user',
  },
  sellerStoreManagement: {
    landing: '/storepage-management',
    detail:
      '/storepage-management/detail/:widgetSlug/:productCollectionWidgetSlug/:dataSourceLandingPageId',
  },
  support: {
    landing: '/support',
    supportTicketForm: '/support/tickets/form/',
    supportTicketDetail: '/support/tickets/:ticketId',
  },
  settings: {
    landing: '/settings',
    userManagement: '/settings/user-management',
    userManagementForm: '/settings/user-management/form/:id',
    sellerUserManagementSettings: '/settings/seller-user-management',
    sellerUserManagementSettingsForm: '/settings/seller-user-management/form/',
    dynamicSettings: '/settings/dynamic-settings',
    dynamicSettingsForm: '/settings/dynamic-settings/:dynamicSettingName/form/:dynamicSettingId?',
    deliverySettings: '/settings/delivery-settings',
    sellerOfferRejectReasons: '/settings/seller-offer-reject-reasons',
    attributeSetAndCategoryMapping: '/settings/attribute-set-and-category-mapping',
    buyboxRules: '/settings/buybox-rules',
    sellerEditUser: '/settings/seller-edit-user/:dataSourceId/:userId',
    accessTokens: '/settings/access-tokens',
    deliverySettingsShipmentSettingsDetail:
      '/settings/delivery-settings/shipment-settings/form/:id?/:configId?',
    deliverySettingsShipmentSettings: '/settings/delivery-settings/shipment-settings',
    integrationErrors: '/settings/integration-errors',
    ssoLoginSettingsUrl: '/settings/sso-settings',
    ssoLoginSettingsFormUrl: '/settings/sso-settings/form/:providerSlug?',
    orderRejectionDeductions: '/settings/order-rejection-deductions',
    orderRejectionDeductionDetail:
      '/settings/order-rejection-deductions/detail/:cancellationReasonId',
  },
  messages: {
    landing: '/messages',
    productMessages: '/messages/product-messages',
    productMessagesForm: '/messages/product-messages/form/:id',
    orderMessages: '/messages/order-messages',
    orderMessagesForm: '/messages/order-messages/form/:id',
    readyMessages: '/messages/ready-messages',
    readyMessagesForm: '/messages/ready-messages/form/:id',
    publishedProductMessages: '/messages/published-product-messages',
    waitingProductMessages: '/messages/waiting-product-messages',
    supportTickets: '/messages/support-tickets',
    supportTicketForm: '/support/tickets/form/',
    supportTicketDetail: '/support/tickets/:ticketId',
    quickReplies: '/messages/quick-replies',
    quickReply: '/messages/quick-replies/new-reply',
    quickRepliesForm: '/messages/quick-replies/reply/:id',
  },
  download: {
    landing: '/:cacheKey/download/',
    export: '/export/:cacheKey/download/',
  },
  dashboard: {
    landing: '/dashboard',
  },
  marketing: {
    landing: '/marketing',
    productCollections: {
      landing: '/marketing/product-collections',
      form: '/marketing/product-collections/form/:id',
      newCollection: '/marketing/product-collections/new-collection',
    },
    marketplaceCampaigns: {
      landing: '/marketing/marketplace-campaigns',
      form: '/marketing/marketplace-campaigns/form/:id',
    },
    discountCodes: {
      landing: '/marketing/discount-codes',
      form: '/marketing/discount-codes/form/:id',
    },
    campaigns: {
      landing: '/marketing/campaigns',
      form: '/marketing/campaigns/form/:id?',
    },
  },
  reporting: {
    landing: '/reporting',
    salesPerformance: '/reporting/sales-performance',
    sellerPerformance: '/reporting/seller-performance',
    salesReports: '/reporting/sales-reports',
  },
  finance: {
    landing: '/finance',
    agreements: '/finance/agreements',
    agreementDetail: '/finance/agreements/detail/:agreementId?',
    dashboard: '/finance/dashboard',
    // reconciliationTransactions: '/finance/reconciliation-transactions',
    reconciliations: {
      landing: '/finance/reconciliations',
      detail: '/finance/reconciliations/detail/:reconciliationId',
    },
    sellerManagement: {
      landing: '/finance/seller-management',
      generalInfo: '/finance/seller-management/general-info/:id?',
      documents: '/finance/seller-management/documents/:id?',
      deliverySettings: '/finance/seller-management/delivery-settings/:id?',
      permissions: '/finance/seller-management/permissions/:id?',
      commissions: '/finance/seller-management/commissions/:id?',
      sellerDetail: '/finance/seller-management/seller-detail/:id',
    },
    sellerPerformanceMetrics: {
      landing: '/finance/seller-performance-metrics',
    },
  },
  order: {
    landing: '/order',
    ordersList: '/order/orders-list',
    orderDetail: '/order/order-detail/:orderId',
    cancellationRequests: '/order/cancellation-requests',
    deliverySettings: '/order/delivery-settings',
    packages: {
      landing: '/order/packages',
      packageList: '/order/packages/package-list',
      packageDetail: '/order/packages/package-detail/:packageId',
      packagesWaitingForCargo: '/order/packages/packages-waiting-for-cargo',
      packagesWaitingForDelivery: '/order/packages/packages-waiting-for-delivery',
    },
  },
  productsAndCategories: {
    landing: '/products-and-categories',
    addProductsFromCatalog: '/products-and-categories/add-products-from-catalog',
    inventoryManagement: {
      landing: '/products-and-categories/inventory-management',
      prices: {
        landing: '/products-and-categories/inventory-management/prices',
        priceDetail: '/products-and-categories/inventory-management/prices/price-detail/:id',
      },
      stocks: {
        landing: '/products-and-categories/inventory-management/stocks',
        stockDetail: '/products-and-categories/inventory-management/stocks/stock-detail/:stockId',
      },
    },
    products: {
      landing: '/products-and-categories/products',
      productPool: '/products-and-categories/products/product-pool',
      productForm: '/products-and-categories/products/product-form',
      detail: '/products-and-categories/products/detail/:productId',
    },
    productRevisions: {
      landing: '/products-and-categories/revision-requests',
    },
    offerList: {
      landing: '/products-and-categories/offer-list',
      detail: '/products-and-categories/offer-list/detail/:offerId',
      approved: '/products-and-categories/offer-list/approved',
      approvedDetail: '/products-and-categories/offer-list/approved/detail/:offerId',
      pendingApproval: '/products-and-categories/offer-list/pending-approval',
      rejected: '/products-and-categories/offer-list/rejected',
    },
    bulkActions: {
      landing: '/products-and-categories/bulk-actions',
      offers: '/products-and-categories/bulk-actions/offers',
      stocks: '/products-and-categories/bulk-actions/stocks',
      prices: '/products-and-categories/bulk-actions/prices',
      downloadAttributes: '/products-and-categories/bulk-actions/download-attributes',
    },
  },
  importExportOperations: {
    landing: '/import-export-operations',
  },
  notificationManagement: {
    landing: '/notification-management',
    detail: '/notification-management/form/:id?',
    form: '/notification-management/form/',
  },
  appShell: {
    landing: '/app',
    app: '/app/:appSlug/:appPath?',
  },
  stockLocations: {
    landing: '/stock-locations',
    detail: '/stock-locations/stock-location-detail/:locationId',
    create: '/stock-locations/create-stock-location',
  },
};
export const guestRoutes = [
  RouteUrls.public.signIn,
  RouteUrls.public.forgotPassword,
  RouteUrls.public.resetPassword,
  RouteUrls.public.signInCallback,
];
export const defaultRedirectionUrl = RouteUrls.root;
export const getRouteUrlsToArray = (urls = RouteUrls) => {
  return Object.keys(urls).reduce((acc, key) => {
    const value = urls[key];
    if (typeof value === 'object') {
      return [...acc, ...getRouteUrlsToArray(value)];
    }
    return [...acc, value];
  }, []);
};

export const routeUrlSet = uniq(getRouteUrlsToArray());
