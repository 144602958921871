import AkinonButton from '@components/AkinonButton';
import AkinonDatePicker from '@components/AkinonDatePicker';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import { getActivePassiveBooleanOptions } from '@constants/commontypes';
import { defaultDateValueFormat } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useCreateCollectionMutation } from '@services/api/hooks/useCreateCollectionMutation';
import { usePutCollectionMutation } from '@services/api/hooks/usePutCollectionMutation';
import { Form } from 'antd';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema, getInitialFormValues } from '../common';
import useOnSubmit from '../hooks/useOnSubmit';

const CollectionForm = ({
  collectionId,
  collectionDetail,
  isCollectionDetailLoading,
  setCurrentStep,
}) => {
  const { t, i18n } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const navigate = useAppNavigate();

  const schema = getFormSchema();
  const { control, handleSubmit, reset } = useForm({
    resolver: zodResolver(schema),
    defaultValues: getInitialFormValues({ collectionDetail }),
    mode: 'all',
  });

  useEffect(() => {
    if (collectionDetail) {
      reset(getInitialFormValues({ collectionDetail }));
    }
  }, [collectionDetail, reset]);

  const { isCreatingCollection, createCollection } = useCreateCollectionMutation({
    mutationOptions: {
      onSuccess: async (response) => {
        await navigate(RouteUrls.marketing.productCollections.form, { id: response.id });
        setCurrentStep((prevState) => prevState + 1);
      },
    },
  });
  const { isPuttingCollection, putCollection } = usePutCollectionMutation({
    mutationOptions: {
      onSuccess: async (response) => {
        await navigate(RouteUrls.marketing.productCollections.form, { id: response.id });
        setCurrentStep((prevState) => prevState + 1);
      },
    },
  });

  const onSubmit = useOnSubmit({ collectionId, createCollection, putCollection, t });

  return (
    <Box
      title={collectionId ? t('edit_collection') : t('new_collection_add')}
      className="collection-detail-box box-primary form-box"
    >
      <AkinonSpin spinning={isCollectionDetailLoading}>
        {!isCollectionDetailLoading && (
          <AkinonForm
            control={control}
            schema={schema}
            onFinish={handleSubmit(onSubmit)}
            layout="vertical"
          >
            <AkinonInput
              name={FormKey.NAME}
              label={t('collections_name')}
              placeholder={t('collections_name')}
            />
            <AkinonDatePicker
              name={FormKey.BEGINNING_DATE}
              aria-label="start-date-picker"
              label={t('start_date')}
              active
              format={defaultDateValueFormat}
              disabledDate={(current) => {
                return current && current < moment().endOf('day');
              }}
            />
            <AkinonDatePicker
              name={FormKey.EXPIRATION_DATE}
              aria-label="expiration-date-picker"
              label={t('end_date')}
              active
              format={defaultDateValueFormat}
              disabledDate={(current) => {
                return current && current < moment().endOf('day');
              }}
            />
            <AkinonSelect
              name={FormKey.IS_ACTIVE}
              aria-label="status"
              label={t('status')}
              active
              options={getActivePassiveBooleanOptions({ t })}
              placeholder={t('is.active.choose')}
            />
            <Form.Item wrapperCol={{ span: 24 }}>
              <AkinonButton
                htmlType="submit"
                type="primary"
                loading={isCreatingCollection || isPuttingCollection}
              >
                {collectionId
                  ? t('submit')
                  : t('save_and_continue').toLocaleUpperCase(i18n.language)}
              </AkinonButton>
            </Form.Item>
          </AkinonForm>
        )}
      </AkinonSpin>
    </Box>
  );
};

export default CollectionForm;
